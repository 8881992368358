<template>
  <div>
    <b-form @submit="onSubmit" id="forgot-pas-form">
      <div class="form-group">
        <label for="email">{{ $t('validation.attributes.email') }}</label>
        <b-form-input
          id="fp-email"
          required
          name="email"
          v-model="model.email"
          :state="state('email')"
          type="email"
          :placeholder="$t('validation.attributes.email_address')"
        ></b-form-input>
        <b-form-feedback>{{ feedback('email') }}</b-form-feedback>
      </div>
      <div class="form-group display-flex justify-content-center">
        <input type="submit" form="forgot-pas-form" class="btn btn-outline-primary" :value="$t('buttons.email_recovery_link')" />
      </div>
    </b-form>
  </div>
</template>

<script>
export default {
  name: 'ForgotPassForm',
  data() {
    return {
      model: {
        email: ''
      },
      validation: {}
    }
  },
  methods: {
    feedback(name) {
      if (this.state(name)) {
        return this.validation.errors[name][0]
      }
    },
    state(name) {
      return this.validation.errors !== undefined &&
        this.validation.errors.hasOwnProperty(name)
        ? 'invalid'
        : null
    },
    onSubmit(evt) {
      evt.preventDefault()
      window.axios
        .post('/password/email', this.model)
        .then(response => {
          $('#forget-password-modal').modal('hide')
          this.$app.noty['success'](this.$t('labels.user.email_sent_success'))
        })
        .catch(e => {
          if (e.response.status === 422) {
            this.validation = e.response.data
            this.$app.noty['error'](this.$t('labels.user.email_sent_error'))
            return
          }
        })
    }
  }
}
</script>
