import { render, staticRenderFns } from "./ServiceProviders.vue?vue&type=template&id=592b2e3c&"
import script from "./ServiceProviders.vue?vue&type=script&lang=js&"
export * from "./ServiceProviders.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "ServiceProviders.vue"
export default component.exports