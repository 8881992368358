export default {
    "ar": {
        "alerts": {
            "backend": {
                "users": {
                    "created": "تم إنشاء المستخدم",
                    "updated": "تم تعديل المستخدم",
                    "deleted": "تم حذف المستخدم",
                    "bulk_destroyed": "تم حذف المستخدمين المختارين",
                    "bulk_enabled": "تم تفعيل المستخدمين المختارين",
                    "bulk_disabled": "تم تعطيل المستخدمين المختارين"
                },
                "roles": {
                    "created": "تم إنشاء الدور",
                    "updated": "تم تعديل الدور",
                    "deleted": "تم حذف الدور"
                },
                "metas": {
                    "created": "تم إنشاء البيانات الوصفية",
                    "updated": "تم تعديل البيانات الوصفية",
                    "deleted": "تم حذف البيانات الوصفية",
                    "bulk_destroyed": "تم حذف البيانات الوصفية المختارة"
                },
                "form_submissions": {
                    "deleted": "تم حذف المرسَل",
                    "bulk_destroyed": "تم حذف المرسَلات المختارة"
                },
                "form_settings": {
                    "created": "تم إنشاء إعداد الإستمارة",
                    "updated": "تم تعديل إعداد الإستمارة",
                    "deleted": "تم حذف إعداد الإستمارة"
                },
                "redirections": {
                    "created": "تم إنشاء قاعدة التوجيه",
                    "updated": "تم تعديل قاعدة التوجيه",
                    "deleted": "تم حذف قاعدة التوجيه",
                    "bulk_destroyed": "تم حذف قواعد التوجيه المختارة",
                    "bulk_enabled": "تم تفعيل قواعد التوجيه المختارة",
                    "bulk_disabled": "تم تعطيل قواعد التوجيه المختارة",
                    "file_imported": "تم إستيراد الملف بنجاح"
                },
                "posts": {
                    "created": "تم إنشاء المقالة",
                    "updated": "تم تعديل المقالة",
                    "deleted": "تم حذف المقالة",
                    "bulk_destroyed": "تم حذف المقالات المختارة",
                    "bulk_published": "تم نشر المقالات المختارة",
                    "bulk_pending": "المقالات المختارة قيد المراجعة",
                    "bulk_pinned": "تم تثبيت المقالات المختارة",
                    "bulk_promoted": "تم ترقية المقالات المختارة"
                },
                "actions": {
                    "invalid": "إجراء غير صالح"
                }
            },
            "frontend": []
        },
        "auth": {
            "failed": "البيانات المدخلة لا تتطابق مع قاعدة بياناتنا.",
            "throttle": "تم تجريب عدد كبير من محاولات الدخول. يرجى المحاولة مجدداً بعد {seconds} ثانية."
        },
        "buttons": {
            "cancel": "إلغاء",
            "save": "حفظ",
            "close": "إغلاق",
            "create": "إنشاء",
            "delete": "حذف",
            "confirm": "تأكيد",
            "show": "عرض",
            "edit": "تعديل",
            "update": "تحديث",
            "view": "مشاهدة",
            "preview": "معاينة",
            "back": "تراجع",
            "send": "إرسال",
            "login-as": "تسجيل الدخول كـ {name}",
            "apply": "تطبيق",
            "users": {
                "create": "إنشاء مستخدم"
            },
            "roles": {
                "create": "إنشاء دور"
            },
            "metas": {
                "create": "إنشاء معلومة وصفية"
            },
            "form_settings": {
                "create": "إنشاء إعداد"
            },
            "redirections": {
                "create": "إنشاء قاعدة توجيه",
                "import": "إستيراد CSV"
            },
            "posts": {
                "create": "إنشاء مقالة",
                "save_and_publish": "حفظ ونشر",
                "save_as_draft": "حفظ كمسودة"
            }
        },
        "exceptions": {
            "general": "خطأ في الخادم",
            "unauthorized": "إجراء غير مسموح",
            "backend": {
                "users": {
                    "create": "خطأ في إنشاء المستخدم",
                    "update": "خطأ في تعديل المستخدم",
                    "delete": "خطأ في حذف المستخدم",
                    "first_user_cannot_be_edited": "لا يمكن تعديل معلومات المستخدم كامل الصلاحيات",
                    "first_user_cannot_be_disabled": "لا يمكن تعطيل حساب المستخدم كامل الصلاحيات",
                    "first_user_cannot_be_destroyed": "لا يمكن حذف حساب المستخدم كامل الصلاحيات",
                    "first_user_cannot_be_impersonated": "لا يمكن إنتحال شخصية المستخدم كامل الصلاحيات",
                    "cannot_set_superior_roles": "لا يمكنك الحصول على أدوار تفوق الدور الخاص بك"
                },
                "roles": {
                    "create": "خطأ في إنشاء الدور",
                    "update": "خطأ في تعديل الدور",
                    "delete": "خطأ في حذف الدور"
                },
                "metas": {
                    "create": "خطأ في إنشاء المعلومة الوصفية",
                    "update": "خطأ في تعديل المعلومة الوصفية",
                    "delete": "خطأ في حذف المعلومة الوصفية",
                    "already_exist": " يوجد معلومات وصفية لهذا الرابط منشأ مسبقاً"
                },
                "form_submissions": {
                    "create": "خطأ في إنشاء المرسَل",
                    "delete": "خطأ في حذف المرسَل"
                },
                "form_settings": {
                    "create": "خطا في إنشاء إعداد الإستمارة",
                    "update": "خطا في تعديل إعداد الإستمارة",
                    "delete": "خطا في حذف إعداد الإستمارة",
                    "already_exist": "يوجد إعداد مرتبط بهذه الإستمارة منشأ مسبقاً"
                },
                "redirections": {
                    "create": "خطأ في إنشاء قاعدة التوجيه",
                    "update": "خطأ في تعديل قاعدة التوجيه",
                    "delete": "خطأ في حذف قاعدة التوجيه",
                    "already_exist": " يوجد قاعدة توجيه لهذا المسار منشأة مسبقاً"
                },
                "posts": {
                    "create": "خطأ في إنشاء المقالة",
                    "update": "خطأ في تعديل المقالة",
                    "save": "خطأ في حفظ المقالة",
                    "delete": "خطأ في حذف المقالة"
                },
                "courses": {
                    "create": "Error on course creation",
                    "update": "Error on course updating",
                    "save": "Error on course saving",
                    "delete": "Error on course deletion"
                }
            },
            "frontend": {
                "user": {
                    "email_taken": "عنوان البريد المدخل موجود مسبقاً.",
                    "password_mismatch": "لا يوجد تطابق مع كلمة المرور القديمة.",
                    "delete_account": "خطأ في حذف الحساب.",
                    "updating_disabled": "تعديل معلومات الحساب غير مفعل."
                },
                "auth": {
                    "registration_disabled": "عملية التسجيل غير مفعلة."
                }
            }
        },
        "forms": {
            "contact": {
                "display_name": "إستمارة الإتصال"
            }
        },
        "labels": {
            "language": "اللغة",
            "actions": "الإجراءات",
            "general": "عام",
            "no_results": "لا يوجد نتائج",
            "search": "بحث",
            "validate": "تحقق",
            "choose_file": "أختر ملف",
            "no_file_chosen": "لم يتم إختيار ملف",
            "are_you_sure": "هل أنت متأكد ؟",
            "delete_image": "حذف الصورة",
            "yes": "نعم",
            "no": "لا",
            "add_new": "إضافة",
            "export": "تصدير",
            "more_info": "المزيد من المعلومات",
            "author": "الكاتب",
            "last_access_at": "أخر وصول في",
            "published_at": "تم النشر بتاريخ",
            "created_at": "تم الإنشاء بتاريخ",
            "updated_at": "تم التعديل بتاريخ",
            "deleted_at": "تم الحذف بتاريخ",
            "no_value": "لا يوجد قيمة",
            "upload_image": "رفع صورة",
            "anonymous": "مجهول",
            "all_rights_reserved": "جميع الحقوق محفوظة.",
            "with": "مع",
            "by": "بواسطة",
            "datatables": {
                "no_results": "لا يوجد نتائج",
                "no_matched_results": "لا يوجد نتائج مطابقة",
                "show_per_page": "إظهار",
                "entries_per_page": "عنصر بالصفحة",
                "search": "بحث",
                "infos": "إظهار {offset_start} حتى {offset_end} من أصل {total} عنصر"
            },
            "morphs": {
                "post": "مقالة، المعرف {id}",
                "user": "مستخدم، المعرف {id}"
            },
            "auth": {
                "disabled": "تم إلغاء تفعيل الحساب الخاص بك."
            },
            "http": {
                "403": {
                    "title": "وصول مرفوض",
                    "description": "عذراً، ليس لديك صلاحية الوصول لهذه الصفحة."
                },
                "404": {
                    "title": "الصفحة غير موجودة",
                    "description": "عذراً، إن الصفحة التي تحاول الوصول لها غير موجودة."
                },
                "500": {
                    "title": "خطأ في المخدّم",
                    "description": "عذراً، حدث خطأ غير متوقع في المخدم. سيتم معالجة المشكلة بالسرعة الممكنة."
                }
            },
            "localization": {
                "en": "إنكليزي",
                "fr": "فرنسي",
                "ar": "عربي"
            },
            "placeholders": {
                "route": "يرجى إختيار مسار داخلي صحيح",
                "tags": "إختر أو أنشئ وسماً"
            },
            "cookieconsent": {
                "message": "يستخدم موقع الويب هذا ملفات تعريف الارتباط لضمان حصولك على أفضل تجربة على موقعنا.",
                "dismiss": "تم !"
            },
            "descriptions": {
                "allowed_image_types": "أنواع الصور المسموحة: png gif jpg jpeg."
            },
            "user": {
                "dashboard": "لوحة التحكم",
                "remember": "تذكرني",
                "login": "تسجيل الدخول",
                "logout": "تسجيل الخروج",
                "password_forgot": "نسيت كلمة المرور ؟",
                "send_password_link": "إرسال رابط إعادة تعيين كلمة المرور",
                "password_reset": "إعادة تعيين كلمة المرور",
                "register": "تسجيل",
                "space": "مساحتي",
                "settings": "إعدادات",
                "account": "حسابي",
                "profile": "ملفي",
                "avatar": "الصورة الرمزية",
                "online": "أون لاين",
                "edit_profile": "تعديل ملفي",
                "change_password": "تغير كلمة مروري",
                "delete": "حذف حسابي",
                "administration": "إدارة",
                "member_since": "عضو منذ {date}",
                "profile_updated": "تم تعديل معومات الملف بنجاح.",
                "password_updated": "تم تعديل كلمة المرور بنجاح",
                "super_admin": "مدير بصلاحيات كاملة",
                "account_delete": "<p>إن القيام بهذا الإجراء سيؤدي لحذف حسابك بشكل كامل من الموقع مع جميع المعلومات ذات الصلة.</p>",
                "account_deleted": "تم حذف الحساب بنجاح",
                "titles": {
                    "space": "مساحتي",
                    "account": "حسابي"
                }
            },
            "alerts": {
                "login_as": "يتم تسجيل دخولك فعليًا باسم <strong>{name} </strong> ، ويمكنك الخروج كـ <a href=\"{route}\" data-turbolinks=\"false\">{admin} </a>."
            },
            "backend": {
                "dashboard": {
                    "new_posts": "مقالات جديدة",
                    "pending_posts": "مقالات معلّقة",
                    "published_posts": "مقالات منشورة",
                    "active_users": "مستخدمين فعّالين",
                    "form_submissions": "مرسَلات",
                    "last_posts": "أحدث المقالات",
                    "last_published_posts": "أحدث المنشورات",
                    "last_pending_posts": "أحدث المقالات المعلّقة",
                    "last_new_posts": "أحدث المقالات الجديدة",
                    "all_posts": "جميع المقالات"
                },
                "new_menu": {
                    "post": "مقالة جديدة",
                    "form_setting": "إعداد إستمارة جديد",
                    "user": "مستخدم جديد",
                    "role": "دور جديد",
                    "meta": "معلومة وصفية جديدة",
                    "redirection": "قاعدة توجيه جديدة"
                },
                "sidebar": {
                    "content": "إدارة المحتوى",
                    "forms": "إدارة الإستمارات",
                    "access": "إدارة الوصول",
                    "seo": "إعدادات تحسين محركات البحث"
                },
                "titles": {
                    "dashboard": "لوحة التحكم"
                },
                "users": {
                    "titles": {
                        "main": "إدارة المستخدمين",
                        "index": "عرض المستخدمين",
                        "create": "إنشاء مستخدم",
                        "edit": "تعديل مستخدم"
                    },
                    "actions": {
                        "destroy": "حذف المستخدمين المختارين",
                        "enable": "تفعيل المستخدمين المختارين",
                        "disable": "إلغاء تفعيل المستخدمين المختارين"
                    }
                },
                "roles": {
                    "titles": {
                        "main": "إدارة الأدوار",
                        "index": "عرض الأدوار",
                        "create": "إنشاء دور",
                        "edit": "تعديل دور"
                    }
                },
                "metas": {
                    "titles": {
                        "main": "إدارة المعلومات الوصفية",
                        "index": "عرض المعلومات الوصفية",
                        "create": "إنشاء معلومة وصفية",
                        "edit": "تعديل معلومة وصفية"
                    },
                    "actions": {
                        "destroy": "حذف المعلومات الوصفية المختارة"
                    }
                },
                "form_submissions": {
                    "titles": {
                        "main": "إدارة المرسَلات",
                        "index": "عرض المرسَلات",
                        "show": "تفاصل المُرسَل"
                    },
                    "actions": {
                        "destroy": "حذف المرسَلات المختارة"
                    }
                },
                "form_settings": {
                    "titles": {
                        "main": "إعدادات الإستمارة",
                        "index": "عرض إعدادات الإستمارة",
                        "create": "إنشاء إعداد إستمارة",
                        "edit": "تعديل إعداد إستمارة"
                    },
                    "descriptions": {
                        "recipients": "مثل: 'webmaster@example.com' أو 'sales@example.com,support@example.com' . لتحديد أكثر من مستقبل، يرجى فصلهم بفاصلة.",
                        "message": "الرسالة المراد عرضها للمستخدم بعد عملية إرسال معلومات الإستمارة. اتركها فارغة إذا لم ترد عرض أي رسالة"
                    }
                },
                "redirections": {
                    "titles": {
                        "main": "إدارة قواعد التوجيه",
                        "index": "عرض قواعد التوجيه",
                        "create": "إنشاء قاعدة توجيه",
                        "edit": "تعديل قاعدة توجيه"
                    },
                    "actions": {
                        "destroy": "حذف قواعد التوجيه المختارة",
                        "enable": "تفعيل قواعد التوجيه المختارة",
                        "disable": "إلغاء تفعيل قواعد التوجيه المختارة"
                    },
                    "types": {
                        "permanent": "إعادة توجيه دائم (301)",
                        "temporary": "إعادة توجيه مؤقت (302)"
                    },
                    "import": {
                        "title": "إستيراد ملف CSV",
                        "label": "إختر ملف CSV ليتم إستيراده",
                        "description": "الملف يجب أن يحوي عمودين بالترويسة \"source\" و \"target\" علماً أن إعادة التوجيه ستكون من النوع الدائم بشكل إفتراضي"
                    }
                },
                "posts": {
                    "statuses": {
                        "draft": "مسودة",
                        "pending": "معلّقة",
                        "published": "منشورة"
                    },
                    "titles": {
                        "main": "إدارة المقالات",
                        "index": "عرض المقالات",
                        "create": "إنشاء مقالة",
                        "edit": "تعديل مقالة",
                        "publication": "خيارات النشر"
                    },
                    "descriptions": {
                        "meta_title": "في حال كانت فارغة، فإن العنوان سيكون مطابق لعنوان المقالة بشكل إفتراضي",
                        "meta_description": "في حال كانت فارغة، فإن الوصف سيكون مطابق لملخص المقالة بشكل إفتراضي"
                    },
                    "placeholders": {
                        "body": "أكتب المحتوى...",
                        "meta_title": "عنوان المقالة.",
                        "meta_description": "ملخص المقالة."
                    },
                    "actions": {
                        "destroy": "حذف المقالات المختارة",
                        "publish": "نشر المقالات المختارة",
                        "pin": "تثبيت المقالات المختارة",
                        "promote": "ترقية المقالات المختارة"
                    }
                }
            },
            "frontend": {
                "titles": {
                    "home": "الرئيسية",
                    "about": "حول",
                    "contact": "إتصل بنا",
                    "blog": "المدونة",
                    "message_sent": "تم إرسال الرسالة",
                    "legal_mentions": "إشارات قانونية",
                    "administration": "إدارة"
                },
                "submissions": {
                    "message_sent": "<p>تم إرسال رسالتك بنجاح</p>"
                },
                "placeholders": {
                    "locale": "يرجى إختيار اللغة الخاصة بك",
                    "timezone": "يرجى إختيار النطاق الزمني الخاص بك"
                },
                "blog": {
                    "published_at": "نشرت بتاريخ {date}",
                    "published_at_with_owner_linkable": "نشرت بتاريخ {date} من قبل <a href=\"{link}\">{name}</a>",
                    "tags": "وسوم"
                }
            }
        },
        "logs": {
            "backend": {
                "users": {
                    "created": "تم إنشاء المستخدم {user}",
                    "updated": "تم تعديل المستخدم {user}",
                    "deleted": "تم حذف المستخدم {user}"
                },
                "form_submissions": {
                    "created": "تم إنشاء المرسَل {form_submission}"
                }
            },
            "frontend": []
        },
        "mails": {
            "layout": {
                "hello": "مرحباً !",
                "regards": "تحيات",
                "trouble": "إذا واجهت أي مشكلة بالضغط على الزر {action}، يرجى نسخ ولصق الرابط أدناه في المتصفح الخاص بك :",
                "all_rights_reserved": "جميع الحقوق محفوظة."
            },
            "password_reset": {
                "subject": "إعادة تعيين كلمة المرور",
                "intro": "تم إرسال هذا البريد الإلكتروني لأننا تلقينا طلب إعادة تعيين كلمة المرور للحساب الخاص بك",
                "action": "إعادة تعيين كلمة المرور",
                "outro": "إذا لم تطلب إعادة تعيين كلمة المرور، فلا داعي لاتخاذ أي إجراء"
            },
            "contact": {
                "subject": "رسالة إتصال جديدة",
                "new_contact": "لقد تلقيت رسالة إتصال جديدة. معلومات الإرسال :"
            },
            "alert": {
                "subject": "خطأ في [{app_name}]",
                "message": "لقد واجهت خطأ غير متوقع من طرف الخادم بالرسالة التالية : {message}.",
                "trace": "تفاصيل التقفي :"
            }
        },
        "pagination": {
            "previous": "&laquo; السابق",
            "next": "التالي &raquo;"
        },
        "passwords": {
            "password": "كلمة المرور يجب أن تحتوي على 6 أحرف على الأقل ومطابقة لتأكيدها.",
            "reset": "لقد تم إعادة تعيين كلمة مرورك!",
            "sent": "قمنا بإرسال رابط إعادة تعيين كلمة مرورك إلى بريدك الإلكتروني!",
            "token": "رمز إعادة تعيين كلمة المرور هذا غير صالح.",
            "user": "لم نستطع إيجاد مستخدم ينتمي إليه هذا البريد الإلكتروني."
        },
        "permissions": {
            "categories": {
                "blog": "المدونة",
                "form": "الاستمارات",
                "access": "الوصول",
                "seo": "تحسين أداء محركات البحث"
            },
            "access": {
                "backend": {
                    "display_name": "الوصول إلى لوحة الإدارة",
                    "description": "قادر على الوصول إلى صفحات الإدارة."
                }
            },
            "view": {
                "form_settings": {
                    "display_name": "مشاهدة إعدادات الاستمارات",
                    "description": "قادر على استعرض إعدادات الاستمارات."
                },
                "form_submissions": {
                    "display_name": "مشاهدة مرسَلات الاستمارات",
                    "description": "قادر على استعرض مرسَلات الاستمارات."
                },
                "users": {
                    "display_name": "مشاهدة المستخدمين",
                    "description": "قادر على استعرض المستخدمين."
                },
                "roles": {
                    "display_name": "مشاهدة الأدوار",
                    "description": "قادر على استعرض أدوار المستخدمين."
                },
                "metas": {
                    "display_name": "مشاهدة المعلومات الوصفية",
                    "description": "قادر على استعرض المعلومات الوصفية."
                },
                "redirections": {
                    "display_name": "مشاهدة قواعد إعادة التوجيه",
                    "description": "قادر على استعرض قواعد إعادة التوجيه."
                },
                "posts": {
                    "display_name": "مشاهدة جميع المقالات",
                    "description": "قادر على استعرض جميع المقالات."
                },
                "own": {
                    "posts": {
                        "display_name": "مشاهدة المقالات الخاصة بالمستخدم",
                        "description": "قادر على مشاهدة المقالات الخاصة به."
                    }
                }
            },
            "create": {
                "form_settings": {
                    "display_name": "إنشاء إعدادات الاستمارات",
                    "description": "قادر على إنشاء إعدادات الاستمارات."
                },
                "users": {
                    "display_name": "إنشاء مستخدمين",
                    "description": "قادر على إنشاء مستخدمين."
                },
                "roles": {
                    "display_name": "إضافة أدوار",
                    "description": "قادر على إضافة أدوار."
                },
                "metas": {
                    "display_name": "إضافة معلومات وصفية",
                    "description": "قادر على إضافة معلومات وصفية."
                },
                "redirections": {
                    "display_name": "إضافة قواعد توجيه",
                    "description": "قادر على إضافة قواعد توجيه."
                },
                "posts": {
                    "display_name": "إضافة مقالات",
                    "description": "قادر على إضافة مقالات."
                }
            },
            "edit": {
                "form_settings": {
                    "display_name": "تعديل إعدادات الاستمارات",
                    "description": "قادر على تعديل إعدادات الاستمارات."
                },
                "users": {
                    "display_name": "تعديل المستخدمين",
                    "description": "قادر على تعديل المستخدمين."
                },
                "roles": {
                    "display_name": "تعديل الأدوار",
                    "description": "قادر على تعديل المستخدمين."
                },
                "metas": {
                    "display_name": "تعديل المعلومات الوصفية",
                    "description": "قادر على تعديل المعلومات الوصفية."
                },
                "redirections": {
                    "display_name": "تعديل قواعد التوجيه",
                    "description": "قادر على تعديل قواعد التوجيه."
                },
                "posts": {
                    "display_name": "تعديل جميع المقالات",
                    "description": "قادر على تعديل جميع المقالات."
                },
                "own": {
                    "posts": {
                        "display_name": "تعديل مقالات المستخدم",
                        "description": "قادر على تعديل المقالات الخاصة به."
                    }
                }
            },
            "delete": {
                "form_settings": {
                    "display_name": "حذف إعدادات الاستمارات",
                    "description": "قادر على حذف إعدادات الاستمارات."
                },
                "form_submissions": {
                    "display_name": "حذف مرسلات الاستمارات",
                    "description": "قادر على حذف مرسلات الاستمارات."
                },
                "users": {
                    "display_name": "حذف المستخدمين",
                    "description": "قادر على حذف المستخدمين."
                },
                "roles": {
                    "display_name": "حذف الأدوار",
                    "description": "قادر على حذف الأدوار."
                },
                "metas": {
                    "display_name": "حذف المعلومات الوصفية",
                    "description": "قادر على حذف المعلومات الوصفية."
                },
                "redirections": {
                    "display_name": "حذف قواعد التوجيه",
                    "description": "قادر على حذف قواعد التوجيه."
                },
                "posts": {
                    "display_name": "حذف جميع المقالات",
                    "description": "قادر على حذف جميع المقالات."
                },
                "own": {
                    "posts": {
                        "display_name": "حذف مقالات المستخدم",
                        "description": "قادر على حذف المقالات الخاصة به."
                    }
                }
            },
            "publish": {
                "posts": {
                    "display_name": "نشر المقالات",
                    "description": "قادر على إدارة عملية نشر المقالات."
                }
            },
            "impersonate": {
                "display_name": "إنتحال شخصية مستخدم",
                "description": "قادر على أخذ صلاحيات مستخدم أخر، خاص بحالات التجريب."
            }
        },
        "routes": {
            "home": "الرئيسية",
            "about": "حول",
            "contact": "اتصل-بنا",
            "contact-sent": "تم-الإرسال",
            "legal-mentions": "إشارات-قانونية",
            "redactors": "blog/redactors/{user}"
        },
        "validation": {
            "accepted": "يجب قبول الحقل {attribute}.",
            "active_url": "الحقل {attribute} لا يُمثّل رابطًا صحيحًا.",
            "after": "يجب على الحقل {attribute} أن يكون تاريخًا لاحقًا للتاريخ {date}.",
            "after_or_equal": "يجب على الحقل {attribute} أن يكون تاريخًا مساوٍ أو لاحقًا للتاريخ {date}.",
            "alpha": "يجب أن لا يحتوي الحقل {attribute} سوى على حروف.",
            "alpha_dash": "يجب أن لا يحتوي الحقل {attribute} على حروف، أرقام ومطّات.",
            "alpha_num": "يجب أن يحتوي {attribute} على حروف وأرقام فقط.",
            "array": "يجب أن يكون الحقل {attribute} ًمصفوفة.",
            "before": "يجب على الحقل {attribute} أن يكون تاريخًا سابقًا للتاريخ {date}.",
            "before_or_equal": "يجب على الحقل {attribute} أن يكون تاريخًا سابقًا أو مساوٍ للتاريخ {date}.",
            "between": {
                "numeric": "يجب أن تكون قيمة {attribute} محصورة ما بين {min} و {max}.",
                "file": "يجب أن يكون حجم الملف {attribute} محصورًا ما بين {min} و {max} كيلوبايت.",
                "string": "يجب أن يكون عدد حروف النّص {attribute} محصورًا ما بين {min} و {max}.",
                "array": "يجب أن يحتوي {attribute} على عدد من العناصر محصورًا ما بين {min} و {max}."
            },
            "boolean": "يجب أن تكون قيمة الحقل {attribute} إما true أو false.",
            "confirmed": "حقل التأكيد غير مُطابق للحقل {attribute}.",
            "date": "الحقل {attribute} ليس تاريخًا صحيحاً.",
            "date_format": "لا يتوافق الحقل {attribute} مع الشكل {format}.",
            "different": "يجب أن يكون الحقلان {attribute} و {other} مُختلفان.",
            "digits": "يجب أن يحتوي الحقل {attribute} على {digits} رقمًا/أرقام.",
            "digits_between": "يجب أن يحتوي الحقل {attribute} ما بين {min} و {max} رقمًا/أرقام.",
            "dimensions": " أبعاد الصورة {attribute} غير صالحة.",
            "distinct": "للحقل {attribute} قيمة مُكرّرة.",
            "email": "يجب أن يكون {attribute} عنوان بريد إلكتروني صحيح البُنية.",
            "exists": "الحقل {attribute} لاغٍ.",
            "file": "الحقل {attribute} يجب أن يكون ملف.",
            "filled": "الحقل {attribute} إجباري.",
            "image": "يجب أن يكون الحقل {attribute} صورة.",
            "in": "الحقل {attribute} لاغٍ.",
            "in_array": "الحقل {attribute} غير موجود في {other}.",
            "integer": "يجب أن يكون الحقل {attribute} عدد صحيح.",
            "ip": "يجب أن يكون الحقل {attribute} عنوان IP ذي بُنية صحيحة.",
            "ipv4": "يجب أن يكون الحقل {attribute} عنوان IPv4 ذي بُنية صحيحة.",
            "ipv6": "يجب أن يكون الحقل {attribute} عنوان IPv6 ذي بُنية صحيحة.",
            "json": "يجب أن يكون الحقل {attribute} نصآ من نوع JSON.",
            "max": {
                "numeric": "يجب أن تكون قيمة الحقل {attribute} أصغر من {max}.",
                "file": "يجب أن يكون حجم الملف {attribute} أصغر من {max} كيلوبايت.",
                "string": "يجب أن لا يتجاوز طول النّص {attribute} {max} حروفٍ/حرفًا.",
                "array": "يجب أن لا يحتوي الحقل {attribute} على أكثر من {max} عناصر/عنصر."
            },
            "mimes": "يجب أن يكون الحقل ملفًا من نوع : {values}.",
            "mimetypes": "يجب أن يكون الحقل ملفًا من نوع : {values}.",
            "min": {
                "numeric": "يجب أن تكون قيمة الحقل {attribute} أكبر من {min}.",
                "file": "يجب أن يكون حجم الملف {attribute} أكبر من {min} كيلوبايت.",
                "string": "يجب أن يكون طول النص {attribute} أكبر من {min} حروفٍ/حرفًا.",
                "array": "يجب أن يحتوي الحقل {attribute} على الأقل على {min} عُنصرًا/عناصر."
            },
            "not_in": "الحقل {attribute} لاغٍ.",
            "numeric": "يجب على الحقل {attribute} أن يكون رقماً.",
            "present": "الحقل {attribute} يجب أن يكون موجوداً.",
            "regex": "صيغة الحقل {attribute} غير صحيحة.",
            "required": "الحقل {attribute} مطلوب.",
            "required_if": "الحقل {attribute} مطلوب في حال ما إذا كان {other} يساوي {value}.",
            "required_unless": "الحقل {attribute} مطلوب في حال ما لم يكن {other} يساوي {values}.",
            "required_with": "الحقل {attribute} مطلوب إذا توفّر {values}.",
            "required_with_all": "الحقل {attribute} مطلوب إذا توفّر {values}.",
            "required_without": "الحقل {attribute} مطلوب إذا لم يتوفّر {values}.",
            "required_without_all": "الحقل {attribute} مطلوب إذا لم يتوفّر {values}.",
            "same": "يجب أن يتطابق الحقل {attribute} مع {other}",
            "size": {
                "numeric": "يجب أن تكون قيمة {attribute} أكبر من {size}.",
                "file": "يجب أن يكون حجم الملف {attribute} أكبر من {size} كيلو بايت.",
                "string": "يجب أن يحتوي النص {attribute} عن ما لا يقل عن  {size} حرفٍ/أحرف.",
                "array": "يجب أن يحتوي الحقل {attribute} عن ما لا يقل عن{min} عنصرٍ/عناصر."
            },
            "string": "يجب أن يكون الحقل {attribute} نصآ.",
            "timezone": "يجب أن يكون {attribute} نطاقًا زمنيًا صحيحًا",
            "unique": "قيمة الحقل {attribute} مُستخدمة من قبل.",
            "uploaded": "فشل في تحميل {attribute}.",
            "url": "صيغة الرابط {attribute} غير صحيحة.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": {
                "name": "الاسم",
                "display_name": "اسم العرض",
                "username": "اسم المستخدم",
                "email": "عنوان البريد الإلكتروني",
                "first_name": "الاسم الأول",
                "last_name": "الاسم الأخير",
                "password": "كلمة المرور",
                "password_confirmation": "تأكيد كلمة المرور",
                "old_password": "كلمة المرور القديمة",
                "new_password": "كلمة المرور الجديدة",
                "new_password_confirmation": "تأكيد كلمة المرور الجديدة",
                "postal_code": "الرمز البريدي",
                "city": "المدينة",
                "country": "الدولة",
                "address": "العنوان",
                "phone": "الهاتف",
                "mobile": "المحمول",
                "age": "العمر",
                "sex": "الجنس",
                "gender": "الجنس",
                "day": "اليوم",
                "month": "الشهر",
                "year": "السنة",
                "hour": "الساعة",
                "minute": "الدقيقة",
                "second": "الثانية",
                "title": "العنوان",
                "content": "المحتوى",
                "description": "الوصف",
                "summary": "الملخص",
                "excerpt": "مقتطفات",
                "date": "التاريخ",
                "time": "الوقت",
                "available": "متوفر",
                "size": "الحجم",
                "roles": "الأدوار",
                "permissions": "الصلاحيات",
                "active": "فعال",
                "message": "رسالة",
                "g-recaptcha-response": "رمز حماية Captcha",
                "locale": "تعريب",
                "route": "توجيه",
                "url": "اسم الرابط المستعار",
                "form_type": "نوع الإستمارة",
                "form_data": "معلومات الإستمارة",
                "recipients": "المستلمين",
                "source_path": "المسار الأصلي",
                "target_path": "المسار الهدف",
                "redirect_type": "نوع التوجيه",
                "timezone": "نطاق زمني",
                "order": "ترتيب العرض",
                "image": "صورة",
                "status": "حالة",
                "pinned": "مثبت",
                "promoted": "مُرقّى",
                "body": "جسم",
                "tags": "وسوم",
                "published_at": "منشور في",
                "unpublished_at": "إيقاف النشر في",
                "metable_type": "كيان"
            }
        }
    },
    "en": {
        "alerts": {
            "backend": {
                "users": {
                    "created": "User created",
                    "updated": "User updated",
                    "deleted": "User deleted",
                    "bulk_destroyed": "Selected users deleted",
                    "bulk_enabled": "Selected users enabled",
                    "bulk_disabled": "Selected users disabled",
                    "intake_functionality_added": "Intake functionality added",
                    "intake_functionality_removed": "Intake functionality removed",
                    "member_invited": "Invitation sent to email.",
                    "clients_assigned": "Selected clients were assigned to worker.",
                    "deactivated": "User was deactivated"
                },
                "roles": {
                    "created": "Role created",
                    "updated": "Role updated",
                    "deleted": "Role deleted"
                },
                "metas": {
                    "created": "Meta created",
                    "updated": "Meta updated",
                    "deleted": "Meta deleted",
                    "bulk_destroyed": "Selected metas deleted"
                },
                "form_submissions": {
                    "deleted": "Submission deleted",
                    "bulk_destroyed": "Selected submissions deleted"
                },
                "form_settings": {
                    "created": "Form setting created",
                    "updated": "Form setting updated",
                    "deleted": "Form setting deleted"
                },
                "redirections": {
                    "created": "Redirection created",
                    "updated": "Redirection updated",
                    "deleted": "Redirection deleted",
                    "bulk_destroyed": "Selected redirections deleted",
                    "bulk_enabled": "Selected redirections enabled",
                    "bulk_disabled": "Selected redirections disabled",
                    "file_imported": "File successfully imported"
                },
                "posts": {
                    "created": "Post created",
                    "updated": "Post updated",
                    "deleted": "Post deleted",
                    "bulk_destroyed": "Selected posts deleted",
                    "bulk_published": "Selected posts published",
                    "bulk_pending": "Selected posts are awaiting moderation",
                    "bulk_pinned": "Selected posts pinned",
                    "bulk_promoted": "Selected posts promoted"
                },
                "courses": {
                    "created": "Course created",
                    "updated": "Course updated",
                    "deleted": "Course deleted"
                },
                "action_plans": {
                    "created": "Action Plan created",
                    "updated": "Action Plan updated"
                },
                "client_send_email_contact_us_request": {
                    "sent": "Email sent"
                },
                "front_end_contact_us_send_email": {
                    "sent": "Email sent"
                },
                "intake_forms": {
                    "created": "Intake form created",
                    "saved": "Intake form saved",
                    "updated": "Intake form updated",
                    "deleted": "Intake form deleted"
                },
                "course_categories": {
                    "updated": "Category updated",
                    "deleted": "Category deleted",
                    "created": "Category created"
                },
                "documents": {
                    "updated": "Document updated",
                    "deleted": "Document deleted",
                    "uploaded": "Document uploaded successfully!"
                },
                "agencies": {
                    "created": "Agency created",
                    "saved": "Agency saved",
                    "updated": "Agency updated",
                    "deleted": "Agency deleted",
                    "registered": "Agency registered",
                    "submit_intake_form": "Please submit Intake form first",
                    "agency_updated": "Agency updated successfully!"
                },
                "client_agency_service_categories": {
                    "added": "Service Category added "
                },
                "feedback": {
                    "created": "Thank you, your feedback has been submitted."
                },
                "appointments": {
                    "created": "Created",
                    "updated": "Updated"
                },
                "assessment": {
                    "saved": "Assessment saved"
                },
                "actions": {
                    "invalid": "Invalid action"
                },
                "narrations": {
                    "added": "Narration added successfully!"
                },
                "service_category": {
                    "added": "Service added to client profile successfully."
                },
                "community_connect": {
                    "updated": "Page information is updated successfully!"
                }
            },
            "frontend": []
        },
        "auth": {
            "failed": "These credentials do not match our records.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds.",
            "socialite": {
                "unacceptable": "{provider} is not an acceptable login type."
            }
        },
        "buttons": {
            "cancel": "Cancel",
            "save": "Save",
            "close": "Close",
            "create": "Create",
            "delete": "Delete",
            "confirm": "Confirm",
            "show": "Show",
            "edit": "Edit",
            "update": "Update",
            "view": "View",
            "preview": "Preview",
            "back": "Back",
            "next": "Next",
            "send": "Send",
            "submit": "Submit",
            "login-as": "Login as {name}",
            "apply": "Apply",
            "sign_in": "Sign In",
            "sign_up": "Sign up",
            "visit_now": "Visit now",
            "view_all": "View all",
            "view_less": "View less",
            "view_profile": "View profile",
            "create_account": "Create Account",
            "send_pass_reset": "Send password reset link",
            "send_pass_reset_link_to_client": "Send password reset link to client",
            "make_appointment": "Make appointment",
            "request_an_appointment": "Book an appointment",
            "go_website": "Go to website",
            "contact_us": "Contact Support",
            "contact_support": "Contact support",
            "services": "Services",
            "register": "Register",
            "red_more": "Read more",
            "visit_website": "Visit website",
            "upload_files": "Upload files",
            "reply": "Reply",
            "deactivate": "Deactivate",
            "send_request": "Send Request",
            "skip": "Skip",
            "assign": "Assign",
            "other_worker": " Other worker",
            "add_narration": "Add Narration",
            "mange_categories": "Manage Categories",
            "send_message": "Send Message",
            "users": {
                "create": "Create user"
            },
            "roles": {
                "create": "Create role"
            },
            "metas": {
                "create": "Create meta"
            },
            "form_settings": {
                "create": "Create setting"
            },
            "redirections": {
                "create": "Create redirection",
                "import": "Import CSV"
            },
            "posts": {
                "create": "Create post",
                "save_and_publish": "Save and publish",
                "save_as_draft": "Save as draft"
            },
            "documents": {
                "upload_new_doc": "Upload new document"
            },
            "feedback": {
                "leave_comment": "Leave a comment"
            },
            "email_recovery_link": "Email me a recovery link",
            "view_services": "View Services",
            "view_courses": "View Courses",
            "print": "Print",
            "change_password": "Change Password",
            "view_intake_forms": "View Intake Forms",
            "ok": "Ok",
            "export": "Export",
            "export_db": "Export Database",
            "edit_page_content": "Edit Page Content",
            "register_now": "Register Now",
            "coming_soon": "Coming Soon"
        },
        "exceptions": {
            "general": "Server exception",
            "unauthorized": "Action not allowed",
            "backend": {
                "users": {
                    "create": "Error on user creation",
                    "update": "Error on user updating",
                    "delete": "Error on user deletion",
                    "first_user_cannot_be_edited": "You cannot edit super admin user",
                    "first_user_cannot_be_disabled": "Super admin user cannot be disabled",
                    "first_user_cannot_be_destroyed": "Super admin user cannot be deleted",
                    "first_user_cannot_be_impersonated": "Super admin user cannot be impersonated",
                    "cannot_set_superior_roles": "You cannot attribute roles superior to yours"
                },
                "roles": {
                    "create": "Error on role creation",
                    "update": "Error on role updating",
                    "delete": "Error on role deletion"
                },
                "metas": {
                    "create": "Error on meta creation",
                    "update": "Error on meta updating",
                    "delete": "Error on meta deletion",
                    "already_exist": "There is already a meta for this locale route"
                },
                "course_categories": {
                    "create": "Error on category creation",
                    "update": "Error on category updating",
                    "delete": "Error on category deletion",
                    "already_exist": "Category  already exist"
                },
                "form_submissions": {
                    "create": "Error on submission creation",
                    "delete": "Error on submission deletion"
                },
                "form_settings": {
                    "create": "Error on form setting creation",
                    "update": "Error on form setting updating",
                    "delete": "Error on form setting deletion",
                    "already_exist": "There is already a setting linked to this form"
                },
                "redirections": {
                    "create": "Error on redirection creation",
                    "update": "Error on redirection updating",
                    "delete": "Error on redirection deletion",
                    "already_exist": "There is already a redirection for this path"
                },
                "posts": {
                    "create": "Error on post creation",
                    "update": "Error on post updating",
                    "save": "Error on post saving",
                    "delete": "Error on post deletion"
                },
                "courses": {
                    "create": "Error on course creation",
                    "update": "Error on course updating",
                    "save": "Error on course saving",
                    "delete": "Error on course deletion"
                },
                "intake_forms": {
                    "create": "Error on intake form creation",
                    "update": "Error on intake form updating",
                    "save": "Error on intake form saving",
                    "delete": "Error on intake form deletion"
                },
                "documents": {
                    "upload": "Error on document uploading",
                    "delete": "Error on document deleting"
                },
                "feedback": {
                    "create": "Error on feedback creation",
                    "update": "Error on feedback updating",
                    "save": "Error on feedback saving",
                    "delete": "Error on feedback deletion"
                },
                "appointments": {
                    "create": "Error on appointment creation",
                    "update": "Error on appointment updating",
                    "save": "Error on appointment saving",
                    "delete": "Error on appointment deletion",
                    "no_worker": "You dont have assigned worker yet",
                    "not_available": "Time not available"
                },
                "case_narrations": {
                    "create": "Error on appointment creation",
                    "update": "Error on appointment updating",
                    "save": "Error on appointment saving",
                    "delete": "Error on appointment deletion"
                },
                "assessments": {
                    "create": "Error on create creation",
                    "update": "Error on create updating",
                    "save": "Error on create saving",
                    "delete": "Error on create deletion"
                },
                "agencies": {
                    "create": "Error on agency creation",
                    "update": "Error on agency updating",
                    "save": "Error on agency saving",
                    "delete": "Error on agency deletion",
                    "register": "Error on agency registration"
                }
            },
            "frontend": {
                "user": {
                    "email_taken": "That e-mail address is already taken.",
                    "password_mismatch": "That is not your old password.",
                    "delete_account": "Error on account deletion.",
                    "updating_disabled": "Account editing is disabled."
                },
                "auth": {
                    "registration_disabled": "Registration is disabled."
                }
            }
        },
        "forms": {
            "contact": {
                "display_name": "Contact form"
            },
            "client_intake_form_submit": {
                "display_name": "Client intake form submit"
            },
            "client_intake_form": {
                "upload_title": "Upload documents with maximum size of 30 MB."
            },
            "documents": {
                "upload_title": "Upload documents with maximum size of 30 MB."
            }
        },
        "labels": {
            "reason_for_referral": "Reason for referral",
            "refer": "Refer",
            "person_selected_press_refer_to_confirm": "You have selected a person. Please press the \"Refer\" button to\r\n    confirm this action",
            "select_workers": "Select Workers",
            "internal_referral": "Internal Referral",
            "chat_log": "Chat Log",
            "view_chat_log": "View Chat Log",
            "loading_chat_history": "Loading chat history",
            "unable_to_load_chat_history": "Unable to load chat history",
            "language": "Language",
            "actions": "Actions",
            "general": "General",
            "no_results": "No results available",
            "results": "Results",
            "search": "Search",
            "validate": "Validate",
            "choose_file": "Choose File",
            "no_file_chosen": "No file chosen",
            "are_you_sure": "Are you sure ?",
            "delete_image": "Delete image",
            "yes": "Yes",
            "no": "No",
            "add_new": "Add",
            "export": "Export",
            "more_info": "More info",
            "author": "Author",
            "author_id": "Author ID",
            "last_access_at": "Last access at",
            "published_at": "Published at",
            "created_at": "Created at",
            "updated_at": "Updated at",
            "deleted_at": "Deleted at",
            "no_value": "No value",
            "upload_image": "Upload image",
            "anonymous": "Anonymous",
            "all_rights_reserved": "All rights reserved.",
            "with": "with",
            "by": "by",
            "all": "All",
            "type_of_services": "Services Provided",
            "location": "Location",
            "available_lang": "Languages Served",
            "available_agencies": "Available agencies",
            "contacts": "Contacts",
            "contacts_info": "Contacts Information",
            "select_agency": "Select agency",
            "select_category": "Select category",
            "general_info": "General info",
            "personal_details": "Personal Details",
            "residence_address": "Residence Address",
            "mailing_address": "Mailing Address",
            "same_as_residence_address": "Same as residence address",
            "language_preferences": "Language preferences",
            "marital_status": "Marital Status",
            "immigration_document_number": "Immigration Document Number",
            "select_client": "Select client",
            "link": "link",
            "add_tag": "Add tag",
            "priority_level": "Priority level",
            "title": "title",
            "mandatory_fields": "Fields with * are mandatory",
            "select_type": "Select type",
            "identified_needs": "Identified Needs",
            "datatables": {
                "no_results": "No results available",
                "no_matched_results": "No matched results available",
                "show_per_page": "Show",
                "entries_per_page": "entries per page",
                "search": "Search",
                "infos": "Showing {offset_start} to {offset_end} of {total} entries"
            },
            "contact_us_client_topics": {
                "topics": [
                    "Topic 1",
                    "Topic 2",
                    "Topic 3",
                    "Topic 4",
                    "Topic 5"
                ],
                "placeholder": "Select Topic"
            },
            "morphs": {
                "post": "Post, identity {id}",
                "user": "User, identity {id}"
            },
            "contact_us_info": "Please contact us with any technical issues.",
            "auth": {
                "disabled": "Your account has been disabled."
            },
            "http": {
                "403": {
                    "title": "Access Denied",
                    "description": "Sorry, but you do not have permissions to access this page."
                },
                "404": {
                    "title": "Page Not Found",
                    "description": "Sorry, but the page you were trying to view does not exist."
                },
                "500": {
                    "title": "Server Error",
                    "description": "Sorry, but the server has encountered a situation it doesn't know how to handle. We'll fix this as soon as possible."
                }
            },
            "localization": {
                "en": "English",
                "ru": "Russian",
                "fr": "French",
                "es": "Spanish",
                "de": "German",
                "zh": "Chinese",
                "ar": "Arab",
                "pt": "Portuguese",
                "english": "English",
                "french": "French",
                "kirundi": "Kirundi",
                "swahili": "Swahili",
                "lingala": "Lingala",
                "arabic": "Arabic",
                "creole": "Creole",
                "somali": "Somali",
                "russian": "Russian",
                "mandarin": "Mandarin",
                "cantonese": "Cantonese",
                "farsi": "Farsi",
                "turkish": "Turkish",
                "hindi": "Hindi",
                "tamil": "Tamil",
                "tagalog": "Tagalog",
                "kurdish": "Kurdish",
                "armenian": "Armenian",
                "chinese": "Chinese",
                "polish": "Polish",
                "igbo": "Igbo",
                "spanish": "Spanish",
                "portuguese": "Portuguese",
                "bengali": "Bengali",
                "hindu": "Hindi",
                "urdu": "Urdu",
                "nepali": "Nepali",
                "serbian": "Serbian",
                "dari": "Dari",
                "tigrinya": "Tigrinya",
                "amharic": "Amharic",
                "italian": "Italian",
                "german": "German",
                "ukrainian": "Ukrainian",
                "bosnian": "Bosnian",
                "punjabi": "Punjabi",
                "sinhalese": "Sinhalese"
            },
            "locations": {
                "West": "West",
                "East": "East",
                "South": "South",
                "North": "North"
            },
            "more": "More",
            "placeholders": {
                "route": "Select a valid internal route",
                "tags": "Select or create a tag",
                "select_country": "Select country",
                "select_region": "Select region",
                "select_marital_status": "Select status",
                "select_immigration_class": "Select immigration class",
                "select_immigration_status": "Select immigration status",
                "select_immigration_id_type": "Select immigration ID type",
                "immigration_citizen_intends": "Select option",
                "select_language": "Select language"
            },
            "cookieconsent": {
                "message": "This website uses cookies to ensure you get the best experience on our website.",
                "dismiss": "Got it !"
            },
            "descriptions": {
                "allowed_image_types": "Allowed types: png gif jpg jpeg."
            },
            "user": {
                "dashboard": "Dashboard",
                "remember": "Remember",
                "login": "Login",
                "sign_in": "Sign In",
                "logout": "Logout",
                "password_forgot": "Forget password ?",
                "send_password_link": "Send reset password link",
                "password_reset": "Password Reset",
                "reset_password": "Reset password ",
                "create_account": "Create an account",
                "no_account": "Don't yet have an account?",
                "register": "Register",
                "space": "My space",
                "settings": "Settings",
                "account": "My account",
                "profile": "My profile",
                "avatar": "Avatar",
                "online": "Online",
                "edit_profile": "Edit my profile",
                "change_password": "Change my password",
                "delete": "Delete my account",
                "administration": "Administration",
                "member_since": "Member since {date}",
                "profile_updated": "Profile successfully updated.",
                "password_updated": "Password successfully updated.",
                "super_admin": "Super administrator",
                "account_delete": "<p>This action will delete entirely your account from this site as well as all associated data.</p>",
                "account_deleted": "Account successfully deleted",
                "email_sent_success": "We have successfully emailed a link to reset your password!",
                "email_sent_error": "Error sending email for password reset, please check your email is correct or contact LASI.",
                "titles": {
                    "space": "My space",
                    "account": "My account"
                },
                "why_create_account": "Why create an account?",
                "why_create_account_line1": "Connect with Settlement workers easily via video chat",
                "why_create_account_line2": "Get specific answers to your questions",
                "why_create_account_line3": "Why create an account?",
                "already_have_account": "Already have an account?",
                "setting_up_account": "Please wait, we are setting up your account..."
            },
            "alerts": {
                "login_as": "You are actually logged as <strong>{name}</strong>, you can logout as <a href=\"{route}\" data-turbolinks=\"false\">{admin}</a>."
            },
            "backend": {
                "dashboard": {
                    "new_posts": "New posts",
                    "pending_posts": "Pending posts",
                    "published_posts": "Published posts",
                    "active_users": "Active users",
                    "form_submissions": "Submissions",
                    "last_posts": "Last posts",
                    "last_published_posts": "Last publications",
                    "last_pending_posts": "Last pending posts",
                    "last_new_posts": "Last new posts",
                    "all_posts": "All posts",
                    "dashboard_description": "Here's where you can see upcoming appointments, your referrals, your learning videos and pending activities.",
                    "request_meeting": "Request Meeting",
                    "complete": "Complete",
                    "upcoming_appointments": "Upcoming Appointments",
                    "unconfirmed_appointments": "Unconfirmed appointments",
                    "activity_status": "Activity status",
                    "view_all": "View All",
                    "start_video_chat": "Start Video Chat",
                    "date_time": "Date/Time",
                    "worker": "Worker",
                    "agency": "Agency",
                    "type": "Type",
                    "service_category": "Service Category",
                    "notes": "Notes",
                    "status": "Status",
                    "clients_total": "Clients total #",
                    "available_service_categories": "Available service categories",
                    "intake_forms_review": "Intake Forms to review",
                    "total_no_appointments": "Total number of appointments",
                    "accepted": "Accepted",
                    "pending": "Pending",
                    "cancelled": "Cancelled",
                    "client": "Client",
                    "confirm": "Confirm",
                    "completed": "Completed",
                    "edit": "Edit",
                    "not_completed": "Not Completed",
                    "cancel": "Cancel",
                    "last_7_days": "Last 7 Days",
                    "last_30_days": "Last 30 Days",
                    "last_year": "Last Year",
                    "list_clients": "List of clients",
                    "name": "Name",
                    "action_plan_progress": "Action Plan Progress",
                    "request_appointment": "Request Appointment",
                    "my_appointments": "My appointments",
                    "active": "Active",
                    "inactive": "Inactive",
                    "intake_functionality": "Intake functionality:",
                    "invite_new_member": "Invite new member",
                    "send_invitation": "Send Invitation",
                    "top_requested_services": "Top requested services",
                    "number_of_clients": "Number of Clients",
                    "responsible_persons": "Responsible Persons",
                    "total_no": "Total #",
                    "awaiting": "Awaiting",
                    "declined": "Declined",
                    "assigned_worker": "Assigned Worker",
                    "complaints": "Complaints",
                    "list_workers": "List of workers",
                    "assigned_clients": "Assigned Clients",
                    "received_intake_forms": "Received Intake forms",
                    "summary_reports": "Summary reports",
                    "remove": "Remove",
                    "workers_total": "Workers total #",
                    "managers_total": "Managers total #",
                    "manage_accounts": "Manage accounts",
                    "reassign": "Reassign",
                    "activate": "Activate",
                    "suspend": "Suspend",
                    "delete": "Delete",
                    "type_account": "Type of account",
                    "registration_id": "Registration ID",
                    "suspended": "Suspended",
                    "deleted": "Deleted",
                    "date_of_creation": "Date of creation",
                    "agencies": "Agencies",
                    "summary": "Summary",
                    "edit_agency": "Edit Agency",
                    "body": "Body",
                    "website_url": "Website URL",
                    "service_categories": "Service Categories",
                    "surveys": "Surveys",
                    "surveys_management": "Surveys Management",
                    "reassign_role": "Reassign Role",
                    "community_connect": "Community Connect",
                    "reassign_agency": "Reassign Agency"
                },
                "surveys": {
                    "create": "Create a survey"
                },
                "new_menu": {
                    "post": "New post",
                    "form_setting": "New form setting",
                    "user": "New user",
                    "role": "New role",
                    "meta": "New meta",
                    "redirection": "New redirection"
                },
                "service_categories": {
                    "service_name": "Service Name",
                    "summary": "Summary",
                    "actions": "Actions",
                    "add": "Add Service Category",
                    "service_name_en": "Service Name (English)",
                    "service_name_fr": "Service Name (French)",
                    "service_name_placeholder_en": "Enter service name (English)...",
                    "service_name_placeholder_fr": "Enter service name (French)...",
                    "summary_en": "Summary (English)",
                    "summary_fr": "Summary (French)",
                    "summary_placeholder_en": "Enter summary in English...",
                    "summary_placeholder_fr": "Enter summary in French...",
                    "description_en": "Description (English)",
                    "description_fr": "Description (French)",
                    "description_placeholder_en": "Enter a description in English...",
                    "description_placeholder_fr": "Enter a description in French...",
                    "edit": "Edit Service Category",
                    "created_success": "Service Category created successfully!",
                    "updated_success": "Service Category updated successfully!",
                    "deleted_success": "Service Category deleted successfully!",
                    "delete": "Delete Service Category",
                    "program_email": "Program Email",
                    "program_email_placeholder": "Enter Program Manager's Email..."
                },
                "sidebar": {
                    "content": "Content management",
                    "forms": "Form management",
                    "access": "Access management",
                    "seo": "SEO settings",
                    "links": {
                        "services_activity": "Services Activity",
                        "my_information": "My Information",
                        "document_uploads": "Document Uploads",
                        "action_plan": "Action Plan and Referral",
                        "appointments_and_communication": "Appointments & Communication",
                        "appointments": "Appointments",
                        "video_chat_room": "Video chat room",
                        "feedback_and_complaints": "Feedback",
                        "learning_videos": "Learning Centre",
                        "lasi_coalition_directory": "LASI Coalition Directory",
                        "service_providers_directory": "Service Providers Directory",
                        "service_providers_categories": "Service Providers Categories",
                        "previous_sessions": "Previous sessions",
                        "contact_us": "Contact Support",
                        "accounts": "Accounts",
                        "clients": "Clients",
                        "workers": "Workers",
                        "reports": "Reports",
                        "report": "Report",
                        "intake_forms": "Intake Forms",
                        "sign_out": "Sign out"
                    }
                },
                "titles": {
                    "dashboard": "Dashboard"
                },
                "users": {
                    "titles": {
                        "main": "User",
                        "index": "User list",
                        "create": "User creation",
                        "edit": "User modification"
                    },
                    "actions": {
                        "destroy": "Delete selected users",
                        "enable": "Enable selected users",
                        "disable": "Disable selected users"
                    }
                },
                "roles": {
                    "titles": {
                        "main": "Role",
                        "index": "Role list",
                        "create": "Role creation",
                        "edit": "Role modification"
                    }
                },
                "metas": {
                    "titles": {
                        "main": "Meta",
                        "index": "Meta list",
                        "create": "Meta creation",
                        "edit": "Meta modification"
                    },
                    "actions": {
                        "destroy": "Delete selected metas"
                    }
                },
                "form_submissions": {
                    "titles": {
                        "main": "Submission",
                        "index": "Submission list",
                        "show": "Submission detail"
                    },
                    "actions": {
                        "destroy": "Delete selected submissions"
                    }
                },
                "form_settings": {
                    "titles": {
                        "main": "Forms",
                        "index": "Form setting list",
                        "create": "Form setting creation",
                        "edit": "Form setting modification"
                    },
                    "descriptions": {
                        "recipients": "Example: 'webmaster@example.com' or 'sales@example.com,support@example.com' . To specify multiple recipients, separate each email address with a comma.",
                        "message": "The message to display to the user after submission of this form. Leave blank for no message."
                    }
                },
                "redirections": {
                    "titles": {
                        "main": "Redirection",
                        "index": "Redirection list",
                        "create": "Redirection creation",
                        "edit": "Redirection modification"
                    },
                    "actions": {
                        "destroy": "Delete selected redirections",
                        "enable": "Enable selected redirections",
                        "disable": "Disable selected redirections"
                    },
                    "types": {
                        "permanent": "Permanent redirect (301)",
                        "temporary": "Temporary redirect (302)"
                    },
                    "import": {
                        "title": "CSV file import",
                        "label": "Select CSV file to import",
                        "description": "File must have 2 columns with \"source\" and \"target\" as heading, redirection will be permanent by default"
                    }
                },
                "posts": {
                    "statuses": {
                        "draft": "Draft",
                        "pending": "Pending",
                        "published": "Published"
                    },
                    "titles": {
                        "main": "Posts",
                        "index": "Post list",
                        "create": "Create post",
                        "edit": "Edit post",
                        "publication": "Publication options"
                    },
                    "descriptions": {
                        "meta_title": "If leave empty, title will be that of article' title by default.",
                        "meta_description": "If leave empty, description will be that of article's summary by default."
                    },
                    "placeholders": {
                        "body": "Write your content...",
                        "meta_title": "Article's title.",
                        "meta_description": "Article's summary."
                    },
                    "actions": {
                        "destroy": "Delete selected posts",
                        "publish": "Publish selected posts",
                        "pin": "Pin selected posts",
                        "promote": "Promote selected posts"
                    }
                },
                "action_plans": {
                    "statuses": {
                        "referred": "Referred",
                        "in_progress": "In Progress",
                        "completed": "Completed"
                    },
                    "types": {
                        "services": "Services",
                        "service_provider": "Service Provider",
                        "lasi_agency": "LASI Agency",
                        "lms_course": "LMS Course"
                    },
                    "priority_levels": {
                        "low": "Low",
                        "medium": "Medium",
                        "high": "High"
                    },
                    "identified_need": "Identified need",
                    "required_action": "Required Action"
                },
                "documents": {
                    "titles": {
                        "page_title": "Document Uploads",
                        "my_dock": "My documents"
                    },
                    "table_fields": {
                        "title": "Title",
                        "size": "Size",
                        "date_time": "Date & Time"
                    }
                },
                "intake_forms": {
                    "statuses": {
                        "created": "Created",
                        "saved": "Saved",
                        "submitted": "Submitted",
                        "approved": "Approved",
                        "rejected": "Rejected",
                        "pending": "Pending",
                        "all": "All"
                    },
                    "titles": {
                        "main": "Intake forms",
                        "index": "List of forms",
                        "create": "Create intake form",
                        "edit": "Edit intake form",
                        "accept": "Accept Intake Form",
                        "date_of_completion": "Date of completion",
                        "reviewed_by": "Reviewed By",
                        "review_intake": "Review Intake form"
                    },
                    "placeholders": {
                        "dropzone": "Drop files here or click button “Upload files”"
                    },
                    "labels": {
                        "applicant_details": "Applicant details",
                        "intake_form": "Intake Form",
                        "received": "Received",
                        "assign_worker": "Assign worker",
                        "assigned_worker": "Assigned worker",
                        "assigned_clients": "Assigned clients",
                        "review": "Review",
                        "print": "Print",
                        "received_date": "Received date"
                    },
                    "options": {
                        "gender": {
                            "male": "Male",
                            "female": "Female"
                        },
                        "languages": {
                            "english": "English",
                            "french": "French"
                        },
                        "marital_statuses": [
                            "Single",
                            "Married",
                            "Separated",
                            "Divorced",
                            "Widowed",
                            "Common Law Partner"
                        ],
                        "immigration_classes": [
                            "Business Class (Investor, Federal Enterpreneur, Start-Up, Via, Self-Employed)",
                            "Canadian Experience Class",
                            "Family Sponsorship",
                            "Federal Skilled Trades Program",
                            "Federal Skilled Worker Program",
                            "Humanitarian/Compassionate Application in Process",
                            "Humanitarian/Compassionate Grounds",
                            "Live-In Caregiver",
                            "Provincial Nominee",
                            "Quebec Selected Skilled Worker Program",
                            "Refugee Class: Blended Sponsored Refugee",
                            "Refugee Class: Government-Assisted Refugee",
                            "Refugee Class: Privately Sponsored",
                            "Refugee Class: Refugee Appeal Division (RAD)",
                            "Refugee Class: Refugee Claimant",
                            "Refugee Class: Refused Refugee Claimant",
                            "Refugee Class: Other",
                            "Caregiver Pathway",
                            "Dependent",
                            "Study Permit",
                            "Temporary Resident Permit (Formerly known as Minister’s Permit)",
                            "Assisted Relatives of Independent Immigrant",
                            "Other Independent Immigrant",
                            "Visitor (Tourist, Business Visitor, Parent and Grandparent SuperVisa)",
                            "Work Permit (Business People, professionals, intra-company transferees, traders and investors)",
                            "Unknown"
                        ],
                        "immigration_statuses": [
                            "Canadian Citizen",
                            "Citizen-By-Birth",
                            "Citizen (Aboriginal)",
                            "Conventional Refugee",
                            "Humanitarian/Compassionate Application in Process",
                            "Judicial Review",
                            "Live-In Caregiver",
                            "No Status",
                            "Permanent Residence Application in Process",
                            "Permanent Residence",
                            "Pre Removal Risk Assessment",
                            "Protected Person",
                            "Provincial Nominee awaiting CIC approval for PR",
                            "Refugee Appeal",
                            "Refugee Claimant",
                            "Refused Refugee Claimant",
                            "Spousal or Children Sponsorship",
                            "Student",
                            "Temporary Foreign Worker",
                            "Temporary Resident Permit Worker",
                            "Unknown",
                            "Visitor",
                            "Work Permit (Post-graduate)"
                        ],
                        "immigration_id_types": [
                            "Permanent Residence Card  ",
                            "Landing Document",
                            "Citizenship Card",
                            "Citizenship Certificate",
                            "Intend to Land",
                            "Live-in Caregiver Program Work Permit",
                            "Notice of Decision",
                            "Refugee Claimant Document",
                            "Refugee Claimant Document & Notice of Decision",
                            "Work Permit",
                            "Other"
                        ],
                        "immigration_citizen_intends": [
                            "No",
                            "Yes"
                        ]
                    },
                    "redirect_agency": {
                        "modal_header": "Register with an Agency",
                        "form_submitted": "Intake form submitted!",
                        "message1": "If you haven't registered with an Agency yet, please proceed to \"LASI Coalition Directory\" section to select an agency and required services.",
                        "message2": "Your intake form will be then shared with the agency automatically.",
                        "take_me_there": "Take me there",
                        "message3": "You have been registered to this agency - ",
                        "message4": "You will receive an email when your form is accepted and then you can book your first appointment!"
                    }
                },
                "feedback": {
                    "titles": {
                        "page_title": "Feedback",
                        "new_comment": "Leave a Feedback or Complaint",
                        "leave_reply": "Leave a reply",
                        "reply_to": "Reply to",
                        "comments": "Comments",
                        "satisfaction_rate": "Your satisfaction rate",
                        "clients_satisfaction_rate": "Clients satisfaction rate"
                    },
                    "types": {
                        "feedback": "Feedback",
                        "complaint": "Complaint"
                    },
                    "counter_data": {
                        "total": "Total",
                        "avg": "Avg. rating",
                        "not_replied": "Not replied"
                    },
                    "table_fields": {
                        "name": "Name",
                        "rating": "Rating",
                        "feedback": "Feedback",
                        "placed": "Placed"
                    }
                },
                "appointments": {
                    "titles": {
                        "appointment_Request": "Appointment Request",
                        "no_appointments": "There are no upcoming appointments"
                    },
                    "labels": {
                        "type_of_appointment": "Type of appointment",
                        "schedule_meeting": " Schedule a meeting",
                        "appointment_buffer": "Appointment Time"
                    },
                    "placeholders": {
                        "reason": "Reason",
                        "agency": "Agency",
                        "notes": "Notes",
                        "select_date": "Select date",
                        "select_duration": "Select duration",
                        "select_time": "Select time",
                        "select_client": "Select client"
                    },
                    "types": {
                        "in_person": "In Person",
                        "online": "Online"
                    },
                    "statuses": {
                        "scheduled": "Scheduled",
                        "confirmed": "Confirmed",
                        "completed": "Completed",
                        "canceled": "Canceled",
                        "not_completed": "Not Completed"
                    },
                    "messages": {
                        "no_agency_assigned": "Please complete registration with an Agency first!"
                    }
                },
                "video_char_room": {
                    "titles": {
                        "page_title": "Video Chat Room"
                    }
                },
                "received_services": {
                    "title": "Received services",
                    "table": {
                        "fields": {
                            "service": "Service",
                            "agency": "Agency",
                            "details": "Details",
                            "worker": "Worker"
                        }
                    }
                },
                "register_to_agency": {
                    "title": "Select service(s)"
                },
                "courses": {
                    "create_new": "Create New Course",
                    "create": "Create Course",
                    "create_course_confirm": "Would you like to create a new course on LearnWorlds?",
                    "account_settings": "Account Settings",
                    "account_settings_message": "Please contact LASI Webmaster to give you privileges on LearnWorlds platform",
                    "view_course": "View Course",
                    "manager_view_confirm1": "Would you like to view ",
                    "manager_view_confirm2": " on LearnWorlds?",
                    "manage_course": "Manage Course",
                    "profile_info": "Learning Centre Profile Info",
                    "courses": "Courses",
                    "hours": "Hours",
                    "achievements": "Achievements",
                    "certificates": "Certificates",
                    "progress_not_started": "Not Started",
                    "progress_in_progress": "In-Progress",
                    "progress_completed": "Completed",
                    "course_completed": "Course Completed!",
                    "course_completed_message": "This course has already been completed!",
                    "resume_course": "Resume Course",
                    "resume_course_message": "Would you like to resume this course:",
                    "resume": "Resume",
                    "start_course": "Start Course",
                    "start_course_message": "Would you like to start this course:",
                    "start": "Start",
                    "cancel": "Cancel",
                    "registered_users": "Users",
                    "add_user": "Add User",
                    "add_user_modal_title": "Add Existing LASI User to LearnWorlds Course",
                    "add_user_modal_subheading": "Search and enroll existing user to this course",
                    "add_user_modal_success": "User has been successfully added to this course!",
                    "selected_client": "Selected Client",
                    "add_user_modal_not_found": "Selected client does not have a LearnWorlds Account!",
                    "add_user_modal_create_account": "Would you like to create a new account for this user in LearnWorlds?",
                    "add_user_modal_exists": "This user is already added to this course!",
                    "enroll_user": "Enroll User",
                    "create_user": "Create User",
                    "ok": "Ok",
                    "client_search_text": "Search by name...",
                    "client_search_select": "Select",
                    "download_certificate": "Download Certificate",
                    "all": "All",
                    "my_organization": "My Organization",
                    "no_courses": "No courses started yet!",
                    "client_progress": "Client Progress",
                    "my_courses": "My Courses",
                    "my_clients": "My Clients",
                    "track_clients_progress": "Track Clients Progress"
                },
                "service_providers_directory": {
                    "service_providers_directory": "Service Providers Directory",
                    "search": "Search",
                    "favourites": "Favourites",
                    "search_region_header": "Search for services in your region",
                    "select_service_description": "Or select one of the type of services below to find community agencies in your area.",
                    "sub_categories": "Sub-Categories",
                    "service_providers_for": "Service Providers for",
                    "total_service_providers_found": "Total Service Providers found",
                    "no_category_found": "No category found for",
                    "search_results": "Search results for",
                    "name": "Name",
                    "address": "Address",
                    "favourite": "Favourite",
                    "select_sub_category": "Select Category or Search...",
                    "loading": "Loading",
                    "service_providers": "Service Providers",
                    "enter_search": "Search..."
                },
                "service_providers_categories": {
                    "manage_service_providers_categories": "Manage Service Providers Categories",
                    "create_category": "Create Category",
                    "category_name": "Category Name",
                    "211_code": "211 Code",
                    "actions": "Actions",
                    "add_service_provider_category": "Add Service Provider Category",
                    "add_category_name_en": "Category Name (English)",
                    "add_category_name_placeholder_en": "Enter category name (English)...",
                    "add_category_name_fr": "Category Name (French)",
                    "add_category_name_placeholder_fr": "Enter category name (French)...",
                    "add_category_211_code_placeholder": "Enter 211 (Taxonomy) code...",
                    "create": "Create",
                    "cancel": "Cancel",
                    "edit_service_provider_category": "Edit Service Provider Category",
                    "update": "Update",
                    "delete_service_provider_category": "Delete Service Provider Category",
                    "delete_confirm_message": "Are you sure you would like to delete this category: ",
                    "delete": "Delete",
                    "success": "Success",
                    "add_success_message": "New service provider category was successfully added!",
                    "edit_success_message": "Service provider category was successfully updated!",
                    "delete_success_message": "Service provider category was successfully deleted!",
                    "fa_icon": "Icon",
                    "fa_icon_code_placeholder": "Enter Font Awesome code like this - fas fa-ambulance",
                    "fa_icon_helper_text": "Please search for icon code here",
                    "icon_code": "Icon Code"
                }
            },
            "frontend": {
                "titles": {
                    "home": "Home",
                    "available_services": "Available Services",
                    "community_connect": "Community Connect",
                    "about": "About Us",
                    "contact": "Contact Us",
                    "blog": "Blog",
                    "message_sent": "Message sent",
                    "legal_mentions": "Legal mentions",
                    "administration": "Administration",
                    "filter_by": "Filter By:",
                    "contact_info": "Contact Information",
                    "services_provided": "Services provided by:",
                    "program_manager_email": "Program Manager Email"
                },
                "submissions": {
                    "message_sent": "<p>Your message has been successfully sent</p>"
                },
                "placeholders": {
                    "locale": "Select your language",
                    "timezone": "Select your timezone",
                    "select_options": "Select options",
                    "selected_options": "Selected options"
                },
                "blog": {
                    "published_at": "Published at {date}",
                    "published_at_with_owner_linkable": "Published at {date} by <a href=\"{link}\">{name}</a>",
                    "tags": "Tags"
                }
            },
            "date_created": "Date Created",
            "date_completed": "Date Completed",
            "filter_by_category": "Filter by Category...",
            "filter_by_language": "Filter by Language...",
            "subject": "Subject",
            "send_message": "Send Message",
            "export_db_header": "Export Database Dump",
            "export_db_modal_1": "Would you like to initiate a Database Backup?",
            "export_db_modal_2": "Please note that it can take some time for the DB Dump to get ready and the Webmasters will be notified with a \r\n    download link, once completed.",
            "export_db_modal_3": "The link will only be active for 30 mins and will expire after that.",
            "user_reactivate_success": "The account has been activated successfully and an email notification was sent to the user!",
            "reactivate_account": "Reactivate Account",
            "user_deactivated_message": "This user has a deactivated account in the system.",
            "user_reactivate_question": "Would you like to reactivate this user instead?",
            "languages": "Languages"
        },
        "logs": {
            "backend": {
                "users": {
                    "created": "User ID {user} created",
                    "updated": "User ID {user} updated",
                    "deleted": "User ID {user} deleted"
                },
                "form_submissions": {
                    "created": "Form submission ID {form_submission} created"
                }
            },
            "frontend": []
        },
        "mails": {
            "layout": {
                "hello": "Hello !",
                "regards": "Thank you",
                "trouble": "If you’re having trouble clicking the {action} button, copy and paste the URL below into your web browser :",
                "all_rights_reserved": "All rights reserved."
            },
            "password_reset": {
                "subject": "Password reset",
                "intro": "Dear {user_name}",
                "intro1": "A password reset request has been received for your {user_email} account at the Local Agencies Serving Immigrants (LASI) portal.",
                "intro2": "If you have made this request, please click on the following link:",
                "action": "Reset Password",
                "outro": "If you have not made this request, please ignore this email."
            },
            "contact": {
                "subject": "New contact message",
                "new_contact": "You've got a new contact message. Submission detail :"
            },
            "alert": {
                "subject": "[{app_name}] Exception error",
                "message": "You've got unexpected server exception error which message is : {message}.",
                "trace": "All trace detail :"
            },
            "client_intake_form_submit": {
                "subject": "Intake form submit",
                "message": "Dear {user_name}, <br><br>\r\n                      This email is to confirm that we have received your completed intake form. <br>\r\n                      Please browse the LASI Agencies directory to select the agency(s) of interest and click “Register” on the top right to request services. <br>\r\n                      Thank you <br>\r\n                      LASI Team."
            },
            "client_intake_form_declined": {
                "subject": "Intake form declined",
                "message": "Dear {user_name}, <br><br>\r\n                      Your registration at {agency_name} is on hold until we receive the required information. <br>\r\n                      Please login to the LASI Portal and update your information on My Information then click Save or Submit.\r\n                      Then please select and register to the agency again in order to receive the updated information. <br><br>\r\n                      Thank you,<br><br>\r\n                      LASI Portal"
            },
            "client_intake_form_accept": {
                "subject": "Intake form accept",
                "message": "Dear {user_name}, <br><br>\r\n                      Your service request submitted to {agency_name} has been approved\r\n                      and a worker has been assigned to you:<br>\r\n                      Worker Name: {worker_name}<br>\r\n                      Phone Number: {worker_phone}<br>\r\n                      Extension Number: {worker_ext}<br>\r\n                      Email: {worker_email}<br>\r\n                      Please login to the LASI Portal (provide link) to submit an appointment\r\n                      request and begin receiving on-line settlement services.\r\n                      Thank you,<br><br>\r\n                      LASI Portal"
            },
            "worker_intake_form_accept": {
                "subject": "Intake form accept",
                "message": "Dear {worker_name}, <br><br>\r\n                      The following client has been assigned to your caseload:<br>\r\n                      {client_name}<br>\r\n                      Please login to LASI Portal to review the client’s intake form as well as other service records (if any).\r\n                      You will be able to update the client’s case, schedule/approve appointments and meet the client via online video chatting. <br><br>\r\n                      Thank you,<br><br>\r\n                      LASI Portal"
            },
            "client_intake_form_non_ontario_submit": {
                "subject": "Intake form submit",
                "message": "Dear {user_name}, <br><br>\r\n                      Please note that we are not able to support Non-Ontario residents at the moment you can refer to the following links of other settlement organizations for support: <br><br>\r\n                      Thank you <br>\r\n                      LASI Team."
            },
            "client_send_email_contact_us": {
                "subject": "Client send email contact us",
                "message": "From {full_name}, {email}<br>\r\n                      Topic: {topic}<br>\r\n                      Text: {text}<br>"
            },
            "front_end_contact_us_send_email": {
                "subject": "From Contact Us Send Email Form",
                "message": "From {name}, {email}<br><br>\r\n                      Message: {message}<br>"
            },
            "client_agency_registration": {
                "subject": "Agency registration",
                "message": "Dear {user_name}, <br><br>\r\n                      Thank you for registering to receive services from {agency_name}. <br>\r\n                      You will be notified with your assigned worker’s name in a separate email. <br>\r\n                      Thank you <br>\r\n                      LASI Portal."
            },
            "client_agency_invitation": {
                "subject": "Lasi registration",
                "message": "Dear {user_name}, <br><br>\r\n                      You have been registered with the following LASI agency: {agency_name}. <br>\r\n                      To login please use your email and the following password: {password} <br><br>\r\n                      Thank you <br>\r\n                      LASI Portal."
            },
            "agency_registration_notification": {
                "subject": "New Client Registration",
                "message": "Dear {user_name}, <br>\r\n                      The following client has registered with {agency_name} : {client_name}  <br>\r\n                      Please login to the LASI Portal to review the client’s Intake Form and provide the required action.<br><br>\r\n                      LASI Portal."
            },
            "agency_new_doc_upload_notification": {
                "subject": "New Document upload",
                "message": "New Document upload <br>\r\n                      Thank you <br><br>\r\n                      LASI Team."
            },
            "agency_doc_removed_notification": {
                "subject": "Document removed",
                "message": "Document removed <br>\r\n                      Thank you <br><br>\r\n                      LASI Team."
            },
            "client_worker_assign_notification": {
                "subject": "Worker assignation",
                "message": "Dear {client_name}, <br><br>\r\n                      You have been assigned to worker {worker_name}\r\n                      <br><br>Thank you,<br>LASI Portal"
            },
            "worker_clients_assign_notification": {
                "subject": "Clients assignation",
                "message": "Dear {worker_name}, <br><br>\r\n                      You have been assigned to {clients_list_message}\r\n                      <br><br>Thank you,<br>LASI Portal"
            },
            "client_worker_referral_notification": {
                "subject": "Worker referral",
                "message": "Dear {client_name}, <br><br>\r\n                      {worker_name} has been referred to you.\r\n                      <br><br>Thank you,<br>LASI Portal"
            },
            "worker_client_referral_notification": {
                "subject": "Clients referral",
                "message": "Dear {worker_name}, <br><br>\r\n                      You have been referred to {client_name}.\r\n                      <br><br>Thank you,<br>LASI Portal"
            },
            "client_registration_notification": {
                "subject": "Registration",
                "message": "Dear {client_name},<br><br>Thank you for creating an account\r\n                      with the Local Agencies Serving Immigrants (LASI) portal,\r\n                      where you will be able to access information and services\r\n                      to support your settlement in Ottawa.<br><br>Your Username to access the portal is {client_email}.<br><br>\r\n                      Please login and click on <b>My Information</b> to complete your <b>Intake Form</b> and <b>upload related documents</b> before\r\n                      registering with an agency.<br><br>Your information will be reviewed and a worker will be assigned to you."
            },
            "staff_registration_notification": {
                "subject": "Registration",
                "message": "Dear {user_name},<br>An account has been created for you on the Local Agencies Serving Immigrants (LASI) portal.<br><br>\r\n                      Your Username to access the portal is {user_email}.<br><br>Please remember to login and update your profile with your contact information,\r\n                      speaking languages, and service categories you are working on. Refer to the user manual and your manager for any questions on portal usage.<br><br>Thank you<br><br>LASI Portal"
            },
            "appointment_request_from_worker_to_client": {
                "subject": "Appointment request",
                "message": "Dear {client_name},<br><br>{worker_name}, has sent you an appointment request as follows:<br><br>\r\n                      {appointment_date} at {appointment_time} about {appointment_reason}.<br><br>Please login to your account\r\n                      on the LASI Portal to review the appointment request and take action. Select your action for scheduled\r\n                      appointments by clicking on the three dots on the right of the appointment.<br><br><br>\r\n                      Thank you,<br>LASI Portal"
            },
            "appointment_request_from_client_to_worker": {
                "subject": "Appointment request",
                "message": "Dear {worker_name},<br><br><strong>{client_name}</strong> has sent you an appointment request as follows:<br><br>\r\n                     {appointment_date} at {appointment_time} about {appointment_reason}.<br><br>Please login to the LASI Portal\r\n                     to review the request and take action. Select your action for the appointment request by clicking on the\r\n                     three dots to the right of the appointment.<br><br><br>\r\n                     Thank you,<br>LASI Portal"
            },
            "appointment_confirm_to_client": {
                "subject": "Appointment confirm",
                "message": "Dear {client_name},<br><br><strong>{worker_name}</strong> has confirmed your appointment request as follows:<br><br>\r\n                     {appointment_date} at {appointment_time} about {appointment_reason}.<br><br>Please remember to login to your account on LASI Portal\r\n                     at the time of appointment, click onVideo Chat Room in the menu and select your appointment to establish video chatting connection.\r\n                     Please make sure that your device has an activated webcam and microphone prior to connecting for the appointment.<br><br><br>\r\n                     Thank you,<br>LASI Portal"
            },
            "appointment_confirm_to_worker": {
                "subject": "Appointment confirm",
                "message": "Dear {worker_name},<br><br><strong>{client_name}</strong> has confirmed the appointment request you sent as follows:<br><br>\r\n                     {appointment_date} at {appointment_time} about {appointment_reason}.<br><br>Please remember to login to the LASI Portal at the time\r\n                     of appointment, click on Video Chat Room in the menu and select the appointment to establish the video chatting connection. Your device must\r\n                     have an activated webcam and microphone for the video session.<br><br><br>\r\n                     Thank you,<br>LASI Portal"
            },
            "appointment_canceled_to_client": {
                "subject": "Appointment cancel",
                "message": "Dear {client_name},<br><br><strong>{worker_name}</strong> has had to cancel the following scheduled appointment:<br><br>\r\n                     {appointment_date} at {appointment_time} about {appointment_reason}.<br><br>Please follow up with your worker directly or send another\r\n                     appointment request with another date/time. <br><br><br>\r\n                     Thank you,<br>LASI Portal"
            },
            "appointment_canceled_by_client": {
                "subject": "Appointment cancel",
                "message": "Dear {worker_name},<br><br><strong>{client_name}</strong> has canceled the following scheduled appointment:<br><br>\r\n                     {appointment_date} at {appointment_time} about {appointment_reason}.<br><br>Please follow up with {client_name} directly or\r\n                     send another appointment request with another date/time.<br><br><br>\r\n                     Thank you,<br>LASI Portal"
            },
            "action_plan_to_client": {
                "subject": "Action Plan",
                "table": {
                    "title": "Title/Identified Need",
                    "action": "Required Action"
                },
                "intro1": "Dear {client_name},<br><br><strong>{worker_name}</strong> has created an action plan for you to refer you to the following services,\r\n                      activities or organizations:<br>",
                "intro2": "Please login to your account at LASI Portal and update your Action Plan accordingly.",
                "intro3": "Thank you,",
                "intro4": "LASI Portal"
            },
            "role_changed": {
                "subject": "User Role Changed",
                "message": "Dear {user_name},<br><br>Your user role has been updated to <strong>user_role</strong><br><br><br>\r\n                     Thank you,<br>LASI Portal"
            }
        },
        "pagination": {
            "previous": "Previous",
            "next": "Next"
        },
        "passwords": {
            "password": "Passwords must be at least six characters and match the confirmation.",
            "reset": "Your password has been reset!",
            "sent": "We have e-mailed your password reset link!",
            "sent-client": "We have e-mailed password reset link to this client!",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that e-mail address."
        },
        "permissions": {
            "categories": {
                "blog": "Blog",
                "form": "Forms",
                "access": "Access",
                "seo": "SEO",
                "intake_forms": "Intake Forms",
                "documents": "Documents",
                "feedback": "Feedback",
                "workers": "Workers",
                "clients": "Clients"
            },
            "access": {
                "backend": {
                    "display_name": "Backoffice access",
                    "description": "Can access to administration pages."
                }
            },
            "view": {
                "form_settings": {
                    "display_name": "View form settings",
                    "description": "Can view form settings."
                },
                "form_submissions": {
                    "display_name": "View form submissions",
                    "description": "Can view form submissions."
                },
                "users": {
                    "display_name": "View users",
                    "description": "Can view users."
                },
                "roles": {
                    "display_name": "View roles",
                    "description": "Can view roles."
                },
                "metas": {
                    "display_name": "View metas",
                    "description": "Can view metas."
                },
                "redirections": {
                    "display_name": "View redirections",
                    "description": "Can view redirections."
                },
                "posts": {
                    "display_name": "View all posts",
                    "description": "Can view all posts."
                },
                "intake_forms": {
                    "display_name": "View all intake forms",
                    "description": "Can view all intake forms."
                },
                "documents": {
                    "display_name": "View all documents",
                    "description": "Can view all documents."
                },
                "own": {
                    "posts": {
                        "display_name": "View own posts",
                        "description": "Can view own posts."
                    },
                    "intake_forms": {
                        "display_name": "View own intake form",
                        "description": "Can own intake form."
                    },
                    "documents": {
                        "display_name": "View own documents",
                        "description": "Can view own documents."
                    }
                },
                "feedback": {
                    "display_name": "View all feedback",
                    "description": "Can view all feedback."
                },
                "workers": {
                    "display_name": "View workers",
                    "description": "Can view workers."
                },
                "clients": {
                    "display_name": "View clients",
                    "description": "Can view clients."
                }
            },
            "create": {
                "form_settings": {
                    "display_name": "Create form settings",
                    "description": "Can create form settings."
                },
                "users": {
                    "display_name": "Create users",
                    "description": "Can create users."
                },
                "roles": {
                    "display_name": "Create roles",
                    "description": "Can create roles."
                },
                "metas": {
                    "display_name": "Create metas",
                    "description": "Can create metas."
                },
                "redirections": {
                    "display_name": "Create redirections",
                    "description": "Can create redirections."
                },
                "posts": {
                    "display_name": "Create posts",
                    "description": "Can create all posts."
                },
                "intake_forms": {
                    "display_name": "Create intake form",
                    "description": "Can create intake form."
                },
                "documents": {
                    "display_name": "Create documents",
                    "description": "Can create documents."
                },
                "feedback": {
                    "display_name": "Create feedback",
                    "description": "Can create feedback."
                }
            },
            "edit": {
                "form_settings": {
                    "display_name": "Edit form settings",
                    "description": "Can edit form settings."
                },
                "users": {
                    "display_name": "Edit users",
                    "description": "Can edit users."
                },
                "roles": {
                    "display_name": "Edit roles",
                    "description": "Can edit roles."
                },
                "metas": {
                    "display_name": "Edit metas",
                    "description": "Can edit metas."
                },
                "redirections": {
                    "display_name": "Edit redirections",
                    "description": "Can edit redirections."
                },
                "posts": {
                    "display_name": "Edit all posts",
                    "description": "Can edit all posts."
                },
                "intake_forms": {
                    "display_name": "Edit all intake forms",
                    "description": "Can edit all intake forms."
                },
                "own": {
                    "posts": {
                        "display_name": "Edit own posts",
                        "description": "Can edit own posts."
                    },
                    "intake_forms": {
                        "display_name": "Edit own intake form",
                        "description": "Can edit own intake form."
                    }
                }
            },
            "delete": {
                "form_settings": {
                    "display_name": "Delete form settings",
                    "description": "Can delete form settings."
                },
                "form_submissions": {
                    "display_name": "Delete form submissions",
                    "description": "Can delete form submissions."
                },
                "users": {
                    "display_name": "Delete users",
                    "description": "Can delete users."
                },
                "roles": {
                    "display_name": "Delete roles",
                    "description": "Can delete roles."
                },
                "metas": {
                    "display_name": "Delete metas",
                    "description": "Can delete metas."
                },
                "redirections": {
                    "display_name": "Delete redirections",
                    "description": "Can delete redirections."
                },
                "posts": {
                    "display_name": "Delete all posts",
                    "description": "Can delete all posts."
                },
                "intake_forms": {
                    "display_name": "Delete all intake forms",
                    "description": "Can delete all intake forms."
                },
                "documents": {
                    "display_name": "Delete all documents",
                    "description": "Can delete all documents."
                },
                "workers": {
                    "display_name": "Delete workers",
                    "description": "Can delete workers."
                },
                "own": {
                    "posts": {
                        "display_name": "Delete own posts",
                        "description": "Can delete own posts."
                    },
                    "intake_forms": {
                        "display_name": "Delete own intake forms",
                        "description": "Can delete own intake forms."
                    },
                    "documents": {
                        "display_name": "Delete own documents",
                        "description": "Can delete own documents."
                    }
                }
            },
            "publish": {
                "posts": {
                    "display_name": "Publish posts",
                    "description": "Can manage posts publication."
                }
            },
            "impersonate": {
                "display_name": "Impersonate user",
                "description": "Can take ownership of others user identities. Useful for tests."
            }
        },
        "routes": {
            "home": "home",
            "available-services": "available-services",
            "about": "about",
            "contact": "contact",
            "contact-sent": "contact-sent",
            "legal-mentions": "legal-mentions",
            "redactors": "blog/redactors/{user}",
            "agency-inside": "agency-inside",
            "community-connect": "community-connect",
            "service-inside": "service-inside",
            "get-agencies": "get-agencies"
        },
        "static": {
            "backend": {
                "pages": {
                    "client_profile": {
                        "action_plan": "Action Plan",
                        "add_amend": "Add /amend additional sections",
                        "assessment_form": "Assessment Form",
                        "assigned_to": "Assigned to",
                        "case_narrations": "Case Narrations",
                        "contacts": "Contacts",
                        "languages": "Languages",
                        "referral": "Referral",
                        "tab1": {
                            "title": "Appointments"
                        },
                        "tab2": {
                            "title": "Services"
                        },
                        "tab3": {
                            "title": "Action Plan"
                        },
                        "tab4": {
                            "title": "Assessment Form"
                        },
                        "tab5": {
                            "title": "Case Narrations"
                        },
                        "title": "Client profile"
                    },
                    "intake_form": {
                        "callout": "Complete, edit and view your Intake Form information which will be used by the agencies\r\n                              you are registered with to provide you with the desired services.",
                        "footer_text": "The information submitted in the intake form above will only be shared with the LASI Agencies\r\n                                   that you will register with in order to provide you with the required services.",
                        "tab1": {
                            "title": "Demographic and Contact Information"
                        },
                        "tab2": {
                            "title": "Immigration Related Information"
                        },
                        "title": "Intake Form"
                    },
                    "my_profile": {
                        "tab1": {
                            "title": "My Information"
                        },
                        "tab2": {
                            "title": "Services"
                        }
                    }
                }
            },
            "frontend": {
                "pages": {
                    "about": {
                        "h1": "About us",
                        "h5": "Collectively, LASI agencies deliver services to more than 20,000 immigrants and refugees per year.  The service providers who make up LASI are:",
                        "li1": "Catholic  Centre for Immigrants (CCI)",
                        "li10": "Worldskills Employment Centre",
                        "li11": "YMCA-YWCA of the National Capital Region",
                        "li2": "Conseil Economic & Social d’Ottawa - Carleton (CESOC)",
                        "li3": "Immigrant Women Services Ottawa (IWSO)",
                        "li4": "Jewish Family Services (JFS)",
                        "li5": "La Cité Collegiale",
                        "li6": "Lebanese and Arab Social Service Agency (LASSA)",
                        "li7": "Ottawa Chinese Community Service Centre (OCCSC)",
                        "li8": "Ottawa Community Immigrant Services Organization (OCISO)",
                        "li9": "Somali Centre for Family Services (SFS)"
                    },
                    "contact": {
                        "h1": "Contact Us",
                        "p1": "Let us know how we can help you."
                    },
                    "forgot_pass": {
                        "p1": "Enter your email address below. We'll look for your account and send you a password reset email."
                    },
                    "home": {
                        "main_banner": {
                            "h1": "Are you a recently arrived <span class=\"bold-red\"> immigrant or refugee</span> to Ottawa looking for services to <span class=\"bold-red\">help you get settled?</span>",
                            "p2": "LASI is pleased to offer a one-stop portal for immigrants and refugees new to Ottawa to access the vital information and services they require.",
                            "p3": "Find important settlement, language, health, employment, education, counselling and specialized services offered from all 11 agencies right here.",
                            "tab1": "Agencies",
                            "tab2": "Services"
                        },
                        "page_description": {
                            "h2": "First Days in Canada",
                            "p2": "Have you recently settled in Canada? Learn about Ontario, Canada, Employment and\r\n                             service providers by visiting <a href=\"https://settlement.org/\">Settlement.org</a>\r\n                             to get a guide to your first two weeks in Ontario, Canada"
                        }
                    },
                    "service-provider": {
                        "h1": "Service Provider"
                    },
                    "services": {
                        "top_section": {
                            "h1": "Services",
                            "lasi_ca": {
                                "h5": "Narrow your <br> search"
                            },
                            "p1": "LASI is pleased to offer a one-stop portal for immigrants and refugees new to Ottawa to access the vital information and services they require.  Find important settlement, language, health, employment, education, counselling and specialized services offered from all 11 agencies right here.",
                            "tab1": "Service Providers Directory",
                            "tab2": "LASI Coalition <br> Agencies"
                        }
                    },
                    "sign_in": {
                        "p1": "Please enter your email address and password to access your portfolio,\r\n                         attend webinars and participate in a scheduled appointment."
                    },
                    "sign_up": {
                        "p1": "Sign up to book an appointment and access services"
                    }
                },
                "sections": {
                    "available_agencies": {
                        "h2": "To get started, use the filters below to find an agency that best fits your needs:",
                        "p1": "The Big Oxmox advised her not to do so, because there were thousands of bad Commas",
                        "p2-1": "SERVICES PROVIDED",
                        "p2-2": "SERVICES LIST TO BE CONFIRMED SOON"
                    },
                    "contact": {
                        "h2": "<h2>Contact Us</h2>",
                        "h3": "<h3>Questions or Comments?</h3>",
                        "p1": "<p>If you have a question about LASI or have a technical difficulty,</p>",
                        "p2": "<p>please send us a message using the contact form.</p>"
                    },
                    "create_account": {
                        "h2-1": "<h2>Create a LASI account</h2>",
                        "h2-2": "<h3>Already have an account?</h3>",
                        "p1": "<h3>Why create a LASI account?</h3><p>Easily book appointments online with various agencies</p><p>Access services</p><p>and much more!</p>"
                    },
                    "our_services": {
                        "h2": "Our <br> Services",
                        "p1": "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.\r\n                         Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
                        "p2": "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.\r\n                         Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
                        "services": {
                            "s1": {
                                "li1": "Settlement - Information on health, housing, education, transportation and other community services",
                                "li2": "Translation & Interpretation",
                                "title": "Learning about &<br>finding services in Ottawa"
                            },
                            "s10": {
                                "li1": "Private Sponsorship",
                                "title": "<br>Other"
                            },
                            "s2": {
                                "li1": "Social & Matching Programs",
                                "li2": "Recreation Programs",
                                "li3": "Conversation Circles",
                                "title": "Community<br>Links"
                            },
                            "s3": {
                                "li1": "English Language Training",
                                "li2": "French Language Training",
                                "li3": "Workplace Language Training",
                                "li4": "Citizenship Preparation Training",
                                "li5": "Caregiver Training",
                                "li6": "Computer Training",
                                "li7": "Other",
                                "title": "Education and<br>Language Training"
                            },
                            "s4": {
                                "li1": "Preparing for Work",
                                "li2": "Occupational Counselling",
                                "li3": "Employer Connections",
                                "li4": "Starting Your Own Business",
                                "title": "Working and<br>Business"
                            },
                            "s5": {
                                "li1": "Clinical Counselling",
                                "li2": "Mental Health",
                                "li3": "Low Income Supports",
                                "li4": "Refugee Intensive Supports",
                                "title": "Social and<br>Health Counselling"
                            },
                            "s6": {
                                "li1": "Crisis Intervention",
                                "li2": "Shelter and health",
                                "li3": "Counselling",
                                "li4": "Support Groups",
                                "title": "Assistance<br>For Women"
                            },
                            "s7": {
                                "li1": "Recreation, leadership & social",
                                "li2": "School based supports",
                                "li3": "Jobs",
                                "li4": "Counselling",
                                "li5": "Legal and crime prevention",
                                "title": "Assistance<br>For Youth"
                            },
                            "s8": {
                                "li1": "Language Services",
                                "li2": "Social programming",
                                "li3": "Recreation, wellness and exercise",
                                "li4": "Community links",
                                "title": "Assistance<br>For Seniors"
                            },
                            "s9": {
                                "li1": "Tax Clinics",
                                "li2": "Budgeting, credit & banking workshops",
                                "li3": "Loans",
                                "title": "Financial<br>Information"
                            }
                        }
                    },
                    "questions": {
                        "h2": "Questions?",
                        "p1": "If you have a question about LASI or have a technical difficulty, please contact us."
                    },
                    "what_is_lasi": {
                        "h2": "What is LASI?",
                        "p1": "Local Agencies Serving Immigrants (LASI) is a coalition of 11 agencies delivering settlement services to immigrants and refugees in the Ottawa area. LASI members work together to advance the social and economic integration of newcomers through continuous service improvement, implementation of collaborative initiatives and engagement with the broader community.",
                        "p2": "Over the years, the LASI Coalition has worked together on joint initiatives to support newcomers.  The LASI settlement services delivery portal is one such initiative.",
                        "p3": "Collectively, LASI agencies deliver services to more than 20,000 immigrants and refugees per year. The service providers who make up LASI are:"
                    }
                }
            }
        },
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} may only contain letters.",
            "alpha_dash": "The {attribute} may only contain letters, numbers, and dashes.",
            "alpha_num": "The {attribute} may only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "numeric": "The {attribute} must be between {min} and {max}.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "string": "The {attribute} must be between {min} and {max} characters.",
                "array": "The {attribute} must have between {min} and {max} items."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "date": "The {attribute} is not a valid date.",
            "date_format": "The {attribute} does not match the format {format}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "email": "The {attribute} must be a valid email address.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field must have a value.",
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "max": {
                "numeric": "The {attribute} may not be greater than {max}.",
                "file": "The {attribute} may not be greater than {max} kilobytes.",
                "string": "The {attribute} may not be greater than {max} characters.",
                "array": "The {attribute} may not have more than {max} items."
            },
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "numeric": "The {attribute} must be at least {min}.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "string": "The {attribute} must be at least {min} characters.",
                "array": "The {attribute} must have at least {min} items."
            },
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "present": "The {attribute} field must be present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} is present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "numeric": "The {attribute} must be {size}.",
                "file": "The {attribute} must be {size} kilobytes.",
                "string": "The {attribute} must be {size} characters.",
                "array": "The {attribute} must contain {size} items."
            },
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid zone.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "url": "The {attribute} format is invalid.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": {
                "name": "Name",
                "display_name": "Display name",
                "username": "Pseudo",
                "email": "Email",
                "first_name": "First name",
                "last_name": "Last name",
                "middle_name": "Middle name",
                "password": "Password",
                "password_confirmation": "Confirm password",
                "old_password": "Old password",
                "new_password": "New password",
                "new_password_confirmation": "Confirm new password",
                "postal_code": "Postal code",
                "city": "City",
                "country": "Country",
                "address": "Address",
                "phone": "Phone",
                "mobile": "Mobile",
                "age": "Age",
                "sex": "Sex",
                "gender": "Gender",
                "day": "Day",
                "month": "Month",
                "year": "Year",
                "hour": "Hour",
                "minute": "Minute",
                "second": "Second",
                "title": "Title",
                "content": "Content",
                "description": "Description",
                "summary": "Summary",
                "excerpt": "Excerpt",
                "date": "Date",
                "time": "Time",
                "available": "Available",
                "size": "Size",
                "roles": "Roles",
                "permissions": "Permissions",
                "active": "Active",
                "message": "Message",
                "g-recaptcha-response": "Captcha",
                "locale": "Localization",
                "route": "Route",
                "url": "URL alias",
                "form_type": "Form type",
                "form_data": "Form data",
                "recipients": "Recipients",
                "source_path": "Original path",
                "target_path": "Target path",
                "redirect_type": "Redirect type",
                "timezone": "Timezone",
                "order": "Display order",
                "image": "Image",
                "status": "Status",
                "pinned": "Pinned",
                "promoted": "Promoted",
                "body": "Body",
                "tags": "Tags",
                "published_at": "Publish at",
                "unpublished_at": "Unpublish at",
                "metable_type": "Entity",
                "email_address": "Email address",
                "date_of_birth": "Date of Birth",
                "place_of_birth": "Place of Birth",
                "address_street": "Street Address",
                "province_state": "Province / State",
                "phone_number": "Phone Number",
                "phone_number_alt": "Alternate Phone Number",
                "mother_tongue": "Mother tongue",
                "marital_status": "Marital Status",
                "nr_family_members": "Number of family members",
                "immigration_class": "Immigration Class",
                "immigration_status": "Immigration Status",
                "immigration_id_type": "Immigration ID type",
                "document_number": "Immigration document number",
                "immigration_country_of_origin": "Country of Origin",
                "immigration_country_of_residence": "Last Country of Residence",
                "immigration_date_arrival": "Date of Arrival",
                "immigration_citizen_intends": "Intends to become Canadian citizen?",
                "port_of_entry": "Port of Entry",
                "topic": "Topic",
                "organization_name": "Organisation name",
                "comment": "Comment",
                "attach_files": "Attach files",
                "rate_us": "Your Satisfactory Rate",
                "role": "Role",
                "agency": "Agency",
                "start_date": "Start Date",
                "end_date": "End Date",
                "phone_ext": "Phone Extension",
                "current_password": "Current password"
            },
            "password_not_match": "Passwords do not match!",
            "password_message": "Password must have at least:",
            "password_length": "8 characters",
            "password_num": "1 numeric character",
            "password_special": "1 special character in (!@#$%^&*)"
        }
    },
    "es": {
        "alerts": {
            "backend": {
                "users": {
                    "created": "Usuario creado",
                    "updated": "Usuario actualizado",
                    "deleted": "Usuario eliminado",
                    "bulk_destroyed": "Usuarios seleccionados eliminados",
                    "bulk_enabled": "Usuarios seleccionados habilitados",
                    "bulk_disabled": "Usuarios seleccionados deshabilitados"
                },
                "roles": {
                    "created": "Rol creado",
                    "updated": "Rol actualizado",
                    "deleted": "Rol eliminado"
                },
                "metas": {
                    "created": "Meta creado",
                    "updated": "Meta actualizado",
                    "deleted": "Meta eliminado",
                    "bulk_destroyed": "Metas seleccionadas eliminadas"
                },
                "form_submissions": {
                    "deleted": "Solicitud eliminada",
                    "bulk_destroyed": "Solicitudes seleccionadas eliminadas"
                },
                "form_settings": {
                    "created": "Configuración de formulario creada",
                    "updated": "Configuración de formulario actualizada",
                    "deleted": "Configuración de formulario eliminada"
                },
                "redirections": {
                    "created": "Redirección creada",
                    "updated": "Redirección actualizada",
                    "deleted": "Redirección eliminada",
                    "bulk_destroyed": "Redirecciones seleccionadas eliminadas",
                    "bulk_enabled": "Redirecciones seleccionadas habilitadas",
                    "bulk_disabled": "Redirecciones seleccionadas deshabilitadas",
                    "file_imported": "Archivo importado con éxito"
                },
                "posts": {
                    "created": "Articulo creado",
                    "updated": "Articulo actualizado",
                    "deleted": "Articulo eliminado",
                    "bulk_destroyed": "Artículos seleccionados eliminados",
                    "bulk_published": "Artículos seleccionados publicados",
                    "bulk_pending": "Los artículos seleccionados esperan moderación",
                    "bulk_pinned": "Los artículos seleccionados fijadas",
                    "bulk_promoted": "Los artículos seleccionados promocionados"
                },
                "actions": {
                    "invalid": "Acción no válida"
                }
            },
            "frontend": []
        },
        "auth": {
            "failed": "Estas credenciales no coinciden con nuestros registros.",
            "throttle": "Demasiados intentos de inicio de sesión. "
        },
        "buttons": {
            "cancel": "Cancelar",
            "save": "Salvar",
            "close": "Cerrar",
            "create": "Crear",
            "delete": "Borrar",
            "confirm": "Confirmar",
            "show": "Mostrar",
            "edit": "Editar",
            "update": "Actualizar",
            "view": "Ver",
            "preview": "Previsualizar",
            "back": "Atras",
            "send": "Enviar",
            "login-as": "Iniciar como {name}",
            "apply": "Aplicar",
            "users": {
                "create": "Crear usuario"
            },
            "roles": {
                "create": "Crear rol"
            },
            "metas": {
                "create": "Crear meta"
            },
            "form_settings": {
                "create": "Crear configuración"
            },
            "redirections": {
                "create": "Crear redirección",
                "import": "Importar CSV"
            },
            "posts": {
                "create": "Crear publicación",
                "save_and_publish": "Guardar y publicar",
                "save_as_draft": "Guardar como borrador"
            }
        },
        "exceptions": {
            "general": "Excepción del servidor",
            "unauthorized": "Acción no permitida",
            "backend": {
                "users": {
                    "create": "Error en la creación del usuario",
                    "update": "Error en la actualización del usuario",
                    "delete": "Error en la eliminación del usuario",
                    "first_user_cannot_be_edited": "No puedes editar el usuario súper administrador",
                    "first_user_cannot_be_disabled": "El usuario súper administrador no puede ser deshabilitado",
                    "first_user_cannot_be_destroyed": "El usuario súper administrador no puede ser eliminado",
                    "first_user_cannot_be_impersonated": "El usuario Super administrador no puede ser suplantado",
                    "cannot_set_superior_roles": "No puedes atribuir roles superiores a los tuyos"
                },
                "roles": {
                    "create": "Error en la creación de roles",
                    "update": "Error en la actualización de roles",
                    "delete": "Error en la eliminación de roles"
                },
                "metas": {
                    "create": "Error en la creación de la meta",
                    "update": "Error en la actualización de la meta",
                    "delete": "Error en la eliminación de la meta",
                    "already_exist": "Ya hay una meta para esta ruta de configuración regional"
                },
                "form_submissions": {
                    "create": "Error en la creación de la solicitud",
                    "delete": "Error en la eliminación de la solicitud"
                },
                "form_settings": {
                    "create": "Error en la creación de configuración de formulario",
                    "update": "Error en la actualización de configuración del formulario",
                    "delete": "Error en la eliminación de configuración del formulario",
                    "already_exist": "Ya hay una configuración vinculada a este formulario"
                },
                "redirections": {
                    "create": "Error en la creación de la redirección",
                    "update": "Error en la actualización de la redirección",
                    "delete": "Error en la eliminación de la redirección",
                    "already_exist": "Ya hay una redirección para este camino"
                },
                "posts": {
                    "create": "Error en la creación del articulo",
                    "update": "Error en la actualización del articulo",
                    "save": "Error en la salvado del articulo",
                    "delete": "Error en la eliminación del articulo"
                },
                "courses": {
                    "create": "Error on course creation",
                    "update": "Error on course updating",
                    "save": "Error on course saving",
                    "delete": "Error on course deletion"
                }
            },
            "frontend": {
                "user": {
                    "email_taken": "Esa dirección de correo electrónico ya está es uso.",
                    "password_mismatch": "Esa no es tu contraseña anterior.",
                    "delete_account": "Error al eliminar la cuenta.",
                    "updating_disabled": "La edición de cuenta está deshabilitada."
                },
                "auth": {
                    "registration_disabled": "El registro está desactivado."
                }
            }
        },
        "forms": {
            "contact": {
                "display_name": "Formulario de contacto"
            }
        },
        "labels": {
            "language": "Idioma",
            "actions": "Acciones",
            "general": "General",
            "no_results": "No hay resultados disponibles",
            "search": "Buscar",
            "validate": "Validar",
            "choose_file": "Seleccione el archivo",
            "no_file_chosen": "Ningún archivo seleccionado",
            "are_you_sure": "Estás seguro ?",
            "delete_image": "Eliminar imagen",
            "yes": "Sí",
            "no": "No",
            "add_new": "Añadir",
            "export": "Exportar",
            "more_info": "Más información",
            "author": "Autor",
            "author_id": "Autor ID",
            "last_access_at": "Último acceso a",
            "published_at": "Publicado en",
            "created_at": "Creado en",
            "updated_at": "Actualizado en",
            "deleted_at": "Eliminado en",
            "no_value": "Sin valor",
            "upload_image": "Cargar imagen",
            "anonymous": "Anónimo",
            "all_rights_reserved": "Todos los derechos reservados.",
            "with": "con",
            "by": "por",
            "datatables": {
                "no_results": "No hay resultados disponibles",
                "no_matched_results": "No hay resultados coincidentes disponibles",
                "show_per_page": "Mostrar",
                "entries_per_page": "entradas por página",
                "search": "Buscar",
                "infos": "Mostrando {offset_start} a {offset_end} de {total} entries"
            },
            "morphs": {
                "post": "Articulo, identidad {id}",
                "user": "Usuario, identidad{id}"
            },
            "auth": {
                "disabled": "Your account has been disabled."
            },
            "http": {
                "403": {
                    "title": "Acceso denegado",
                    "description": "Lo sentimos, pero no tienes permisos para acceder a esta página."
                },
                "404": {
                    "title": "Página no encontrada",
                    "description": "Lo sentimos, pero la página que intentabas ver no existe."
                },
                "500": {
                    "title": "Error del Servidor",
                    "description": "Lo sentimos, pero el servidor ha encontrado una situación que no sabe cómo manejar. Arreglaremos esto lo antes posible."
                }
            },
            "localization": {
                "en": "Inglés",
                "fr": "Francés",
                "es": "Español"
            },
            "placeholders": {
                "route": "Seleccione una ruta interna válida",
                "tags": "Seleccione o cree una etiqueta"
            },
            "cookieconsent": {
                "message": "Este sitio web utiliza cookies para garantizar que obtenga la mejor experiencia en nuestro sitio web.",
                "dismiss": "Estoy de acuerdo !"
            },
            "descriptions": {
                "allowed_image_types": "Tipos permitidos: png gif jpg jpeg."
            },
            "user": {
                "dashboard": "Controles",
                "remember": "Recuerda",
                "login": "Iniciar sesión",
                "logout": "Cerrar sesión",
                "password_forgot": "Contraseña olvidada ?",
                "send_password_link": "Enviar enlace para restablecer contraseña",
                "password_reset": "Restablecimiento de contraseña",
                "register": "Registro",
                "space": "Mi espacio",
                "settings": "Settings",
                "account": "Mi cuenta",
                "profile": "Mi perfil",
                "avatar": "Avatar",
                "online": "En línea",
                "edit_profile": "Editar mi perfil",
                "change_password": "Cambiar mi contraseña",
                "delete": "Borrar mi cuenta",
                "administration": "Administración",
                "member_since": "Miembro desde {date}",
                "profile_updated": "Perfil actualizado con éxito.",
                "password_updated": "Contraseña actualizada con éxito.",
                "super_admin": "Súper administrador",
                "account_delete": "<p>Esta acción eliminará por completo su cuenta de este sitio, así como todos los datos asociados.</p>",
                "account_deleted": "Cuenta eliminada con éxito",
                "titles": {
                    "space": "Mi espacio",
                    "account": "Mi cuenta"
                }
            },
            "alerts": {
                "login_as": "Actualmente as iniciado session como <strong>{name}</strong>, puedes cerrar sesión como <a href=\"{route}\" data-turbolinks=\"false\">{admin}</a>."
            },
            "backend": {
                "dashboard": {
                    "new_posts": "Artículos nuevos",
                    "pending_posts": "Artículos pendientes",
                    "published_posts": "Artículos Publicados",
                    "active_users": "Usuarios activos",
                    "form_submissions": "Solicitudes",
                    "last_posts": "Últimos artículos",
                    "last_published_posts": "Últimos artículos publicados",
                    "last_pending_posts": "Últimos artículos pendientes",
                    "last_new_posts": "Últimos artículos nuevos",
                    "all_posts": "Todos los artículos"
                },
                "new_menu": {
                    "post": "Nuevo articulo",
                    "form_setting": "Nueva configuración de formulario",
                    "user": "Nuevo usuario",
                    "role": "Nuevo rol",
                    "meta": "Nueva meta",
                    "redirection": "Nueva redirección"
                },
                "sidebar": {
                    "content": "Gestión de contenido",
                    "forms": "Gestión de formularios",
                    "access": "Gestión de Acceso",
                    "seo": "Ajustes SEO"
                },
                "titles": {
                    "dashboard": "Controles"
                },
                "users": {
                    "titles": {
                        "main": "Gestión de usuarios",
                        "index": "Lista de usuarios",
                        "create": "Creación de usuario",
                        "edit": "Modificación del usuario"
                    },
                    "actions": {
                        "destroy": "Eliminar usuarios seleccionados",
                        "enable": "Habilitar usuarios seleccionados",
                        "disable": "Desactivar usuarios seleccionados"
                    }
                },
                "roles": {
                    "titles": {
                        "main": "Gestión de roles",
                        "index": "Lista de roles",
                        "create": "Creación de roles",
                        "edit": "Modificación de roles"
                    }
                },
                "metas": {
                    "titles": {
                        "main": "Meta gestión",
                        "index": "Lista Meta",
                        "create": "Creación de Meta",
                        "edit": "Modificación de meta"
                    },
                    "actions": {
                        "destroy": "Eliminar metas seleccionadas"
                    }
                },
                "form_submissions": {
                    "titles": {
                        "main": "Gestión de solicitudes",
                        "index": "Lista de solicitudes",
                        "show": "Detalle de solicitud"
                    },
                    "actions": {
                        "destroy": "Elimiar solicitudes seleccionadas"
                    }
                },
                "form_settings": {
                    "titles": {
                        "main": "Configuración de formulario",
                        "index": "Lista de configuración de formulario",
                        "create": "Creación de configuración de formulario",
                        "edit": "Modificación de configuración de formulario"
                    },
                    "descriptions": {
                        "recipients": "Ejemplo: 'webmaster@example.com' o 'sales@example.com,support@example.com' . Para especificar múltiples destinatarios, separe cada dirección de correo electrónico con una coma.",
                        "message": "El mensaje para mostrar al usuario después del envío de este formulario. Dejar en blanco para ningún mensaje."
                    }
                },
                "redirections": {
                    "titles": {
                        "main": "Gestión de redirección",
                        "index": "Lista de redirección",
                        "create": "Creación de redirección",
                        "edit": "Modificación de redirección"
                    },
                    "actions": {
                        "destroy": "Eliminar las redirecciones seleccionadas",
                        "enable": "Habilitar redirecciones seleccionadas",
                        "disable": "Deshabilitar redirecciones seleccionadas"
                    },
                    "types": {
                        "permanent": "Redirección permanente (301)",
                        "temporary": "Redirección temporal (302)"
                    },
                    "import": {
                        "title": "Importación de archivo CSV",
                        "label": "Seleccionar archivo CSV para importar",
                        "description": "El archivo debe tener 2 columnas con \"source\" y \"target\" como encabezado, la redirección será permanente por defecto"
                    }
                },
                "posts": {
                    "statuses": {
                        "draft": "Borrador",
                        "pending": "Pendiente",
                        "published": "Publicado"
                    },
                    "titles": {
                        "main": "Gestión de artículos",
                        "index": "Lista de artículos",
                        "create": "Crear articulo",
                        "edit": "Editar articulo",
                        "publication": "Opciones de publicación"
                    },
                    "descriptions": {
                        "meta_title": "If leave empty, title will be that of article' title by default.",
                        "meta_description": "If leave empty, description will be that of article's summary by default."
                    },
                    "placeholders": {
                        "body": "Escribe tu contenido...",
                        "meta_title": "Título del articulo.",
                        "meta_description": "Resumen del articulo."
                    },
                    "actions": {
                        "destroy": "Eliminar artículos seleccionados",
                        "publish": "Publicar artículos seleccionados",
                        "pin": "Fijar artículos seleccionados",
                        "promote": "Promover artículos seleccionados"
                    }
                }
            },
            "frontend": {
                "titles": {
                    "home": "Inicio",
                    "about": "Acerca de",
                    "contact": "Contacto",
                    "blog": "Blog",
                    "message_sent": "Mensaje enviado",
                    "legal_mentions": "Menciones legales",
                    "administration": "Administración"
                },
                "submissions": {
                    "message_sent": "<p>Su mensaje ha sido enviado con éxito</p>"
                },
                "placeholders": {
                    "locale": "Selecciona tu idioma",
                    "timezone": "Selecciona tu zona horaria"
                },
                "blog": {
                    "published_at": "Publicado en {date}",
                    "published_at_with_owner_linkable": "Publicado en {date} por <a href=\"{link}\">{name}</a>",
                    "tags": "Etiquetas"
                }
            }
        },
        "logs": {
            "backend": {
                "users": {
                    "created": "Usuario ID {user} creado",
                    "updated": "Usuario ID {user} actualizado",
                    "deleted": "Usuario ID {user} borrado"
                },
                "form_submissions": {
                    "created": "ID de formulario de solicitud {form_submission} creado"
                }
            },
            "frontend": []
        },
        "mails": {
            "layout": {
                "hello": "Hola !",
                "regards": "Saludos",
                "trouble": "Si tiene problemas para hacer clic en botón {action}, copie y pegue la siguiente URL en su navegador web :",
                "all_rights_reserved": "Todos los derechos reservados."
            },
            "password_reset": {
                "subject": "Restablecimiento de contraseña",
                "intro": "Recibió este correo electrónico porque recibimos una solicitud de restablecimiento de contraseña para su cuenta.",
                "action": "Restablecer la contraseña",
                "outro": "Si no solicitó un restablecimiento de contraseña, no se requiere ninguna acción adicional."
            },
            "contact": {
                "subject": "Nuevo mensaje de contacto",
                "new_contact": "Tienes un nuevo mensaje de contacto. Detalle de presentación :"
            },
            "alert": {
                "subject": "[{app_name}] Error de excepción",
                "message": "Tienes un error de excepción del servidor inesperado, que es el mensaje : {message}.",
                "trace": "Todos los detalles de rastreo :"
            }
        },
        "pagination": {
            "previous": "&laquo; Anterior",
            "next": "Siguiente &raquo;"
        },
        "passwords": {
            "password": "Las contraseñas deben coincidir y contener al menos 6 caracteres",
            "reset": "¡Tu contraseña ha sido restablecida!",
            "sent": "¡Te hemos enviado por correo el enlace para restablecer tu contraseña!",
            "token": "El token de recuperación de contraseña es inválido.",
            "user": "No podemos encontrar ningún usuario con ese correo electrónico."
        },
        "permissions": {
            "categories": {
                "blog": "Blog",
                "form": "Formularios",
                "access": "Acceso",
                "seo": "SEO"
            },
            "access": {
                "backend": {
                    "display_name": "Acceso Backoffice",
                    "description": "Puede acceder a las páginas de administración."
                }
            },
            "view": {
                "form_settings": {
                    "display_name": "Ver configuración de formulario",
                    "description": "Puede ver la configuración del formulario."
                },
                "form_submissions": {
                    "display_name": "Ver formularios de solicitud",
                    "description": "Puede ver formularios de solicitud."
                },
                "users": {
                    "display_name": "Ver usuarios",
                    "description": "Puede ver a los usuarios."
                },
                "roles": {
                    "display_name": "Ver roles",
                    "description": "Puede ver roles."
                },
                "metas": {
                    "display_name": "Ver metas",
                    "description": "Puede ver metas"
                },
                "redirections": {
                    "display_name": "Ver redirecciones",
                    "description": "Puede ver las redirecciones."
                },
                "posts": {
                    "display_name": "Ver todos los artículos",
                    "description": "Puede ver todos los artículos."
                },
                "own": {
                    "posts": {
                        "display_name": "Ver artículos propios",
                        "description": "Puede ver artículos propios"
                    }
                }
            },
            "create": {
                "form_settings": {
                    "display_name": "Crear configuraciones de formulario",
                    "description": "Puede crear configuraciones de formulario."
                },
                "users": {
                    "display_name": "Crear usuarios",
                    "description": "Puede crear usuarios."
                },
                "roles": {
                    "display_name": "Crear roles",
                    "description": "Puede crear roles."
                },
                "metas": {
                    "display_name": "Crear metas",
                    "description": "Puede crear metas."
                },
                "redirections": {
                    "display_name": "Crear redirecciones",
                    "description": "Puede crear redirecciones."
                },
                "posts": {
                    "display_name": "Crear publicaciones",
                    "description": "Puede crear todas las publicaciones."
                }
            },
            "edit": {
                "form_settings": {
                    "display_name": "Editar configuración de formulario",
                    "description": "Puede editar la configuración del formulario."
                },
                "users": {
                    "display_name": "Editar usuarios",
                    "description": "Puede editar usuarios."
                },
                "roles": {
                    "display_name": "Editar roles",
                    "description": "Puede editar roles."
                },
                "metas": {
                    "display_name": "Editar metas",
                    "description": "Puede editar metas."
                },
                "redirections": {
                    "display_name": "Editar redirecciones",
                    "description": "Can edit redirections."
                },
                "posts": {
                    "display_name": "Editar todos los artículos",
                    "description": "Puede editar todos los artículos."
                },
                "own": {
                    "posts": {
                        "display_name": "Editar artículos propias",
                        "description": "Puede editar artículos propios."
                    }
                }
            },
            "delete": {
                "form_settings": {
                    "display_name": "Eliminar configuración de formulario",
                    "description": "Puede eliminar la configuración del formulario."
                },
                "form_submissions": {
                    "display_name": "Eliminar formularios de solicitud",
                    "description": "Puede eliminar formularios de solicitud."
                },
                "users": {
                    "display_name": "Eliminar usuarios",
                    "description": "Puede eliminar usuarios"
                },
                "roles": {
                    "display_name": "Eliminar roles",
                    "description": "Puede eliminar roles"
                },
                "metas": {
                    "display_name": "Eliminar metas",
                    "description": "Puede eliminar metas"
                },
                "redirections": {
                    "display_name": "Eliminar redirecciones",
                    "description": "Puede eliminar redirecciones."
                },
                "posts": {
                    "display_name": "Eliminar todos los artículos",
                    "description": "Puede eliminar todos los artículos."
                },
                "own": {
                    "posts": {
                        "display_name": "Eliminar artículos propios",
                        "description": "Puede eliminar artículos propios"
                    }
                }
            },
            "publish": {
                "posts": {
                    "display_name": "Publicar artículos",
                    "description": "Puede gestionar la publicación de artículos."
                }
            },
            "impersonate": {
                "display_name": "Suplantar usuario",
                "description": "Puede tomar posesión de otras identidades de usuario. Útil para las pruebas."
            }
        },
        "routes": {
            "home": "inicio",
            "about": "acerca",
            "contact": "contacto",
            "contact-sent": "envio-contacto",
            "legal-mentions": "menciones-legales",
            "redactors": "blog/redactores/{user}"
        },
        "validation": {
            "accepted": "{attribute} debe ser aceptado.",
            "active_url": "{attribute} no es una URL válida.",
            "after": "{attribute} debe ser una fecha posterior a {date}.",
            "after_or_equal": "{attribute} debe ser una fecha posterior o igual a {date}.",
            "alpha": "{attribute} sólo debe contener letras.",
            "alpha_dash": "{attribute} sólo debe contener letras, números y guiones.",
            "alpha_num": "{attribute} sólo debe contener letras y números.",
            "array": "{attribute} debe ser un conjunto.",
            "before": "{attribute} debe ser una fecha anterior a {date}.",
            "before_or_equal": "{attribute} debe ser una fecha anterior o igual a {date}.",
            "between": {
                "numeric": "{attribute} tiene que estar entre {min} - {max}.",
                "file": "{attribute} debe pesar entre {min} - {max} kilobytes.",
                "string": "{attribute} tiene que tener entre {min} - {max} caracteres.",
                "array": "{attribute} tiene que tener entre {min} - {max} ítems."
            },
            "boolean": "El campo {attribute} debe tener un valor verdadero o falso.",
            "confirmed": "La confirmación de {attribute} no coincide.",
            "date": "{attribute} no es una fecha válida.",
            "date_format": "{attribute} no corresponde al formato {format}.",
            "different": "{attribute} y {other} deben ser diferentes.",
            "digits": "{attribute} debe tener {digits} dígitos.",
            "digits_between": "{attribute} debe tener entre {min} y {max} dígitos.",
            "dimensions": "Las dimensiones de la imagen {attribute} no son válidas.",
            "distinct": "El campo {attribute} contiene un valor duplicado.",
            "email": "{attribute} no es un correo válido",
            "exists": "{attribute} es inválido.",
            "file": "El campo {attribute} debe ser un archivo.",
            "filled": "El campo {attribute} es obligatorio.",
            "image": "{attribute} debe ser una imagen.",
            "in": "{attribute} es inválido.",
            "in_array": "El campo {attribute} no existe en {other}.",
            "integer": "{attribute} debe ser un número entero.",
            "ip": "{attribute} debe ser una dirección IP válida.",
            "ipv4": "{attribute} debe ser un dirección IPv4 válida",
            "ipv6": "{attribute} debe ser un dirección IPv6 válida.",
            "json": "El campo {attribute} debe tener una cadena JSON válida.",
            "max": {
                "numeric": "{attribute} no debe ser mayor a {max}.",
                "file": "{attribute} no debe ser mayor que {max} kilobytes.",
                "string": "{attribute} no debe ser mayor que {max} caracteres.",
                "array": "{attribute} no debe tener más de {max} elementos."
            },
            "mimes": "{attribute} debe ser un archivo con formato: {values}.",
            "mimetypes": "{attribute} debe ser un archivo con formato: {values}.",
            "min": {
                "numeric": "El tamaño de {attribute} debe ser de al menos {min}.",
                "file": "El tamaño de {attribute} debe ser de al menos {min} kilobytes.",
                "string": "{attribute} debe contener al menos {min} caracteres.",
                "array": "{attribute} debe tener al menos {min} elementos."
            },
            "not_in": "{attribute} es inválido.",
            "not_regex": "El formato del campo {attribute} no es válido.",
            "numeric": "{attribute} debe ser numérico.",
            "present": "El campo {attribute} debe estar presente.",
            "regex": "El formato de {attribute} es inválido.",
            "required": "El campo {attribute} es obligatorio.",
            "required_if": "El campo {attribute} es obligatorio cuando {other} es {value}.",
            "required_unless": "El campo {attribute} es obligatorio a menos que {other} esté en {values}.",
            "required_with": "El campo {attribute} es obligatorio cuando {values} está presente.",
            "required_with_all": "El campo {attribute} es obligatorio cuando {values} está presente.",
            "required_without": "El campo {attribute} es obligatorio cuando {values} no está presente.",
            "required_without_all": "El campo {attribute} es obligatorio cuando ninguno de {values} estén presentes.",
            "same": "{attribute} y {other} deben coincidir.",
            "size": {
                "numeric": "El tamaño de {attribute} debe ser {size}.",
                "file": "El tamaño de {attribute} debe ser {size} kilobytes.",
                "string": "{attribute} debe contener {size} caracteres.",
                "array": "{attribute} debe contener {size} elementos."
            },
            "string": "El campo {attribute} debe ser una cadena de caracteres.",
            "timezone": "El {attribute} debe ser una zona válida.",
            "unique": "{attribute} ya ha sido registrado.",
            "uploaded": "Subir {attribute} ha fallado.",
            "url": "El formato {attribute} es inválido.",
            "custom": {
                "attribute-name": {
                    "rule-name": "mensaje personalizado"
                }
            },
            "attributes": {
                "name": "Nombre",
                "display_name": "Nombre para mostrar",
                "username": "Seudo",
                "email": "Email",
                "first_name": "Nombre",
                "last_name": "Apellidos",
                "password": "Contraseña",
                "password_confirmation": "Confirmar contraseña",
                "old_password": "Contraseña anterior",
                "new_password": "Nueva contraseña",
                "new_password_confirmation": "Confirmar nueva contraseña",
                "postal_code": "código postal",
                "city": "Ciudad",
                "country": "País",
                "address": "Dirección",
                "phone": "Teléfono",
                "mobile": "Móvil",
                "age": "Años",
                "sex": "Sexo",
                "gender": "Género",
                "day": "Día",
                "month": "Mes",
                "year": "Año",
                "hour": "Hora",
                "minute": "Minuto",
                "second": "Segundo",
                "title": "Título",
                "content": "Contenido",
                "description": "Descripción",
                "summary": "Resumen",
                "excerpt": "Extracto",
                "date": "Fecha",
                "time": "Hora",
                "available": "Disponible",
                "size": "Tamaño",
                "roles": "Roles",
                "permissions": "Permisos",
                "active": "Activo",
                "message": "Mensaje",
                "g-recaptcha-response": "Captcha",
                "locale": "Localización",
                "route": "Ruta",
                "url": "URL alias",
                "form_type": "Tipo de formulario",
                "form_data": "Datos de formulario",
                "recipients": "Destinatarios",
                "source_path": "Ruta original",
                "target_path": "Ruta de destino",
                "redirect_type": "Redirigir tipo",
                "timezone": "Zona horaria",
                "order": "Orden de visualización",
                "image": "Imagen",
                "status": "Estado",
                "pinned": "Fijado",
                "promoted": "Promovido",
                "body": "Cuerpo",
                "tags": "Etiquetas",
                "published_at": "Publicar en",
                "unpublished_at": "Anular publicación en",
                "metable_type": "Entidad"
            }
        }
    },
    "fr": {
        "routes": {
            "$routeName": "",
            "about": "qui-sommes-nous",
            "agency-inside": "agency-inside",
            "community-connect": "community-connect",
            "available-services": "services-disponibles",
            "contact": "demande-de-contact",
            "contact-sent": "message-envoyee",
            "home": "accueil",
            "legal-mentions": "mentions-legales",
            "redactors": "blog/redacteurs/{user}",
            "service-inside": "service-inside",
            "get-agencies": "get-agencies"
        },
        "alerts": {
            "backend": {
                "action_plans": {
                    "created": "Plan d'action créé",
                    "updated": "Plan d'action mis à jour"
                },
                "actions": {
                    "invalid": "Action invalide"
                },
                "agencies": {
                    "created": "Agence créée",
                    "deleted": "Agence supprimée",
                    "registered": "Agence enregistrée",
                    "saved": "Agence enregistrée",
                    "submit_intake_form": "Veuillez d'abord soumettre le formulaire d'admission",
                    "updated": "Agence mise à jour",
                    "agency_updated": "Agence mise à jour avec succès!"
                },
                "appointments": {
                    "created": "Établie",
                    "updated": "Mis à jour"
                },
                "assessment": {
                    "saved": "Évaluation enregistrée"
                },
                "client_agency_service_categories": {
                    "added": "Catégorie de service ajoutée"
                },
                "client_send_email_contact_us_request": {
                    "sent": "Email envoyé"
                },
                "course_categories": {
                    "created": "Catégorie créée",
                    "deleted": "Catégorie supprimée",
                    "updated": "Catégorie mise à jour"
                },
                "courses": {
                    "created": "Cours créé",
                    "deleted": "Cours supprimé",
                    "updated": "Cours mis à jour"
                },
                "documents": {
                    "deleted": "Document supprimé",
                    "updated": "Document mis à jour",
                    "uploaded": "Document téléchargé avec succès!"
                },
                "feedback": {
                    "created": "Merci, votre commentaire a été soumis."
                },
                "form_settings": {
                    "created": "Paramétrage de formulaire créé",
                    "deleted": "Paramétrage de formulaire supprimé",
                    "updated": "Paramétrage de formulaire mis à jour"
                },
                "form_submissions": {
                    "bulk_destroyed": "Soumissions sélectionnées supprimées",
                    "deleted": "Soumission supprimée"
                },
                "front_end_contact_us_send_email": {
                    "sent": "Email envoyé"
                },
                "intake_forms": {
                    "created": "Formulaire d'admission créé",
                    "deleted": "Formulaire d'admission supprimé",
                    "saved": "Formulaire d'admission enregistré",
                    "updated": "Formulaire d'admission mis à jour"
                },
                "metas": {
                    "bulk_destroyed": "Metas sélectionnées supprimées",
                    "created": "Meta créée",
                    "deleted": "Meta supprimée",
                    "updated": "Meta mise à jour"
                },
                "posts": {
                    "bulk_destroyed": "Articles sélectionnés supprimés",
                    "bulk_pending": "Articles sélectionnés en attente de modération",
                    "bulk_pinned": "Articles sélectionnés épinglé",
                    "bulk_promoted": "Articles sélectionnés mis en avant",
                    "bulk_published": "Articles sélectionnés publiés",
                    "created": "Article créé",
                    "deleted": "Article supprimé",
                    "updated": "Article mis à jour"
                },
                "redirections": {
                    "bulk_destroyed": "Redirections sélectionnées supprimées",
                    "bulk_disabled": "Redirections sélectionnées désactivées",
                    "bulk_enabled": "Redirections sélectionnées activées",
                    "created": "Redirection créée",
                    "deleted": "Redirection supprimée",
                    "file_imported": "Fichier importé avec succès",
                    "updated": "Redirection mise à jour"
                },
                "roles": {
                    "created": "Rôle créé",
                    "deleted": "Rôle supprimé",
                    "updated": "Rôle mis à jour"
                },
                "users": {
                    "bulk_destroyed": "Utilisateurs sélectionnés supprimés",
                    "bulk_disabled": "Utilisateurs sélectionnés désactivés",
                    "bulk_enabled": "Utilisateurs sélectionnés activés",
                    "clients_assigned": "Les clients sélectionnés ont été affectés au travailleur.",
                    "created": "Utilisateur créé",
                    "deactivated": "L'utilisateur a été désactivé",
                    "deleted": "Utilisateur supprimé",
                    "intake_functionality_added": "Ajout de la fonctionnalité d'admission",
                    "intake_functionality_removed": "Fonctionnalité d'admission supprimée",
                    "member_invited": "Invitation envoyée par e-mail.",
                    "updated": "Utilisateur mis à jour"
                },
                "narrations": {
                    "added": "Narration ajoutée avec succès!"
                },
                "service_category": {
                    "added": "Service ajouté au profil client avec succès."
                },
                "community_connect": {
                    "updated": "Les informations de la page ont été mises à jour avec succès!"
                }
            },
            "frontend": []
        },
        "auth": {
            "failed": "Ces identifiants ne correspondent pas à nos enregistrements",
            "socialite": {
                "unacceptable": "{provider} n'est pas accepté."
            },
            "throttle": "Trop de tentatives de connexion. Veuillez essayer de nouveau dans {seconds} secondes."
        },
        "buttons": {
            "add_narration": "Ajouter une narration",
            "apply": "Appliquer",
            "assign": "Attribuer",
            "back": "Retour",
            "cancel": "Annuler",
            "close": "Fermer",
            "confirm": "Valider",
            "contact_support": "Contactez le support",
            "contact_us": "Contactez le supportv",
            "create": "Créer",
            "create_account": "Créer un compte",
            "deactivate": "Désactiver",
            "delete": "Supprimer",
            "documents": {
                "upload_new_doc": "Télécharger un nouveau document"
            },
            "edit": "Editer",
            "feedback": {
                "leave_comment": "laissez un commentaire"
            },
            "form_settings": {
                "create": "Créer un paramètre"
            },
            "go_website": "Aller sur un site Internet",
            "login-as": "Se loguer en tant que {name}",
            "make_appointment": "Prendre un rendez-vous",
            "mange_categories": "Gérer les catégories",
            "metas": {
                "create": "Créer une meta"
            },
            "next": "Prochaine",
            "other_worker": "Autre travailleur",
            "posts": {
                "create": "Créer un article",
                "save_and_publish": "Enregistrer et publier",
                "save_as_draft": "Enregistrer en tant que brouillon"
            },
            "preview": "Prévisualiser",
            "red_more": "Lire la suite",
            "redirections": {
                "create": "Créer une redirection",
                "import": "Importer le CSV"
            },
            "register": "S'inscrire",
            "reply": "Réponse",
            "request_an_appointment": "Prenez rendez-vous",
            "roles": {
                "create": "Créer un rôle"
            },
            "save": "Sauvegarder",
            "send": "Envoyer",
            "send_message": "Envoyer le message",
            "send_pass_reset": "Envoyer la réinitialisation du mot de passe",
            "send_pass_reset_link_to_client": "Envoyer le lien de réinitialisation du mot de passe au client",
            "send_request": "Envoyer une demande",
            "services": "Prestations de service",
            "show": "Voir",
            "sign_in": "Se connecter",
            "sign_up": "S'inscrire",
            "skip": "Sauter",
            "submit": "Soumettre",
            "update": "Mettre à jour",
            "upload_files": "Télécharger des fichiers",
            "users": {
                "create": "Créer un utilisateur"
            },
            "view": "Voir",
            "view_all": "Voir tout",
            "view_less": "Voir moins",
            "view_profile": "Voir le profil",
            "visit_now": "Visitez maintenant",
            "visit_website": "Visitez le site Web",
            "email_recovery_link": "Envoyez-moi un lien de réinitialisation de mot de passe par courriel",
            "view_services": "Voir services",
            "view_courses": "Voir les cours",
            "print": "Imprimer",
            "change_password": "Changer le mot de passe",
            "view_intake_forms": "Voir les formulaires d'admission",
            "ok": "d'accord",
            "export": "Exporter",
            "export_db": "Exporter la base de données",
            "edit_page_content": "Modifier le contenu de la page",
            "register_now": "S'inscrire maintenant",
            "coming_soon": "À venir"
        },
        "exceptions": {
            "backend": {
                "agencies": {
                    "create": "Erreur lors de la création de l'agence",
                    "delete": "Erreur lors de la suppression de l'agence",
                    "register": "Erreur lors de l'inscription de l'agence",
                    "save": "Erreur lors de l'enregistrement de l'agence",
                    "update": "Erreur lors de la mise à jour de l'agence"
                },
                "appointments": {
                    "create": "Erreur lors de la création du rendez-vous",
                    "delete": "Erreur lors de la suppression du rendez-vous",
                    "no_worker": "Vous n'avez pas encore affecté de travailleur",
                    "not_available": "Temps non disponible",
                    "save": "Erreur lors de l'enregistrement du rendez-vous",
                    "update": "Erreur lors de la mise à jour du rendez-vous"
                },
                "assessments": {
                    "create": "Erreur lors de la création de la création",
                    "delete": "Erreur lors de la création de la suppression",
                    "save": "Erreur lors de la création de l'enregistrement",
                    "update": "Erreur lors de la création de la mise à jour"
                },
                "case_narrations": {
                    "create": "Erreur lors de la création du rendez-vous",
                    "delete": "Erreur lors de la suppression du rendez-vous",
                    "save": "Erreur lors de l'enregistrement du rendez-vous",
                    "update": "Erreur lors de la mise à jour du rendez-vous"
                },
                "course_categories": {
                    "already_exist": "La catégorie existe déjà",
                    "create": "Erreur lors de la création de la catégorie",
                    "delete": "Erreur lors de la suppression de la catégorie",
                    "update": "Erreur lors de la mise à jour de la catégorie"
                },
                "courses": {
                    "create": "Error on course creation",
                    "delete": "Error on course deletion",
                    "save": "Error on course saving",
                    "update": "Error on course updating"
                },
                "document": {
                    "upload": ""
                },
                "documents": {
                    "delete": "Erreur lors de la suppression du document",
                    "upload": "Erreur lors du téléchargement du document"
                },
                "feedback": {
                    "create": "Erreur lors de la création des commentaires",
                    "delete": "Erreur lors de la suppression des commentaires",
                    "save": "Erreur lors de l'enregistrement des commentaires",
                    "update": "Erreur lors de la mise à jour des commentaires"
                },
                "form_settings": {
                    "already_exist": "Il existe déjà un paramétrage pour ce formulaire",
                    "create": "Erreur lors de la création du paramètre de formulaire",
                    "delete": "Erreur lors de la suppression du paramètre de formulaire",
                    "update": "Erreur lors de la mise à jour du paramètre de formulaire"
                },
                "form_submissions": {
                    "create": "Erreur lors de la création de la soumission",
                    "delete": "Erreur lors de la suppression de la soumission"
                },
                "intake_forms": {
                    "create": "Erreur lors de la création du formulaire d'admission",
                    "delete": "Erreur lors de la suppression du formulaire d'admission",
                    "save": "Erreur lors de l'enregistrement du formulaire d'admission",
                    "update": "Erreur lors de la mise à jour du formulaire d'admission"
                },
                "metas": {
                    "already_exist": "Il existe déjà une meta pour cette route",
                    "create": "Erreur lors de la création de la meta",
                    "delete": "Erreur lors de la suppression de la meta",
                    "update": "Erreur lors de la mise à jour de la meta"
                },
                "posts": {
                    "create": "Erreur lors de la création de l'article",
                    "delete": "Erreur lors de la suppression de l'article",
                    "save": "Erreur lors de l'enregistrement de l'article",
                    "update": "Erreur lors de la mise à jour de l'article"
                },
                "redirections": {
                    "already_exist": "Il existe déjà une redirection pour ce chemin",
                    "create": "Erreur lors de la création de la redirection",
                    "delete": "Erreur lors de la suppression de la redirection",
                    "update": "Erreur lors de la mise à jour de la redirection"
                },
                "roles": {
                    "create": "Erreur lors de la création du rôle",
                    "delete": "Erreur lors de la suppression du rôle",
                    "update": "Erreur lors de la mise à jour du rôle"
                },
                "users": {
                    "cannot_set_superior_roles": "Vous ne pouvez pas attribuer de rôle supérieur au vôtre",
                    "create": "Erreur lors de la création de l'utilisateur",
                    "delete": "Erreur lors de la suppression de l'utilisateur",
                    "first_user_cannot_be_destroyed": "L'utilisateur super admin ne peut pas être supprimé",
                    "first_user_cannot_be_disabled": "L'utilisateur super admin ne peut pas être désactivé",
                    "first_user_cannot_be_edited": "Vous ne pouvez pas éditer l'utilisateur super admin",
                    "first_user_cannot_be_impersonated": "L'utilisateur super admin ne peut pas être usurpé",
                    "update": "Erreur lors de la mise à jour de l'utilisateur"
                }
            },
            "frontend": {
                "auth": {
                    "registration_disabled": "L'enregistrement d'utilisateurs est désactivé."
                },
                "user": {
                    "delete_account": "Erreur lors de la suppression de votre compte.",
                    "email_taken": "Cet email est déjà utilisé par un compte existant.",
                    "password_mismatch": "L'ancien mot de passe est incorrect.",
                    "updating_disabled": "La modification de compte est désactivée."
                }
            },
            "general": "Erreur serveur",
            "unauthorized": "Action non autorisée"
        },
        "forms": {
            "client_intake_form": {
                "upload_title": "Téléchargez des documents d'une taille maximale de 30 Mo."
            },
            "client_intake_form_submit": {
                "display_name": "Soumettre le formulaire d'admission des clients"
            },
            "contact": {
                "display_name": "Formulaire de contact"
            },
            "documents": {
                "upload_title": "Téléchargez des documents d'une taille maximale de 30 Mo."
            }
        },
        "labels": {
            "reason_for_referral": "Motif du renvoi",
            "refer": "Référer",
            "person_selected_press_refer_to_confirm": "Vous avez sélectionné une personne. Veuillez appuyer sur le bouton \"Consulter\" pour\r\n    confirmer cette action",
            "select_workers": "Sélectionnez les travailleurs",
            "internal_referral": "Référence interne",
            "actions": "Actions",
            "add_new": "Ajouter",
            "add_tag": "Ajouter une étiquette",
            "chat_log": "Journal de chat",
            "view_chat_log": "Afficher le journal de discussion",
            "loading_chat_history": "Chargement de l'historique des discussions",
            "unable_to_load_chat_history": "Impossible de charger l'historique des discussions",
            "alerts": {
                "login_as": "Vous êtes actuellement connecté en tant que <strong>{name}</strong>, vous pouvez à tout moment vous reconnecter en tant que <a href=\"{route}\" data-turbolinks=\"false\">{admin}</a>."
            },
            "all": "Toute!!",
            "all_rights_reserved": "Tous droits réservés!!",
            "anonymous": "Anonyme",
            "are_you_sure": "Etes-vous sûr ?",
            "auth": {
                "disabled": "Votre compte a été désactivé."
            },
            "author": "Auteur",
            "author_id": "ID Auteur",
            "available_agencies": "Agences disponibles",
            "available_lang": "Langues servies",
            "backend": {
                "action_plans": {
                    "identified_need": "Besoin identifié",
                    "priority_levels": {
                        "high": "Haute",
                        "low": "Faible",
                        "medium": "Moyen"
                    },
                    "required_action": "Action requise",
                    "statuses": {
                        "completed": "Terminé",
                        "in_progress": "En cours",
                        "referred": "Référé"
                    },
                    "types": {
                        "lasi_agency": "Agence LASI",
                        "lms_course": "Cours LMS",
                        "service_provider": "Fournisseur de services",
                        "services": "Prestations de service"
                    }
                },
                "appointments": {
                    "labels": {
                        "schedule_meeting": "Planifier une réunion",
                        "type_of_appointment": "Type de rendez-vous",
                        "appointment_buffer": "Heure du rendez-vous"
                    },
                    "placeholders": {
                        "agency": "Agence",
                        "notes": "Remarques",
                        "reason": "Raison",
                        "select_client": "Sélectionnez un client",
                        "select_date": "Sélectionner une date",
                        "select_duration": "Sélectionnez la durée",
                        "select_time": "Sélectionnez l'heure"
                    },
                    "statuses": {
                        "canceled": "Annulé",
                        "completed": "Terminé",
                        "confirmed": "Confirmé",
                        "not_completed": "Pas achevé",
                        "scheduled": "Programmé"
                    },
                    "titles": {
                        "appointment_Request": "Demande de rendez-vous",
                        "no_appointments": "Il n'y a pas de rendez-vous à venir"
                    },
                    "types": {
                        "in_person": "En personne",
                        "online": "En ligne"
                    },
                    "messages": {
                        "no_agency_assigned": "Veuillez d'abord vous inscrire auprès d'une Agence!"
                    }
                },
                "courses": {
                    "create_new": "Créer un nouveau cours",
                    "create": "Créer un cours",
                    "create_course_confirm": "Souhaitez-vous créer un nouveau cours sur LearnWorlds?",
                    "account_settings": "Paramètres du compte",
                    "account_settings_message": "Veuillez contacter LASI Webmaster pour vous donner des privilèges sur la plateforme LearnWorlds",
                    "view_course": "Voir le cours",
                    "manager_view_confirm1": "Souhaitez-vous voir ",
                    "manager_view_confirm2": " au LearnWorlds?",
                    "manage_course": "Gérer le cours",
                    "profile_info": "Informations sur le profil du centre d'apprentissage",
                    "courses": "Cours",
                    "hours": "Heures",
                    "achievements": "Réalisations",
                    "certificates": "Certificats",
                    "progress_not_started": "Pas commencé",
                    "progress_in_progress": "En cours",
                    "progress_completed": "Complété",
                    "course_completed": "Cours terminé!",
                    "course_completed_message": "Ce cours est déjà terminé!",
                    "resume_course": "Reprendre le cours",
                    "resume_course_message": "Souhaitez-vous reprendre ce cours:",
                    "resume": "Reprendre",
                    "start_course": "Commencer le cours",
                    "start_course_message": "Souhaitez-vous commencer ce cours:",
                    "start": "Démarrer",
                    "cancel": "Annuler",
                    "registered_users": "Utilisateurs",
                    "add_user": "Ajouter un utilisateur",
                    "add_user_modal_title": "Ajouter un utilisateur LASI existant au cours LearnWorlds",
                    "add_user_modal_subheading": "Rechercher et inscrire un utilisateur existant à ce cours",
                    "add_user_modal_success": "L'utilisateur a été ajouté avec succès à ce cours!",
                    "selected_client": "Client sélectionné",
                    "add_user_modal_not_found": "Le client sélectionné n'a pas de compte LearnWorlds!",
                    "add_user_modal_create_account": "Souhaitez-vous créer un nouveau compte pour cet utilisateur dans LearnWorlds?",
                    "add_user_modal_exists": "Cet utilisateur est déjà ajouté à ce cours!",
                    "enroll_user": "Inscrire l'utilisateur",
                    "create_user": "Créer un utilisateur",
                    "ok": "D'accord",
                    "client_search_text": "Recherche par nom...",
                    "client_search_select": "Sélectionner",
                    "download_certificate": "Télécharger le Certificat",
                    "all": "Toute",
                    "my_organization": "Mon Organisation",
                    "no_courses": "Aucun cours n'a encore commencé!",
                    "client_progress": "Progrès du client",
                    "my_courses": "Mes cours",
                    "my_clients": "Mes clients",
                    "track_clients_progress": "Suivre les progrès des clients"
                },
                "dashboard": {
                    "active_users": "Utilisateurs actifs",
                    "all_posts": "Voir tous les articles",
                    "form_submissions": "Soumissions",
                    "last_new_posts": "Derniers nouveaux articles",
                    "last_pending_posts": "Derniers articles en attente de publication",
                    "last_posts": "Dernier articles",
                    "last_published_posts": "Dernières publications",
                    "new_posts": "Nouveaux articles",
                    "pending_posts": "Articles en attente de publication",
                    "published_posts": "Articles publiés",
                    "dashboard_description": "C'est ici que vous pouvez voir les rendez-vous à venir, vos références, vos vidéos d'apprentissage et les activités en attente.",
                    "request_meeting": "Demander une réunion",
                    "complete": "Compléter",
                    "upcoming_appointments": "Rendez-vous à venir",
                    "unconfirmed_appointments": "Rendez-vous non confirmés",
                    "activity_status": "Statut de l'activité",
                    "view_all": "Voir tout",
                    "start_video_chat": "Démarrer le chat vidéo",
                    "date_time": "Date/Heure",
                    "worker": "Ouvrière",
                    "agency": "Agence",
                    "type": "Taper",
                    "service_category": "Catégorie de service",
                    "notes": "Remarques",
                    "status": "Statut",
                    "clients_total": "Total de clients #",
                    "available_service_categories": "Catégories de services disponibles",
                    "intake_forms_review": "Formulaires d'admission à revoir",
                    "total_no_appointments": "Nombre total de rendez-vous",
                    "accepted": "Accepté",
                    "pending": "En attente",
                    "cancelled": "Annulé",
                    "client": "Cliente",
                    "confirm": "Confirm",
                    "completed": "Complété",
                    "edit": "Éditer",
                    "not_completed": "Pas achevé",
                    "cancel": "Annuler",
                    "last_7_days": "Les 7 derniers jours",
                    "last_30_days": "Les 30 derniers jours",
                    "last_year": "L'année dernière",
                    "list_clients": "Liste des clients",
                    "name": "Nom",
                    "action_plan_progress": "Progrès du plan d'action",
                    "request_appointment": "Demander un rendez-vous",
                    "my_appointments": "Mes rendez-vous",
                    "active": "Active",
                    "inactive": "Inactive",
                    "intake_functionality": "Fonctionnalité d'admission:",
                    "invite_new_member": "Inviter un nouveau membre",
                    "send_invitation": "Envoyer une invitation",
                    "top_requested_services": "Services les plus demandés",
                    "number_of_clients": "Nombre de clients",
                    "responsible_persons": "Les personnes responsables",
                    "total_no": "Le total #",
                    "awaiting": "En attente",
                    "declined": "Diminué",
                    "assigned_worker": "Travailleur affecté",
                    "complaints": "Plaintes",
                    "list_workers": "Liste des travailleurs",
                    "assigned_clients": "Clients assignés",
                    "received_intake_forms": "Formulaires d'admission reçus",
                    "summary_reports": "Rapports de synthèse",
                    "remove": "Supprimer",
                    "workers_total": "Total de travailleurs #",
                    "managers_total": "Total de gestionnaires #",
                    "manage_accounts": "Gérer les comptes",
                    "reassign": "Réaffecter",
                    "activate": "Activer",
                    "suspend": "Suspendre",
                    "delete": "Supprimer",
                    "type_account": "Type de compte",
                    "registration_id": "ID d'enregistrement",
                    "suspended": "Suspendue",
                    "deleted": "Supprimé",
                    "date_of_creation": "Date de création",
                    "agencies": "Agences",
                    "summary": "Résumé",
                    "edit_agency": "Modifier l'agence",
                    "body": "Corps",
                    "website_url": "URL de site web",
                    "service_categories": "Catégories de services",
                    "surveys": "Enquêtes",
                    "surveys_management": "Gestion des sondages",
                    "reassign_role": "Réaffecter le rôle",
                    "community_connect": "Connexion communautaire",
                    "reassign_agency": "Réaffecter l'agence"
                },
                "surveys": {
                    "create": "Créer un sondage"
                },
                "documents": {
                    "table_fields": {
                        "size": "Taille",
                        "title": "Titre",
                        "date_time": "Date et heure"
                    },
                    "titles": {
                        "my_dock": "Mes documents",
                        "page_title": "Téléchargements de documents"
                    }
                },
                "feedback": {
                    "counter_data": {
                        "avg": "Moy. évaluation",
                        "not_replied": "Pas répondu",
                        "total": "Totale"
                    },
                    "table_fields": {
                        "feedback": "Retour d'information",
                        "name": "Nom",
                        "placed": "Mis",
                        "rating": "Évaluation"
                    },
                    "titles": {
                        "clients_satisfaction_rate": "Taux de satisfaction des clients",
                        "comments": "commentaires",
                        "leave_reply": "Laisser une réponse",
                        "new_comment": "Laisser un commentaire ou une plainte",
                        "page_title": "Retour d'information",
                        "reply_to": "Répondre à",
                        "satisfaction_rate": "Votre taux de satisfaction"
                    },
                    "types": {
                        "complaint": "Plainte",
                        "feedback": "Retour d'information"
                    }
                },
                "form_settings": {
                    "descriptions": {
                        "message": "Le message à afficher après la soumission de ce formulaire. Laissez vide pour n'afficher aucun message.",
                        "recipients": "Exemple: 'webmaster@example.com' or 'sales@example.com,support@example.com' . Pour déclarer des destinataires multiples, séparer chaque adresse par une virgule."
                    },
                    "titles": {
                        "create": "Création d'un paramètre de formulaire",
                        "edit": "Edition d'un paramètre de formulaire",
                        "index": "Liste des paramètres de formulaire",
                        "main": "Formulaires"
                    }
                },
                "form_submissions": {
                    "actions": {
                        "destroy": "Supprimer les soumissions sélectionnées"
                    },
                    "titles": {
                        "index": "Liste des soumissions",
                        "main": "Soumissions",
                        "show": "Détail de la soumission"
                    }
                },
                "intake_forms": {
                    "labels": {
                        "applicant_details": "Détails du candidat",
                        "assign_worker": "Attribuer un travailleur",
                        "assigned_clients": "Clients affectés",
                        "assigned_worker": "Travailleur affecté",
                        "intake_form": "Formulaire d'admission",
                        "received": "Reçue",
                        "review": "Passer en revue",
                        "print": "Imprimer",
                        "received_date": "Date de réception"
                    },
                    "options": {
                        "gender": {
                            "female": "Femme",
                            "male": "Mâle"
                        },
                        "immigration_citizen_intends": [
                            "Non",
                            "Oui"
                        ],
                        "immigration_classes": [
                            "Classe Affaires (Investisseur, Entrepreneur fédéral, Start-up, Via, Indépendant)",
                            "Classe d'expérience canadienne",
                            "Classe de réfugiés: Réfugié parrainé mixte",
                            "Catégorie de réfugié: réfugié assisté par le gouvernement",
                            "Classe de réfugiés: parrainé par le secteur privé",
                            "Catégorie de réfugié: Section d'appel des réfugiés (SAR)",
                            "Catégorie de réfugié: demandeur d'asile",
                            "Catégorie de réfugié: demandeur d'asile refusé",
                            "Classe de réfugié: Autre",
                            "Parcours du soignant",
                            "Dépendante",
                            "Permis d'études",
                            "Parrainage familial",
                            "Permis de séjour temporaire (anciennement connu sous le nom de permis du ministre)",
                            "Parents assistés d'immigrant indépendant",
                            "Autre immigrant indépendant",
                            "Visiteur (Touriste, Visiteur d'affaires, SuperVisa pour parents et grands-parents)",
                            "Permis de travail (gens d'affaires, professionnels, cessionnaires intra-entreprise, commerçants et investisseurs)",
                            "Inconnue",
                            "Programme fédéral des métiers spécialisés",
                            "Programme des travailleurs qualifiés (fédéral)",
                            "Demande humanitaire / compassion en cours",
                            "Motifs humanitaires / de compassion",
                            "Aide familial résidant",
                            "Candidat provincial",
                            "Programme des travailleurs qualifiés sélectionnés du Québec"
                        ],
                        "immigration_id_types": [
                            "Carte de résidence permanente",
                            "Document de débarquement",
                            "Autre",
                            "Carte de citoyenneté",
                            "Certificat de citoyenneté",
                            "Avoir l'intention d'atterrir",
                            "Permis de travail du Programme des aides familiaux résidants",
                            "Avis de décision",
                            "Document du demandeur d'asile",
                            "Document du demandeur d'asile et avis de décision",
                            "Permis de travail"
                        ],
                        "immigration_statuses": [
                            "Citoyen canadien",
                            "Citoyen par naissance",
                            "Évaluation des risques avant le retrait",
                            "Personne protégée",
                            "Candidat provincial en attente de l'approbation de CIC pour le RP",
                            "Appel des réfugiés",
                            "Demandeur d'asile",
                            "Demandeur du statut de réfugié refusé",
                            "Parrainage de conjoint ou d'enfants",
                            "Étudiante",
                            "Travailleur étranger temporaire",
                            "Travailleur de permis de séjour temporaire",
                            "Citoyen (autochtone)",
                            "Inconnue",
                            "Visiteuse",
                            "Permis de travail (post-diplôme)",
                            "Réfugié conventionnel",
                            "Demande humanitaire / compassion en cours",
                            "Une revue judiciaire",
                            "Aide familial résidant",
                            "Pas de statut",
                            "Demande de résidence permanente en cours",
                            "Résidence permanente"
                        ],
                        "languages": {
                            "english": "Anglaise",
                            "french": "français"
                        },
                        "marital_statuses": [
                            "Célibataire",
                            "Mariée",
                            "Séparée",
                            "Divorcée",
                            "Veuve",
                            "Conjoint de fait"
                        ]
                    },
                    "statuses": {
                        "approved": "Approuvée",
                        "created": "Établie",
                        "rejected": "Rejetée",
                        "saved": "Enregistrée",
                        "submitted": "Soumise",
                        "pending": "En attente",
                        "all": "Toute"
                    },
                    "titles": {
                        "accept": "Accepter le formulaire d'admission",
                        "create": "Créer un formulaire d'admission",
                        "edit": "Modifier le formulaire d'admission",
                        "index": "Liste des formulaires",
                        "main": "Formulaires d'admission",
                        "date_of_completion": "Date d'achèvement",
                        "reviewed_by": "Revu par",
                        "review_intake": "Examen du formulaire d'admission"
                    },
                    "redirect_agency": {
                        "modal_header": "S'inscrire auprès d'une Agence",
                        "form_submitted": "Formulaire d'admission soumis!",
                        "message1": "Si vous n'êtes pas encore inscrit auprès d'une agence, veuillez passer à la section \"Annuaire de la coalition LASI\" pour sélectionner une agence et les services requis.",
                        "message2": "Votre formulaire d'admission sera ensuite partagé automatiquement avec l'agence.",
                        "take_me_there": "Emmène-moi là-bas",
                        "message3": "Vous êtes inscrit à cette agence - ",
                        "message4": "Vous recevrez un e-mail lorsque votre formulaire sera accepté et vous pourrez alors prendre votre premier rendez-vous !"
                    }
                },
                "metas": {
                    "actions": {
                        "destroy": "Supprimer les metas sélectionnés"
                    },
                    "titles": {
                        "create": "Créer une meta",
                        "edit": "Editer une meta",
                        "index": "Liste des metas",
                        "main": "Metas"
                    }
                },
                "new_menu": {
                    "form_setting": "Nouveau paramétrage de formulaire",
                    "meta": "Nouvelle meta",
                    "post": "Nouvel article",
                    "redirection": "Nouvelle redirection",
                    "role": "Nouveau rôle",
                    "user": "Nouvel utilisateur"
                },
                "posts": {
                    "actions": {
                        "destroy": "Supprimer les articles sélectionnés",
                        "pin": "Epingler les articles sélectionnés",
                        "promote": "Mettre en avant les articles sélectionnés",
                        "publish": "Publier les articles sélectionnés"
                    },
                    "descriptions": {
                        "meta_description": "Si vide, la description par défaut sera le résumé de l'article.",
                        "meta_title": "Si vide, le titre par défaut sera celui de l'article."
                    },
                    "placeholders": {
                        "body": "Saisissez votre contenu...",
                        "meta_description": "Résumé de l'article.",
                        "meta_title": "Titre de l'article."
                    },
                    "statuses": {
                        "draft": "Brouillon",
                        "pending": "En attente",
                        "published": "Publié"
                    },
                    "titles": {
                        "create": "Créer un article",
                        "edit": "Editer un article",
                        "index": "Liste des articles",
                        "main": "Articles",
                        "publication": "Options de publication"
                    }
                },
                "received_services": {
                    "table": {
                        "fields": {
                            "agency": "Agence",
                            "details": "Détails",
                            "service": "Un service",
                            "worker": "Ouvrière"
                        }
                    },
                    "title": "Services reçus"
                },
                "redirections": {
                    "actions": {
                        "destroy": "Supprimer les redirections sélectionnées",
                        "disable": "Désactiver les redirections sélectionnées",
                        "enable": "Activer les redirections sélectionnées"
                    },
                    "import": {
                        "description": "Le fichier doit avoir 2 colonnes avec en-têtes de colonne \"source\" et \"target\", la redirection sera du type permanent par défaut",
                        "label": "Sélectionner un fichier CSV à importer",
                        "title": "Import de fichier CSV"
                    },
                    "titles": {
                        "create": "Création d'une redirection",
                        "edit": "Modification d'une redirection",
                        "index": "Liste des redirections",
                        "main": "Redirections"
                    },
                    "types": {
                        "permanent": "Redirection permanente (301)",
                        "temporary": "Redirection temporaire (302)"
                    }
                },
                "register_to_agency": {
                    "title": "Sélectionnez le (s) service (s)"
                },
                "roles": {
                    "titles": {
                        "create": "Créer un rôle",
                        "edit": "Editer un rôle",
                        "index": "Liste des rôles",
                        "main": "Rôles"
                    }
                },
                "service_providers_directory": {
                    "service_providers_directory": "Annnuaire des fournisseurs de services",
                    "search": "Rechercher",
                    "favourites": "Favoris",
                    "search_region_header": "Rechercher des services dans votre région",
                    "select_service_description": "Ou alors sélectionnez l'un des types de services ci-dessous pour trouver des organismes communautaires dans votre région.",
                    "sub_categories": "Sous-catégories",
                    "service_providers_for": "Fournisseurs de services pour",
                    "total_service_providers_found": "Nombre total de fournisseurs de services trouvés",
                    "no_category_found": "Aucune catégorie trouvée pour",
                    "search_results": "Résultats de recherche pour",
                    "name": "Nom",
                    "address": "Adresse",
                    "favourite": "Préféré",
                    "select_sub_category": "Sélectionnez une catégorie ou recherchez...",
                    "loading": "Chargement",
                    "service_providers": "Les fournisseurs de services",
                    "enter_search": "Rechercher..."
                },
                "service_providers_categories": {
                    "manage_service_providers_categories": "Gérer les catégories de fournisseurs de services",
                    "create_category": "Créer une catégorie",
                    "category_name": "Nom de catégorie",
                    "211_code": "Code 211",
                    "actions": "Actions",
                    "add_service_provider_category": "Ajouter une catégorie de fournisseur de services",
                    "add_category_name_en": "Nom de la catégorie (anglais)",
                    "add_category_name_placeholder_en": "Entrez le nom de la catégorie (anglais)...",
                    "add_category_name_fr": "Nom de la catégorie (français)",
                    "add_category_name_placeholder_fr": "Entrez le nom de la catégorie (français)...",
                    "add_category_211_code_placeholder": "Entrez le code 211 (taxonomie)...",
                    "create": "Créer",
                    "cancel": "Annuler",
                    "edit_service_provider_category": "Modifier la catégorie du fournisseur de services",
                    "update": "Mettre à jour",
                    "delete_service_provider_category": "Supprimer la catégorie de fournisseur de services",
                    "delete_confirm_message": "Êtes-vous sûr de vouloir supprimer cette catégorie: ",
                    "delete": "Supprimer",
                    "success": "Succès",
                    "add_success_message": "La nouvelle catégorie de fournisseur de services a été ajoutée avec succès!",
                    "edit_success_message": "La catégorie de fournisseur de services a été mise à jour avec succès!",
                    "delete_success_message": "La catégorie de fournisseur de services a été supprimée avec succès!",
                    "fa_icon": "Icône",
                    "fa_icon_code_placeholder": "Entrez le code Font Awesome comme celui-ci - fas fa-ambulance",
                    "fa_icon_helper_text": "Veuillez rechercher le code de l'icône ici",
                    "icon_code": "Code d'icône"
                },
                "service_categories": {
                    "service_name": "Nom du service",
                    "summary": "Sommaire",
                    "actions": "Actions",
                    "add": "Ajouter une catégorie de service",
                    "service_name_en": "Nom du service (anglais)",
                    "service_name_fr": "Nom du service (français)",
                    "service_name_placeholder_en": "Entrez le nom du service (anglais)...",
                    "service_name_placeholder_fr": "Entrez le nom du service (français)...",
                    "summary_en": "Résumé (anglais)",
                    "summary_fr": "Résumé (français)",
                    "summary_placeholder_en": "Saisir le résumé en anglais...",
                    "summary_placeholder_fr": "Saisir le résumé en français...",
                    "description_en": "Descriptif (Anglais)",
                    "description_fr": "Descriptif (français)",
                    "description_placeholder_en": "Entrez une description en anglais...",
                    "description_placeholder_fr": "Entrez une description en français...",
                    "edit": "Modifier la catégorie de service",
                    "created_success": "Catégorie de service créée avec succès!",
                    "updated_success": "Catégorie de service mise à jour avec succès!",
                    "deleted_success": "Catégorie de service supprimée avec succès!",
                    "delete": "Supprimer la catégorie de service",
                    "program_email": "Courriel du programme",
                    "program_email_placeholder": "Saisissez l'adresse e-mail du responsable du programme..."
                },
                "sidebar": {
                    "access": "Gestion des accès",
                    "content": "Gestion de contenu",
                    "forms": "Gestion des formulaires",
                    "links": {
                        "accounts": "Comptes",
                        "action_plan": "Plan d'action et référence",
                        "appointments": "Rendez-vous",
                        "appointments_and_communication": "Rendez-vous et communication",
                        "clients": "Clientes",
                        "contact_us": "Contactez le support",
                        "document_uploads": "Téléchargements de documents",
                        "feedback_and_complaints": "Retour d'information",
                        "intake_forms": "Formulaires d'admission",
                        "lasi_coalition_directory": "Annuaire de la Coalition LASI",
                        "learning_videos": "Centre d'apprentissage",
                        "my_information": "Mon information",
                        "previous_sessions": "Sessions précédentes",
                        "report": "rapport",
                        "reports": "Rapports",
                        "service_providers_directory": "Annuaire des fournisseurs de services",
                        "service_providers_categories": "Catégories de fournisseurs de services",
                        "services_activity": "Activité de services",
                        "sign_out": "Déconnexion",
                        "video_chat_room": "Salle de chat vidéo",
                        "workers": "Ouvriers"
                    },
                    "seo": "Paramétrages SEO"
                },
                "titles": {
                    "dashboard": "Tableau de bord"
                },
                "users": {
                    "actions": {
                        "destroy": "Supprimer les utilisateurs sélectionnés",
                        "disable": "Désactiver les utilisateurs sélectionnés",
                        "enable": "Activer les utilisateurs sélectionnés"
                    },
                    "titles": {
                        "create": "Créer un utilisateur",
                        "edit": "Editer un utilisateur",
                        "index": "Liste des utilisateurs",
                        "main": "Utilisateurs"
                    }
                },
                "video_char_room": {
                    "titles": {
                        "page_title": "Salle de chat vidéo"
                    }
                }
            },
            "by": "par",
            "choose_file": "Sélectionner un fichier",
            "contact_us_client_topics": {
                "placeholder": "Sélectionnez le sujet",
                "topics": [
                    "Thème 1",
                    "Thème 2",
                    "Thème 3",
                    "Thème 4",
                    "Thème 5"
                ]
            },
            "contact_us_info": "Veuillez nous contacter pour tout problème technique.",
            "contacts": "Contacts",
            "contacts_info": "Informations sur les contacts",
            "cookieconsent": {
                "dismiss": "J'ai compris !",
                "message": "En poursuivant votre navigation sur ce site, vous acceptez l’utilisation de Cookies afin de réaliser des statistiques de visites."
            },
            "created_at": "Créé le",
            "datatables": {
                "entries_per_page": "éléments par page",
                "infos": "Affichage de l'élément {offset_start} à {offset_end} sur {total} éléments",
                "no_matched_results": "Aucun résultat correspondant à votre recherche",
                "no_results": "Aucun résultat trouvé",
                "search": "Rechercher",
                "show_per_page": "Afficher"
            },
            "delete_image": "Supprimer l'image",
            "deleted_at": "Supprimé le",
            "descriptions": {
                "allowed_image_types": "Extensions autorisés: png gif jpg jpeg."
            },
            "export": "Exporter",
            "frontend": {
                "blog": {
                    "published_at": "Publié le {date}",
                    "published_at_with_owner_linkable": "Publié le {date} par <a href=\"{link}\">{name}</a>",
                    "tags": "Tags"
                },
                "placeholders": {
                    "locale": "Sélectionnez votre langue",
                    "select_options": "Sélectionnez les options",
                    "selected_options": "Options sélectionnées",
                    "timezone": "Sélectionnez votre fuseau horaire"
                },
                "submissions": {
                    "message_sent": "<p>Votre demande de contact a bien été envoyée</p>"
                },
                "titles": {
                    "about": "Qui sommes-nous ?",
                    "administration": "Administration",
                    "available_services": "Services disponibles",
                    "community_connect": "Connexion communautaire",
                    "blog": "Blog",
                    "contact": "Contact",
                    "home": "Accueil",
                    "legal_mentions": "Mention légales",
                    "message_sent": "Message envoyé",
                    "filter_by": "Filtrer Par:",
                    "contact_info": "Coordonnées",
                    "services_provided": "Prestations fournies par:",
                    "program_manager_email": "Courriel du gestionnaire de programme"
                }
            },
            "general": "Général",
            "general_info": "Informations générales",
            "http": {
                "403": {
                    "description": "Désolé, mais vous n'avez pas les permissions pour accéder à cette page.",
                    "title": "Accès non autorisé"
                },
                "404": {
                    "description": "Désolé, mais la page à laquelle vous tentez d'accéder n'existe pas.",
                    "title": "Page introuvable"
                },
                "500": {
                    "description": "Désolé, mais le serveur a rencontré une erreur non prévue. Nous la fixerons dès que possible.",
                    "title": "Erreur serveur"
                }
            },
            "immigration_document_number": "Numéro du document d'immigration",
            "language": "Langue",
            "language_preferences": "Préférences linguistiques",
            "last_access_at": "Dernier accès le",
            "link": "lien",
            "localization": {
                "ar": "arabe",
                "de": "Allemande",
                "en": "Anglais",
                "es": "Espagnole",
                "fr": "Français",
                "pt": "Portugais",
                "ru": "russe",
                "zh": "Chinoise",
                "english": "Anglais",
                "french": "Français",
                "kirundi": "Kirundi",
                "swahili": "Swahili",
                "lingala": "Lingala",
                "arabic": "Arabe",
                "creole": "Créole",
                "somali": "Somali",
                "russian": "Russe",
                "mandarin": "Mandarine",
                "cantonese": "Cantonais",
                "farsi": "Farsi",
                "turkish": "Turque",
                "hindi": "Hindi",
                "tamil": "Tamil",
                "tagalog": "Tagalogue",
                "kurdish": "Kurde",
                "armenian": "Arménienne",
                "chinese": "Chinoise",
                "polish": "Polonais",
                "igbo": "Igbo",
                "spanish": "Espagnole",
                "portuguese": "Portugais",
                "bengali": "Bengali",
                "hindu": "Hindi",
                "urdu": "Ourdou",
                "nepali": "Népalaise",
                "serbian": "Serbe",
                "dari": "Dari",
                "tigrinya": "Tigrinya",
                "amharic": "Amharique",
                "italian": "Italienne",
                "german": "Allemande",
                "ukrainian": "Ukrainienne",
                "bosnian": "Bosniaque",
                "punjabi": "Pendjabi",
                "sinhalese": "Cinghalais"
            },
            "locations": {
                "West": "Ouest",
                "East": "Est",
                "South": "Sud",
                "North": "Nord"
            },
            "more": "Suite",
            "location": "Emplacement",
            "mailing_address": "Adresse postale",
            "mandatory_fields": "Les champs avec * sont obligatoires",
            "select_type": "Sélectionner le genre",
            "marital_status": "État civil",
            "more_info": "Plus d'info",
            "morphs": {
                "post": "Article, identifiant {id}",
                "user": "Utilisateur, identifiant {id}"
            },
            "no": "Non",
            "no_file_chosen": "Aucun fichier sélectionné",
            "no_results": "Aucun résultat trouvé",
            "no_value": "Aucune valeur",
            "personal_details": "Détails personnels",
            "placeholders": {
                "immigration_citizen_intends": "Sélectionnez l'option",
                "route": "Sélectionner une route interne valide",
                "select_country": "Choisissez le pays",
                "select_immigration_class": "Sélectionnez la classe d'immigration",
                "select_immigration_id_type": "Sélectionnez le type d'identité d'immigration",
                "select_immigration_status": "Sélectionnez le statut d'immigration",
                "select_language": "Choisir la langue",
                "select_marital_status": "Sélectionnez le statut",
                "select_region": "Choisissez une région",
                "tags": "Sélectionner ou créer une étiquette"
            },
            "priority_level": "Niveau de priorité",
            "published_at": "Publié le",
            "residence_address": "Adresse de domicile",
            "results": "Résultats",
            "same_as_residence_address": "Identique à l'adresse de résidence",
            "search": "Rechercher",
            "select_agency": "Sélectionnez une agence",
            "select_category": "Choisir une catégorie",
            "select_client": "Sélectionnez un client",
            "title": "Titre",
            "type_of_services": "Services fournis",
            "updated_at": "Modifié le",
            "upload_image": "Transférer une image",
            "user": {
                "account": "Mon compte",
                "account_delete": "<p>Cette action supprimera définitivement votre compte de ce site ainsi que toutes vos données associées.</p>",
                "account_deleted": "Compte supprimé avec succès",
                "administration": "Administration",
                "avatar": "Avatar",
                "change_password": "Changer mon mot de passe",
                "create_account": "Créer un compte",
                "no_account": "Vous n'avez pas encore de compte ?",
                "dashboard": "Tableau de bord",
                "delete": "Supprimer mon compte",
                "edit_profile": "Mettre à jour mon profil",
                "login": "Connexion",
                "logout": "Déconnexion",
                "member_since": "Membre depuis le {date}",
                "online": "En ligne",
                "password_forgot": "Mot de passe oublié ?",
                "password_reset": "Réinitialisation du mot de passe",
                "password_updated": "Mot de passe modifié avec succès.",
                "profile": "Mon profil",
                "profile_updated": "Profil modifié avec succès.",
                "register": "S'inscrire",
                "remember": "Se souvenir",
                "reset_password": "Réinitialiser le mot de passe",
                "send_password_link": "Envoyer le mot de passe de réinitialisation",
                "settings": "Paramètres",
                "sign_in": "Se connecter",
                "space": "Mon espace",
                "super_admin": "Super admin",
                "titles": {
                    "account": "Mon compte",
                    "space": "Mon espace"
                },
                "email_sent_success": "Nous avons envoyé avec succès un lien pour réinitialiser votre mot de passe !",
                "email_sent_error": "Erreur lors de l'envoi de l'e-mail pour la réinitialisation du mot de passe, veuillez vérifier que votre e-mail est correct ou contactez LASI.",
                "why_create_account": "Pourquoi créer un compte?",
                "why_create_account_line1": "Connectez-vous facilement avec les travailleurs de l'établissement via le chat vidéo",
                "why_create_account_line2": "Obtenez des réponses précises à vos questions",
                "why_create_account_line3": "Recevez des références à des soutiens communautaires, et plus encore",
                "already_have_account": "Vous avez déjà un compte?",
                "setting_up_account": "Veuillez patienter, nous créons votre compte..."
            },
            "validate": "Valider",
            "with": "avec",
            "yes": "Oui",
            "identified_needs": "Besoins identifiés",
            "date_created": "date créée",
            "date_completed": "Rendez-vous complet",
            "filter_by_category": "Filtrer par catégorie...",
            "filter_by_language": "Filtrer par langue...",
            "subject": "Sujette",
            "send_message": "Envoyer le message",
            "export_db_header": "Exporter le vidage de la base de données",
            "export_db_modal_1": "Souhaitez-vous lancer une sauvegarde de base de données?",
            "export_db_modal_2": "Veuillez noter que la préparation du vidage de la base de données peut prendre un certain temps et que les webmasters seront avertis par un\r\n    lien de téléchargement, une fois terminé.",
            "export_db_modal_3": "Le lien ne sera actif que pendant 30 minutes et expirera après cela.",
            "user_reactivate_success": "Le compte a été activé avec succès et une notification par e-mail a été envoyée à l'utilisateur !",
            "reactivate_account": "Réactiver le compte",
            "user_deactivated_message": "Cet utilisateur a un compte désactivé dans le système.",
            "user_reactivate_question": "Souhaitez-vous réactiver cet utilisateur à la place?",
            "languages": "Langues"
        },
        "logs": {
            "backend": {
                "form_submissions": {
                    "created": "Soumission de formulaire ID {form_submission} créée"
                },
                "users": {
                    "created": "Utilisateur ID {user} créé",
                    "deleted": "Utilisateur ID {user} supprimé",
                    "updated": "Utilisateur ID {user} mis à jour"
                }
            },
            "frontend": []
        },
        "mails": {
            "action_plan_to_client": {
                "intro1": "",
                "intro2": "Veuillez vous connecter à votre compte sur le portail LASI et mettre à jour votre plan d'action en conséquence.",
                "intro3": "Je vous remercie,",
                "intro4": "Portail LASI",
                "subject": "Plan d'action",
                "table": {
                    "action": "Action requise",
                    "title": "Titre / besoin identifié"
                }
            },
            "agency_doc_removed_notification": {
                "message": "",
                "subject": "Document supprimé"
            },
            "agency_new_doc_upload_notification": {
                "message": "",
                "subject": "Téléchargement de nouveau document"
            },
            "agency_registration_notification": {
                "message": "",
                "subject": "Inscription d'un nouveau client"
            },
            "alert": {
                "message": "Une exception serveur non prévue a été levée dont le message est : {message}.",
                "subject": "[{app_name}] Exception error",
                "trace": "Trace complète :"
            },
            "appointment_canceled_by_client": {
                "message": "",
                "subject": "Annulation de rendez-vous"
            },
            "appointment_canceled_to_client": {
                "message": "",
                "subject": "Annulation de rendez-vous"
            },
            "appointment_confirm_to_client": {
                "message": "",
                "subject": "Rendez-vous confirmer"
            },
            "appointment_confirm_to_worker": {
                "message": "",
                "subject": "Rendez-vous confirmer"
            },
            "appointment_request_from_client_to_worker": {
                "message": "",
                "subject": "Demande de rendez-vous"
            },
            "appointment_request_from_worker_to_client": {
                "message": "",
                "subject": "Demande de rendez-vous"
            },
            "client_agency_invitation": {
                "message": "",
                "subject": "Inscription Lasi"
            },
            "client_agency_registration": {
                "message": "",
                "subject": "Enregistrement d'agence"
            },
            "client_intake_form_accept": {
                "message": "",
                "subject": "Formulaire d'admission accepter"
            },
            "client_intake_form_declined": {
                "message": "",
                "subject": "Formulaire d'admission refusé"
            },
            "client_intake_form_submit": {
                "message": "",
                "subject": "Soumettre le formulaire d'admission"
            },
            "client_intake_form_non_ontario_submit": {
                "subject": "Intake form submit",
                "message": "Cher {user_name}, <br><br>\r\n                      Veuillez noter que nous ne sommes pas en mesure de soutenir les résidents non-ontariens pour le moment. Vous pouvez consulter les liens suivants d'autres organisations d'établissement pour obtenir de l'aide :<br<br>\r\n                      Merci <br>\r\n                      LASI Team."
            },
            "client_registration_notification": {
                "message": "",
                "subject": "Enregistrement"
            },
            "client_send_email_contact_us": {
                "message": "",
                "subject": "Client envoyer un e-mail contactez-nous"
            },
            "client_worker_assign_notification": {
                "message": "",
                "subject": "Affectation des travailleurs"
            },
            "contact": {
                "new_contact": "Vous avez reçu un nouveau message de contact. Détail de la soumission :",
                "subject": "Nouveau message de contact"
            },
            "front_end_contact_us_send_email": {
                "message": "",
                "subject": "De Contactez-nous Envoyer un formulaire électronique"
            },
            "layout": {
                "all_rights_reserved": "Tous droits réservés!!",
                "hello": "Bonjour !",
                "regards": "Cordialement",
                "trouble": "Si vous rencontrer un problème en cliquant sur le bouton {action}, copier et coller l'URL suivante dans votre navigateur :"
            },
            "password_reset": {
                "action": "Réinitialiser le mot de passe",
                "intro": "Vous recevez cet email car vous avez effectué une demande de réinitialisation de mot de passe.",
                "intro1": "",
                "intro2": "Si vous avez fait cette demande, veuillez cliquer sur le lien suivant:",
                "outro": "Si vous n'avez pas fait cette demande de réinitialisation, aucune action n'est requise.",
                "subject": "Réinitialisation de mon mot de passe"
            },
            "staff_registration_notification": {
                "message": "",
                "subject": "Enregistrement"
            },
            "worker_clients_assign_notification": {
                "message": "",
                "subject": "Attribution des clients"
            },
            "worker_intake_form_accept": {
                "message": "",
                "subject": "Formulaire d'admission accepter"
            },
            "{$this->formSubmission->type}": {
                "subject": ""
            },
            "client_worker_referral_notification": {
                "subject": "Référence du travailleur",
                "message": "Cher {client_name}, <br><br>\r\n                          {worker_name} vous a été référé.\r\n                          <br><br>Thank you,<br>LASI Portal"
            },
            "worker_client_referral_notification": {
                "subject": "Clients referral",
                "message": "Dear {worker_name}, <br><br>\r\n                          You have been referred to {client_name}.\r\n                          <br><br>Thank you,<br>LASI Portal"
            },
            "role_changed": {
                "subject": "Rôle utilisateur modifié",
                "message": "Cher {user_name},<br><br>Votre rôle d'utilisateur a été mis à jour pour <strong>user_role</strong><br><br><br>\r\n                     Merci,<br>LASI Portal"
            }
        },
        "pagination": {
            "next": "Suivant",
            "previous": "Précédent"
        },
        "passwords": {
            "password": "Les mots de passe doivent contenir au moins six caractères et doivent être identiques.",
            "reset": "Votre mot de passe a été réinitialisé !",
            "sent": "Nous vous avons envoyé par courriel le lien de réinitialisation du mot de passe !",
            "sent-client": "Nous avons envoyé un lien de réinitialisation de mot de passe par e-mail à ce client!",
            "token": "Ce jeton de réinitialisation du mot de passe n'est pas valide.",
            "user": "Aucun utilisateur n'a été trouvé avec cette adresse e-mail."
        },
        "permissions": {
            "access": {
                "backend": {
                    "description": "Permet l'accès aux pages du backoffice.",
                    "display_name": "Accès au backoffice"
                }
            },
            "categories": {
                "access": "Accès",
                "blog": "Blog",
                "clients": "Clientes",
                "documents": "Des documents",
                "feedback": "Retour d'information",
                "form": "Formulaires",
                "intake_forms": "Formulaires d'admission",
                "seo": "SEO",
                "workers": "Ouvrières"
            },
            "create": {
                "documents": {
                    "description": "Peut créer des documents.",
                    "display_name": "Créer des documents"
                },
                "feedback": {
                    "description": "Peut créer des commentaires.",
                    "display_name": "Créer des commentaires"
                },
                "form_settings": {
                    "description": "Peut créer des paramètres de formulaires.",
                    "display_name": "Créer les paramètres de formulaires"
                },
                "intake_forms": {
                    "description": "Peut créer une forme d'admission.",
                    "display_name": "Créer un formulaire d'admission"
                },
                "metas": {
                    "description": "Peut créer des metas.",
                    "display_name": "Créer les metas"
                },
                "posts": {
                    "description": "Peut créer des articles.",
                    "display_name": "Créer les articles"
                },
                "redirections": {
                    "description": "Peut créer des redirections.",
                    "display_name": "Créer les redirections"
                },
                "roles": {
                    "description": "Peut créer des rôles.",
                    "display_name": "Créer les rôles"
                },
                "users": {
                    "description": "Peut créer des utilisateurs.",
                    "display_name": "Créer les utilisateurs"
                }
            },
            "delete": {
                "documents": {
                    "description": "Peut supprimer tous les documents.",
                    "display_name": "Supprimer tous les documents"
                },
                "form_settings": {
                    "description": "Peut supprimer des paramètres de formulaires.",
                    "display_name": "Supprimer les paramètres de formulaires"
                },
                "form_submissions": {
                    "description": "Peut supprimer des soumissions de formulaire.",
                    "display_name": "Supprimer les soumissions de formulaire"
                },
                "intake_forms": {
                    "description": "Peut supprimer tous les formulaires d'admission.",
                    "display_name": "Supprimer tous les formulaires d'admission"
                },
                "metas": {
                    "description": "Peut supprimer des metas.",
                    "display_name": "Supprimer les metas"
                },
                "own": {
                    "documents": {
                        "description": "Peut supprimer ses propres documents",
                        "display_name": "Supprimer ses propres documents"
                    },
                    "intake_forms": {
                        "description": "Peut supprimer ses propres formulaires d'admission.",
                        "display_name": "Supprimer ses propres formulaires d'admission"
                    },
                    "posts": {
                        "description": "Peut supprimer ses propres articles.",
                        "display_name": "Supprimer ses propres articles"
                    }
                },
                "posts": {
                    "description": "Peut supprimer l'ensemble des articles.",
                    "display_name": "Supprimer tous les articles"
                },
                "redirections": {
                    "description": "Peut supprimer des redirections.",
                    "display_name": "Supprimer les redirections"
                },
                "roles": {
                    "description": "Peut supprimer des rôles.",
                    "display_name": "Supprimer les rôles"
                },
                "users": {
                    "description": "Peut supprimer des utilisateurs.",
                    "display_name": "Supprimer les utilisateurs"
                },
                "workers": {
                    "description": "Peut supprimer des travailleurs.",
                    "display_name": "Supprimer les travailleurs"
                }
            },
            "edit": {
                "form_settings": {
                    "description": "Peut modifier des paramètres de formulaires.",
                    "display_name": "Modifier les paramètres de formulaires"
                },
                "intake_forms": {
                    "description": "Peut modifier tous les formulaires d'admission.",
                    "display_name": "Modifier tous les formulaires d'admission"
                },
                "metas": {
                    "description": "Peut modifier des metas.",
                    "display_name": "Modifier les metas"
                },
                "own": {
                    "intake_forms": {
                        "description": "Peut modifier son propre formulaire d'admission.",
                        "display_name": "Modifier son propre formulaire d'admission"
                    },
                    "posts": {
                        "description": "Peut modifier ses propres articles.",
                        "display_name": "Modifier ses propres articles"
                    }
                },
                "posts": {
                    "description": "Peut modifier l'ensemble des articles.",
                    "display_name": "Modifier tous les articles"
                },
                "redirections": {
                    "description": "Peut modifier des redirections.",
                    "display_name": "Modifier les redirections"
                },
                "roles": {
                    "description": "Peut modifier des rôles.",
                    "display_name": "Modifier les rôles"
                },
                "users": {
                    "description": "Peut modifier des utilisateurs.",
                    "display_name": "Modifier les utilisateurs"
                }
            },
            "impersonate": {
                "description": "Permet de prendre l'identité d'un autre utilisateur. Utile pour les tests.",
                "display_name": "Usurpation d'utilisateur"
            },
            "publish": {
                "posts": {
                    "description": "Possibilité de gérer la publication des articles.",
                    "display_name": "Publier les articles"
                }
            },
            "view": {
                "clients": {
                    "description": "Peut voir les clients.",
                    "display_name": "Afficher les clients"
                },
                "documents": {
                    "description": "Peut afficher tous les documents.",
                    "display_name": "Afficher tous les documents"
                },
                "feedback": {
                    "description": "Peut afficher tous les commentaires.",
                    "display_name": "Voir tous les commentaires"
                },
                "form_settings": {
                    "description": "Peut voir des paramètres de formulaires.",
                    "display_name": "Voir les paramètres de formulaires"
                },
                "form_submissions": {
                    "description": "Peut voir des soumissions de formulaire.",
                    "display_name": "Voir les soumissions de formulaire"
                },
                "intake_forms": {
                    "description": "Peut afficher tous les formulaires d'admission.",
                    "display_name": "Voir tous les formulaires d'admission"
                },
                "metas": {
                    "description": "Peut voir des metas.",
                    "display_name": "Voir les metas"
                },
                "own": {
                    "documents": {
                        "description": "Peut posséder des documents.",
                        "display_name": "Afficher vos propres documents"
                    },
                    "intake_forms": {
                        "description": "Peut posséder un formulaire d'admission.",
                        "display_name": "Voir son propre formulaire d'admission"
                    },
                    "posts": {
                        "description": "Peut voir ses propres articles.",
                        "display_name": "Voir ses propres articles"
                    }
                },
                "posts": {
                    "description": "Peut voir l'ensemble des articles.",
                    "display_name": "Voir tous les articles"
                },
                "redirections": {
                    "description": "Peut voir des redirections.",
                    "display_name": "Voir les redirections"
                },
                "roles": {
                    "description": "Peut voir des rôles.",
                    "display_name": "Voir les rôles"
                },
                "users": {
                    "description": "Peut voir des utilisateurs.",
                    "display_name": "Voir les utilisateurs"
                },
                "workers": {
                    "description": "Peut voir les travailleurs.",
                    "display_name": "Voir les travailleurs"
                }
            }
        },
        "static": {
            "backend": {
                "pages": {
                    "client_profile": {
                        "action_plan": "Plan d'action",
                        "add_amend": "Ajouter / modifier des sections supplémentaires",
                        "assessment_form": "Formulaire d'évaluation",
                        "assigned_to": "Assigné à",
                        "case_narrations": "Narrations de cas",
                        "contacts": "Contacts",
                        "languages": "Langues",
                        "referral": "Référence",
                        "tab1": {
                            "title": "Rendez-vous"
                        },
                        "tab2": {
                            "title": "Prestations de service"
                        },
                        "tab3": {
                            "title": "Plan d'action"
                        },
                        "tab4": {
                            "title": "Formulaire d'évaluation"
                        },
                        "tab5": {
                            "title": "Narrations de cas"
                        },
                        "title": "Profil client"
                    },
                    "intake_form": {
                        "callout": "Remplissez, modifiez et visualisez les informations de votre formulaire d'admission qui seront utilisées par les agences auprès desquelles vous êtes inscrit pour vous fournir les services souhaités.",
                        "footer_text": "Les informations soumises dans le formulaire d'admission ci-dessus ne seront partagées qu'avec les agences LASI auprès desquelles vous vous inscrirez afin de vous fournir les services requis.",
                        "tab1": {
                            "title": "Informations démographiques et de contact"
                        },
                        "tab2": {
                            "title": "Informations relatives à l'immigration"
                        },
                        "title": "Formulaire d'admission"
                    },
                    "my_profile": {
                        "tab1": {
                            "title": "Mon information"
                        },
                        "tab2": {
                            "title": "Prestations de service"
                        }
                    }
                }
            },
            "frontend": {
                "pages": {
                    "about": {
                        "h1": "À propos de nous",
                        "h5": "Collectivement, les agences LASI fournissent des services à plus de 20 000 immigrants et réfugiés par an. Les prestataires de services qui composent LASI sont:",
                        "li1": "Centre catholique pour immigrants (CCI)",
                        "li10": "Centre d'emploi Worldskills",
                        "li11": "YMCA-YWCA de la région de la capitale nationale",
                        "li2": "Conseil économique et social d’Ottawa - Carleton (CESOC)",
                        "li3": "Services aux femmes immigrantes d'Ottawa (IWSO)",
                        "li4": "Services à la famille juive (JFS)",
                        "li5": "La Cité Collégiale",
                        "li6": "Agence des services sociaux libanais et arabe (LASSA)",
                        "li7": "Centre de services communautaires chinois d'Ottawa (OCCSC)",
                        "li8": "Organisation des services communautaires aux immigrants d'Ottawa (OCISO)",
                        "li9": "Centre somalien des services à la famille (SFS)"
                    },
                    "contact": {
                        "h1": "Nous contacter",
                        "p1": "Dites-nous comment nous pouvons vous aider."
                    },
                    "forgot_pass": {
                        "p1": "Entrez votre adresse e-mail ci-dessous. Nous rechercherons votre compte et vous enverrons un e-mail de réinitialisation de mot de passe."
                    },
                    "home": {
                        "main_banner": {
                            "p2": "LASI est heureuse d'offrir un portail unique aux immigrants et réfugiés nouvellement arrivés à Ottawa pour accéder aux renseignements et aux services essentiels dont ils ont besoin.",
                            "p3": "Trouvez ici les services importants d'établissement, de langue, de santé, d'emploi, d'éducation, de counselling et spécialisés offerts par les 11 agences.",
                            "tab1": "Agences",
                            "tab2": "Prestations de service"
                        },
                        "page_description": {
                            "h2": "Premiers jours au Canada"
                        }
                    },
                    "service-provider": {
                        "h1": "Fournisseur de services"
                    },
                    "services": {
                        "top_section": {
                            "h1": "Prestations de service",
                            "p1": "LASI est heureuse d'offrir un portail unique aux immigrants et réfugiés nouvellement arrivés à Ottawa pour accéder aux renseignements et aux services essentiels dont ils ont besoin. Trouvez ici les services importants d'établissement, de langue, de santé, d'emploi, d'éducation, de counselling et spécialisés offerts par les 11 agences.",
                            "tab1": "Annuaire des fournisseurs de services"
                        }
                    },
                    "sign_in": {
                        "p1": "Veuillez saisir votre adresse e-mail et votre mot de passe pour accéder à votre portfolio, assister à des webinaires et participer à un rendez-vous planifié."
                    },
                    "sign_up": {
                        "p1": "Inscrivez-vous pour prendre rendez-vous et accéder aux services"
                    }
                },
                "sections": {
                    "available_agencies": {
                        "h2": "Pour commencer, utilisez les filtres ci-dessous pour trouver l'agence qui correspond le mieux à vos besoins:",
                        "p1": "Le Big Oxmox lui a conseillé de ne pas le faire, car il y avait des milliers de mauvaises virgules",
                        "p2-1": "SERVICES FOURNIS",
                        "p2-2": "LISTE DES SERVICES À CONFIRMER BIENTÔT"
                    },
                    "contact": {
                        "h2": "<h2>Nous Contacter</h2>",
                        "h3": "<h3>Questions ou commentaires?</h3>",
                        "p1": "<p>Si vous avez une question sur LASI ou avez une difficulté technique,</p>",
                        "p2": "<p>merci de nous envoyer un message via le formulaire de contact.</p>"
                    },
                    "our_services": {
                        "p1": "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
                        "p2": "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
                        "services": {
                            "s1": {
                                "li1": "Établissement - Information sur la santé, le logement, l'éducation, le transport et d'autres services communautaires",
                                "li2": "Traduction et interprétation"
                            },
                            "s10": {
                                "li1": "Parrainage privé"
                            },
                            "s2": {
                                "li1": "Programmes sociaux et d'appariement",
                                "li2": "Programmes de loisirs",
                                "li3": "Cercles de conversation"
                            },
                            "s3": {
                                "li1": "Formation en anglais",
                                "li2": "Formation en langue française",
                                "li3": "Formation linguistique en milieu de travail",
                                "li4": "Formation de préparation à la citoyenneté",
                                "li5": "Formation des soignants",
                                "li6": "Formation informatique",
                                "li7": "Autre"
                            },
                            "s4": {
                                "li1": "Préparation au travail",
                                "li2": "Conseil du travail",
                                "li3": "Liens avec les employeurs",
                                "li4": "Démarrer votre propre entreprise"
                            },
                            "s5": {
                                "li1": "Conseil clinique",
                                "li2": "Santé mentale",
                                "li3": "Soutien aux faibles revenus",
                                "li4": "Soutien intensif aux réfugiés"
                            },
                            "s6": {
                                "li1": "Intervention de crise",
                                "li2": "Logement et santé",
                                "li3": "Conseils",
                                "li4": "Groupes de soutien"
                            },
                            "s7": {
                                "li1": "Loisirs, leadership et social",
                                "li2": "Soutiens en milieu scolaire",
                                "li3": "Travaux",
                                "li4": "Conseils",
                                "li5": "Prévention juridique et du crime"
                            },
                            "s8": {
                                "li1": "Services linguistiques",
                                "li2": "Programmation sociale",
                                "li3": "Loisirs, bien-être et exercice",
                                "li4": "Liens communautaires"
                            },
                            "s9": {
                                "li1": "Cliniques fiscales",
                                "li2": "Ateliers budget, crédit et banque",
                                "li3": "Prêts"
                            }
                        }
                    },
                    "questions": {
                        "h2": "Des questions?",
                        "p1": "Si vous avez une question sur LASI ou avez une difficulté technique, veuillez nous contacter."
                    },
                    "what_is_lasi": {
                        "h2": "Qu'est-ce que LASI?",
                        "p1": "Les agences locales au service des immigrants (LASI) sont une coalition de 11 agences offrant des services d'établissement aux immigrants et aux réfugiés de la région d'Ottawa. Les membres de LASI travaillent ensemble pour faire progresser l'intégration sociale et économique des nouveaux arrivants grâce à l'amélioration continue des services, à la mise en œuvre d'initiatives de collaboration et à l'engagement avec la communauté au sens large.",
                        "p2": "Au fil des ans, la Coalition LASI a travaillé ensemble sur des initiatives conjointes pour soutenir les nouveaux arrivants. Le portail de prestation de services d'établissement LASI est l'une de ces initiatives.",
                        "p3": "Collectivement, les agences LASI fournissent des services à plus de 20 000 immigrants et réfugiés par an. Les prestataires de services qui composent LASI sont:"
                    }
                }
            }
        },
        "validation": {
            "accepted": "Le champ {attribute} doit être accepté.",
            "active_url": "Le champ {attribute} n'est pas une URL valide.",
            "after": "Le champ {attribute} doit être une date postérieure au {date}.",
            "after_or_equal": "Le champ {attribute} doit être une date postérieure ou égal au {date}.",
            "alpha": "Le champ {attribute} doit seulement contenir des lettres.",
            "alpha_dash": "Le champ {attribute} doit seulement contenir des lettres, des chiffres et des tirets.",
            "alpha_num": "Le champ {attribute} doit seulement contenir des chiffres et des lettres.",
            "array": "Le champ {attribute} doit être un tableau.",
            "attributes": {
                "active": "Actif",
                "address": "Adresse",
                "address_street": "Adresse de rue",
                "age": "Age",
                "agency": "Agence",
                "attach_files": "Joindre des fichiers",
                "available": "Disponible",
                "body": "Corps",
                "city": "Ville",
                "comment": "Commentaire",
                "content": "Contenu",
                "country": "Pays",
                "date": "Date",
                "date_of_birth": "Date de naissance",
                "day": "Jour",
                "description": "Description",
                "display_name": "Nom affiché",
                "document_number": "Numéro du document d'immigration",
                "email": "Adresse courriel",
                "email_address": "Adresse courriel",
                "end_date": "Date de fin",
                "excerpt": "Extrait",
                "first_name": "Prénom",
                "form_data": "Données du formulaire",
                "form_type": "Type de formulaire",
                "g-recaptcha-response": "Captcha",
                "gender": "Genre",
                "hour": "Heure",
                "image": "Image",
                "immigration_citizen_intends": "A l'intention de devenir citoyen canadien?",
                "immigration_class": "Classe d'immigration",
                "immigration_country_of_origin": "Pays d'origine",
                "immigration_country_of_residence": "Dernier pays de résidence",
                "immigration_date_arrival": "Date d'arrivée",
                "immigration_id_type": "Type d'identité d'immigration",
                "immigration_status": "Statut d'immigration",
                "port_of_entry": "Port d'entrée",
                "last_name": "Nom",
                "locale": "Localisation",
                "marital_status": "État civil",
                "message": "Message",
                "metable_type": "Entité",
                "middle_name": "Deuxième nom",
                "minute": "Minute",
                "mobile": "Portable",
                "month": "Mois",
                "mother_tongue": "Langue maternelle",
                "name": "Nom",
                "new_password": "Nouveau mot de passe",
                "new_password_confirmation": "Confirmation du nouveau mot de passe",
                "nr_family_members": "Nombre de membres de la famille",
                "old_password": "Ancien mot de passe",
                "order": "Ordre d'affichage",
                "organization_name": "Nom de l'organisme",
                "password": "Mot de passe",
                "password_confirmation": "Confirmer le mot de passe",
                "permissions": "Autorisations",
                "phone": "Téléphone",
                "phone_number": "Numéro de téléphone",
                "phone_number_alt": "Autre numéro de téléphone",
                "pinned": "Epinglé",
                "place_of_birth": "Lieu de naissance",
                "postal_code": "Code postal",
                "promoted": "Mis en avant",
                "province_state": "Province / État",
                "published_at": "Publier le",
                "rate_us": "Votre taux satisfaisant",
                "recipients": "Destinataires",
                "redirect_type": "Type de redirection",
                "role": "Rôle",
                "roles": "Rôles",
                "route": "Route",
                "second": "Seconde",
                "sex": "Sexe",
                "size": "Taille",
                "source_path": "Chemin origine",
                "start_date": "Date de début",
                "status": "Statut",
                "summary": "Résumé",
                "tags": "Etiquettes",
                "target_path": "Chemin cible",
                "time": "Heure",
                "timezone": "Fuseau horaire",
                "title": "Titre",
                "topic": "Sujette",
                "unpublished_at": "Dépublier le",
                "url": "Alias URL",
                "username": "Pseudo",
                "year": "Année",
                "phone_ext": "Extension de téléphone",
                "current_password": "Mot de passe actuel"
            },
            "before": "Le champ {attribute} doit être une date antérieure au {date}.",
            "before_or_equal": "Le champ {attribute} doit être une date antérieure ou égal au {date}.",
            "between": {
                "array": "Le tableau {attribute} doit contenir entre {min} et {max} éléments.",
                "file": "La taille du fichier de {attribute} doit être comprise entre {min} et {max} kilo-octets.",
                "numeric": "La valeur de {attribute} doit être comprise entre {min} et {max}.",
                "string": "Le texte {attribute} doit contenir entre {min} et {max} caractères."
            },
            "boolean": "Le champ {attribute} doit être vrai ou faux.",
            "confirmed": "Le champ de confirmation {attribute} ne correspond pas.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "date": "Le champ {attribute} n'est pas une date valide.",
            "date_format": "Le champ {attribute} ne correspond pas au format {format}.",
            "different": "Les champs {attribute} et {other} doivent être différents.",
            "digits": "Le champ {attribute} doit contenir {digits} chiffres.",
            "digits_between": "Le champ {attribute} doit contenir entre {min} et {max} chiffres.",
            "dimensions": "La taille de l'image {attribute} n'est pas conforme.",
            "distinct": "Le champ {attribute} a une valeur dupliquée.",
            "email": "Le champ {attribute} doit être une adresse e-mail valide.",
            "exists": "Le champ {attribute} sélectionné est invalide.",
            "file": "Le champ {attribute} doit être un fichier.",
            "filled": "Le champ {attribute} doit avoir une valeur.",
            "image": "Le champ {attribute} doit être une image.",
            "in": "Le champ {attribute} est invalide.",
            "in_array": "Le champ {attribute} n'existe pas dans {other}.",
            "integer": "Le champ {attribute} doit être un entier.",
            "ip": "Le champ {attribute} doit être une adresse IP valide.",
            "ipv4": "Le champ {attribute} doit être une adresse IPv4 valide.",
            "ipv6": "Le champ {attribute} doit être une adresse IPv6 valide.",
            "json": "Le champ {attribute} doit être un document JSON valide.",
            "max": {
                "array": "Le tableau {attribute} ne peut contenir plus de {max} éléments.",
                "file": "La taille du fichier de {attribute} ne peut pas dépasser {max} kilo-octets.",
                "numeric": "La valeur de {attribute} ne peut être supérieure à {max}.",
                "string": "Le texte de {attribute} ne peut contenir plus de {max} caractères."
            },
            "mimes": "Le champ {attribute} doit être un fichier de type : {values}.",
            "mimetypes": "Le champ {attribute} doit être un fichier de type : {values}.",
            "min": {
                "array": "Le tableau {attribute} doit contenir au moins {min} éléments.",
                "file": "La taille du fichier de {attribute} doit être supérieure à {min} kilo-octets.",
                "numeric": "La valeur de {attribute} doit être supérieure ou égale à {min}.",
                "string": "Le texte {attribute} doit contenir au moins {min} caractères."
            },
            "not_in": "Le champ {attribute} sélectionné n'est pas valide.",
            "not_regex": "Le format du champ {attribute} n'est pas valide.",
            "numeric": "Le champ {attribute} doit contenir un nombre.",
            "present": "Le champ {attribute} doit être présent.",
            "regex": "Le format du champ {attribute} est invalide.",
            "required": "Le champ {attribute} est obligatoire.",
            "required_if": "Le champ {attribute} est obligatoire quand la valeur de {other} est {value}.",
            "required_unless": "Le champ {attribute} est obligatoire sauf si {other} est {values}.",
            "required_with": "Le champ {attribute} est obligatoire quand {values} est présent.",
            "required_with_all": "Le champ {attribute} est obligatoire quand {values} est présent.",
            "required_without": "Le champ {attribute} est obligatoire quand {values} n'est pas présent.",
            "required_without_all": "Le champ {attribute} est requis quand aucun de {values} n'est présent.",
            "same": "Les champs {attribute} et {other} doivent être identiques.",
            "size": {
                "array": "Le tableau {attribute} doit contenir {size} éléments.",
                "file": "La taille du fichier de {attribute} doit être de {size} kilo-octets.",
                "numeric": "La valeur de {attribute} doit être {size}.",
                "string": "Le texte de {attribute} doit contenir {size} caractères."
            },
            "string": "Le champ {attribute} doit être une chaîne de caractères.",
            "timezone": "Le champ {attribute} doit être un fuseau horaire valide.",
            "unique": "La valeur du champ {attribute} est déjà utilisée.",
            "uploaded": "Le fichier du champ {attribute} n'a pu être téléchargé.",
            "url": "Le format de l'URL de {attribute} n'est pas valide.",
            "phone_ext": "Extension de téléphone",
            "password_not_match": "Les mots de passe ne correspondent pas!",
            "password_message": "Le mot de passe doit avoir au moins :",
            "password_length": "8 caractères",
            "password_num": "1 caractère numérique",
            "password_special": "1 caractère spécial dans (!@#$%^&*)"
        }
    },
    "ru": {
        "alerts": {
            "backend": {
                "users": {
                    "created": "Пользователь создан",
                    "updated": "Пользователь обновлён",
                    "deleted": "Пользователь удалён",
                    "bulk_destroyed": "Выбранные пользователи удалены",
                    "bulk_enabled": "Выбранные пользователи активированы",
                    "bulk_disabled": "Выбранные пользователи заблокированы"
                },
                "roles": {
                    "created": "Роль создана",
                    "updated": "Роль обновлена",
                    "deleted": "Роль удалена"
                },
                "metas": {
                    "created": "Метаданные созданы",
                    "updated": "Метаданные обновлены",
                    "deleted": "Метаданные удалены",
                    "bulk_destroyed": "Выбранные метаданные удалены"
                },
                "form_submissions": {
                    "deleted": "Представление удалёно",
                    "bulk_destroyed": "Выбранные представления удалены"
                },
                "form_settings": {
                    "created": "Настройка формы создана",
                    "updated": "Настройка формы обновлена",
                    "deleted": "Настройка формы удалена"
                },
                "redirections": {
                    "created": "Перенаправление создано",
                    "updated": "Перенаправление обновлено",
                    "deleted": "Перенаправление удалено",
                    "bulk_destroyed": "Выделенные перенаправления удалены",
                    "bulk_enabled": "Выделенные перенаправления включены",
                    "bulk_disabled": "Выделенные перенаправления отключены",
                    "file_imported": "Файл успешно импортирован"
                },
                "posts": {
                    "created": "Статья создана",
                    "updated": "Статья обновлена",
                    "deleted": "Статья удалена",
                    "bulk_destroyed": "Выбранные статьи удалены",
                    "bulk_published": "Выбранные статьи опубликованы",
                    "bulk_pending": "Выбранные статьи ожидают проверки",
                    "bulk_pinned": "Выбранные статьи закреплены",
                    "bulk_promoted": "Выбранные статьи выделены"
                },
                "actions": {
                    "invalid": "Неверное действие"
                }
            },
            "frontend": []
        },
        "auth": {
            "failed": "Имя пользователя или пароль указаны неверно.",
            "throttle": "Слишком много попыток входа. Пожалуйста, попробуйте снова через {seconds} секунд.",
            "socialite": {
                "unacceptable": "{provider} не является допустимым типом входа."
            }
        },
        "buttons": {
            "cancel": "Отмена",
            "save": "Сохранить",
            "close": "Закрыть",
            "create": "Создать",
            "delete": "Удалить",
            "confirm": "Подтвердить",
            "show": "Показать",
            "edit": "Изменить",
            "update": "Обновить",
            "view": "Смотреть",
            "preview": "Предпросмотр",
            "back": "Назад",
            "send": "Отправить",
            "login-as": "Войти как {name}",
            "apply": "Применить",
            "users": {
                "create": "Создать пользователя"
            },
            "roles": {
                "create": "Создать роль"
            },
            "metas": {
                "create": "Создать метаданные"
            },
            "form_settings": {
                "create": "Создать форму"
            },
            "redirections": {
                "create": "Создать перенаправление",
                "import": "Загрузить CSV"
            },
            "posts": {
                "create": "Создать статью",
                "save_and_publish": "Сохранить и опубликовать",
                "save_as_draft": "Сохранить как черновик"
            }
        },
        "exceptions": {
            "general": "Серверная ошибка",
            "unauthorized": "Недопустимое действие",
            "backend": {
                "users": {
                    "create": "Ошибка создания пользователя",
                    "update": "Ошибка обновления пользователя",
                    "delete": "Ошибка удаления пользователя",
                    "first_user_cannot_be_edited": "Вы не можете редактировать супер-администратора",
                    "first_user_cannot_be_disabled": "Супер-администратор не может быть отключён",
                    "first_user_cannot_be_destroyed": "Супер-администратор не может быть удалён",
                    "first_user_cannot_be_impersonated": "Нельзя войти под видом супер-администратора",
                    "cannot_set_superior_roles": "Вы не можете назначать роли, превосходящие ваши"
                },
                "roles": {
                    "create": "Ошибка создания роли",
                    "update": "Ошибка обновления роли",
                    "delete": "Ошибка удаления роли"
                },
                "metas": {
                    "create": "Ошибка создания метаданных",
                    "update": "Ошибка обновления метаданных",
                    "delete": "Ошибка удаления метаданных",
                    "already_exist": "Для этих языковых настроек уже есть метаданные"
                },
                "form_submissions": {
                    "create": "Ошибка создания представления",
                    "delete": "Ошибка удаления представления"
                },
                "form_settings": {
                    "create": "Ошибка создания настройки формы",
                    "update": "Ошибка обновления настройки формы",
                    "delete": "Ошибка удаления настройки формы",
                    "already_exist": "Уже существует настройка, связанная с этой формой"
                },
                "redirections": {
                    "create": "Ошибка создания перенаправления",
                    "update": "Ошибка обновления перенаправления",
                    "delete": "Ошибка удаления перенаправления",
                    "already_exist": "Для этого пути уже существует перенаправление"
                },
                "posts": {
                    "create": "Ошибка создания статьи",
                    "update": "Ошибка обновления статьи",
                    "save": "Ошибка сохранения статьи",
                    "delete": "Ошибка удаления статьи"
                },
                "courses": {
                    "create": "Error on course creation",
                    "update": "Error on course updating",
                    "save": "Error on course saving",
                    "delete": "Error on course deletion"
                }
            },
            "frontend": {
                "user": {
                    "email_taken": "Этот адрес электронной почты уже используется.",
                    "password_mismatch": "Это не ваш старый пароль.",
                    "delete_account": "Ошибка удаления аккаунта.",
                    "updating_disabled": "Редактирование аккаунта отключено."
                },
                "auth": {
                    "registration_disabled": "Регистрация отключена."
                }
            }
        },
        "forms": {
            "contact": {
                "display_name": "Форма обратной связи"
            }
        },
        "labels": {
            "language": "Язык",
            "actions": "Действия",
            "general": "Основные",
            "no_results": "Нет доступных результатов",
            "search": "Искать",
            "validate": "Применить",
            "choose_file": "Выберите файл",
            "no_file_chosen": "Файл не выбран",
            "are_you_sure": "Вы уверены?",
            "delete_image": "Удалить изображение",
            "yes": "Да",
            "no": "Нет",
            "add_new": "Добавить",
            "export": "Экспорт",
            "more_info": "Больше информации",
            "author": "Автор",
            "author_id": "ID автора",
            "last_access_at": "Последний доступ",
            "published_at": "Опубликовано",
            "created_at": "Создано",
            "updated_at": "Обновлено",
            "deleted_at": "Удалено",
            "no_value": "Нет значения",
            "upload_image": "Загрузить изображение",
            "anonymous": "Аноним",
            "all_rights_reserved": "Все права зарезервированы.",
            "with": "с",
            "by": "",
            "datatables": {
                "no_results": "Нет доступных результатов",
                "no_matched_results": "Не найдено подходящих результатов",
                "show_per_page": "Показать",
                "entries_per_page": "записей на страницу",
                "search": "Искать",
                "infos": "Показаны записи с {offset_start} по {offset_end} из {total}"
            },
            "morphs": {
                "post": "Статья, ID {id}",
                "user": "Пользователь, ID {id}"
            },
            "auth": {
                "disabled": "Ваш аккаунт заблокирован."
            },
            "http": {
                "403": {
                    "title": "Доступ запрещён",
                    "description": "Извините, но у вас нет прав доступа к этой странице."
                },
                "404": {
                    "title": "Ресурс не найден",
                    "description": "Извините, но этого ресурса не существует."
                },
                "500": {
                    "title": "Ошибка сервера",
                    "description": "Извините, но сервер столкнулся с ситуацией, которую он не может обработать. Мы исправим это как можно скорее."
                }
            },
            "localization": {
                "en": "Английский",
                "ru": "Русский",
                "fr": "Французский",
                "es": "Испанский",
                "de": "Немецкий",
                "zh": "Китайский",
                "ar": "Арабский",
                "pt": "Португальский"
            },
            "placeholders": {
                "route": "Выберите правильный внутренний маршрут",
                "tags": "Выберите или создайте метку"
            },
            "cookieconsent": {
                "message": "На этом сайте используются файлы cookie, чтобы вам было комфортнее им пользоваться.",
                "dismiss": "Понятно!"
            },
            "descriptions": {
                "allowed_image_types": "Доступные типы: PNG GIF JPG JPEG."
            },
            "user": {
                "dashboard": "Панель управления",
                "remember": "Запомнить",
                "login": "Вход",
                "logout": "Выход",
                "password_forgot": "Забыли пароль?",
                "send_password_link": "Отправить ссылку сброса пароля",
                "password_reset": "Сброс пароля",
                "register": "Регистрация",
                "space": "Моё пространство",
                "settings": "Настройки",
                "account": "Мой аккаунт",
                "profile": "Мой профиль",
                "avatar": "Аватар",
                "online": "В&nbsp;сети",
                "edit_profile": "Изменить мой профиль",
                "change_password": "Изменить мой пароль",
                "delete": "Удалить мой аккаунт",
                "administration": "Администрирование",
                "member_since": "Участник с {date}",
                "profile_updated": "Профиль успешно изменён.",
                "password_updated": "Пароль успешно изменён.",
                "super_admin": "Супер-администратор",
                "account_delete": "<p>Это действие полностью удалит вашу учетную запись с этого сайта, а также все связанные данные.</p>",
                "account_deleted": "Аккаунт успешно удалён",
                "titles": {
                    "space": "Моё пространство",
                    "account": "Мой аккаунт"
                }
            },
            "alerts": {
                "login_as": "Вы вошли как <strong>{name}</strong>, вы можете обратно войти как <a href=\"{route}\" data-turbolinks=\"false\">{admin}</a>."
            },
            "backend": {
                "dashboard": {
                    "new_posts": "Новые статьи",
                    "pending_posts": "Ожидающие одобрения статьи",
                    "published_posts": "Опубликованные статьи",
                    "active_users": "Активные пользователи",
                    "form_submissions": "Заполненные формы",
                    "last_posts": "Последние статьи",
                    "last_published_posts": "Последние публикации",
                    "last_pending_posts": "Последние ожидающие статьи",
                    "last_new_posts": "Последние новые статьи",
                    "all_posts": "Все статьи"
                },
                "new_menu": {
                    "post": "Новая статья",
                    "form_setting": "Новая форма",
                    "user": "Новый пользователь",
                    "role": "Новая роль",
                    "meta": "Новые метаданные",
                    "redirection": "Новое перенаправление"
                },
                "sidebar": {
                    "content": "Управление содержимым",
                    "forms": "Управление формами",
                    "access": "Управление доступом",
                    "seo": "Настройки SEO"
                },
                "titles": {
                    "dashboard": "Панель управления"
                },
                "users": {
                    "titles": {
                        "main": "Пользователи",
                        "index": "Список пользователей",
                        "create": "Создание пользователя",
                        "edit": "Изменение пользователя"
                    },
                    "actions": {
                        "destroy": "Удалить выбранных пользователей",
                        "enable": "Активировать выбранных пользователей",
                        "disable": "Отключить выбранных пользователей"
                    }
                },
                "roles": {
                    "titles": {
                        "main": "Роли",
                        "index": "Список ролей",
                        "create": "Создание роли",
                        "edit": "Изменение роли"
                    }
                },
                "metas": {
                    "titles": {
                        "main": "Метаданные",
                        "index": "Список метаданных",
                        "create": "Создание метаданных",
                        "edit": "Изменение метаданных"
                    },
                    "actions": {
                        "destroy": "Удалить выбранные метаданные"
                    }
                },
                "form_submissions": {
                    "titles": {
                        "main": "Заполнения",
                        "index": "Список заполнений форм",
                        "show": "Детали заполнения формы"
                    },
                    "actions": {
                        "destroy": "Удалить выбранные заполнения"
                    }
                },
                "form_settings": {
                    "titles": {
                        "main": "Формы",
                        "index": "Список форм",
                        "create": "Создание формы",
                        "edit": "Изменение формы"
                    },
                    "descriptions": {
                        "recipients": "Пример: 'webmaster@example.com' или 'sales@example.com,support@example.com'. Для указания нескольких получателей перечислите адреса через запятую.",
                        "message": "Сообщение для показа пользователю после отправки формы. Оставьте пустым, если ничего не надо показывать."
                    }
                },
                "redirections": {
                    "titles": {
                        "main": "Перенаправления",
                        "index": "Список перенаправлений",
                        "create": "Создание перенаправления",
                        "edit": "Изменение перенаправления"
                    },
                    "actions": {
                        "destroy": "Удалить выбранные перенаправления",
                        "enable": "Активировать выбранные перенаправления",
                        "disable": "Отключить выбранные перенаправления"
                    },
                    "types": {
                        "permanent": "Постоянное перенаправление (301)",
                        "temporary": "Временное перенаправление (302)"
                    },
                    "import": {
                        "title": "Импорт CSV-файла",
                        "label": "Выберите CSV-файл для импорта",
                        "description": "Файл должен содержать две колонки с заголовками «source» (исходный) и «target» (целевой), перенаправления будут постоянные по-умолчанию."
                    }
                },
                "posts": {
                    "statuses": {
                        "draft": "Черновик",
                        "pending": "Ожидающее",
                        "published": "Опубликованное"
                    },
                    "titles": {
                        "main": "Статьи",
                        "index": "Список статей",
                        "create": "Создать статью",
                        "edit": "Редактировать статью",
                        "publication": "Параметры публикации"
                    },
                    "descriptions": {
                        "meta_title": "Если оставить пустым, по-умолчанию будет заголовком статьи.",
                        "meta_description": "Если оставить пустым, по умолчанию будет отображаться резюме статьи."
                    },
                    "placeholders": {
                        "body": "Напишите своё содержимое...",
                        "meta_title": "Заголовок статьи",
                        "meta_description": "Резюме статьи"
                    },
                    "actions": {
                        "destroy": "Удалить выбранные статьи",
                        "publish": "Опубликовать выбранные статьи",
                        "pin": "Закрепить выбранные статьи",
                        "promote": "Выделить выбранные статьи"
                    }
                }
            },
            "frontend": {
                "titles": {
                    "home": "Начало",
                    "about": "О нас",
                    "contact": "Контакты",
                    "blog": "Блог",
                    "message_sent": "Сообщение отправлено",
                    "legal_mentions": "Юридическая информация",
                    "administration": "Администрирование"
                },
                "submissions": {
                    "message_sent": "<p>Ваше сообщение успешно отправлено.</p>"
                },
                "placeholders": {
                    "locale": "Выберите свой язык",
                    "timezone": "Выберите свой часовой пояс"
                },
                "blog": {
                    "published_at": "Опубликовано {date}",
                    "published_at_with_owner_linkable": "Опубликовано <a href=\"{link}\">{name}</a>, {date}",
                    "tags": "Метки"
                }
            }
        },
        "logs": {
            "backend": {
                "users": {
                    "created": "Пользователь ID {user} создан",
                    "updated": "Пользователь ID {user} обновлён",
                    "deleted": "Пользователь ID {user} удалён"
                },
                "form_submissions": {
                    "created": "Заполнение формы ID {form_submission} создано"
                }
            },
            "frontend": []
        },
        "mails": {
            "layout": {
                "hello": "Здравствуйте!",
                "regards": "С уважением",
                "trouble": "Если у вас не получается воспользоваться кнопкой {action}, скопируйте URL ниже и откройте его в удобном вам браузере:",
                "all_rights_reserved": "Все права зарезервированы."
            },
            "password_reset": {
                "subject": "Сброс пароля",
                "intro": "Вам отправлено это письмо, потому что мы получили запрос на сброс пароля для вашей учетной записи.",
                "action": "Сбросить пароль",
                "outro": "Если вы не запрашивали сброс пароля, просто проигнорируйте это письмо."
            },
            "contact": {
                "subject": "Новое сообщение с сайта",
                "new_contact": "Получено новое сообщение с сайта:"
            },
            "alert": {
                "subject": "[{app_name}] Ошибка приложения",
                "message": "На сервере произошёл сбой со следующим сообщением об ошибке: {message}",
                "trace": "Детали трассировки:"
            }
        },
        "pagination": {
            "previous": "&laquo;&nbsp;Назад",
            "next": "Вперёд&nbsp;&raquo;"
        },
        "passwords": {
            "password": "Пароль должен быть как минимум 6 символов длиной и совпадать с подтверждением.",
            "reset": "Ваш пароль изменён!",
            "sent": "Мы отправили вам на почту ссылку для сброса пароля!",
            "token": "Неверный токен сброса пароля.",
            "user": "У нас нет пользователя с таким адресом электронной почты."
        },
        "permissions": {
            "categories": {
                "blog": "Блог",
                "form": "Формы",
                "access": "Доступ",
                "seo": "SEO"
            },
            "access": {
                "backend": {
                    "display_name": "Доступ к бэк-офису",
                    "description": "Доступ к страницам администрирования."
                }
            },
            "view": {
                "form_settings": {
                    "display_name": "Просмотр настроек формы",
                    "description": "Можно просматривать настройки формы."
                },
                "form_submissions": {
                    "display_name": "Просмотр заполнений форм",
                    "description": "Пожно просматривать данные заполнения форм."
                },
                "users": {
                    "display_name": "Просмотр пользователей",
                    "description": "Можно видеть список пользователей."
                },
                "roles": {
                    "display_name": "Просмотр ролей",
                    "description": "Можно видеть список ролей."
                },
                "metas": {
                    "display_name": "Просмотр метаданных",
                    "description": "Можно видеть метаданные."
                },
                "redirections": {
                    "display_name": "Просмотр перенаправлений",
                    "description": "Можно видеть список перенаправлений."
                },
                "posts": {
                    "display_name": "Просмотр всех статей",
                    "description": "Можно видеть все статьи."
                },
                "own": {
                    "posts": {
                        "display_name": "Просмотр своих статей",
                        "description": "Можно видеть свои статьи."
                    }
                }
            },
            "create": {
                "form_settings": {
                    "display_name": "Создание форм",
                    "description": "Можно создавать новые формы."
                },
                "users": {
                    "display_name": "Создание пользователей",
                    "description": "Можно создавать новых пользователей."
                },
                "roles": {
                    "display_name": "Создание ролей",
                    "description": "Можно создавать новые роли."
                },
                "metas": {
                    "display_name": "Создание метаданных",
                    "description": "Можно создавать новые метаданные."
                },
                "redirections": {
                    "display_name": "Создание перенеправлений",
                    "description": "Можно создавать новые перенаправления."
                },
                "posts": {
                    "display_name": "Создание статей",
                    "description": "Можно создавать новые статьи."
                }
            },
            "edit": {
                "form_settings": {
                    "display_name": "Правка форм",
                    "description": "Можно изменять данные форм."
                },
                "users": {
                    "display_name": "Правка пользователей",
                    "description": "Можно изменять данные пользователей."
                },
                "roles": {
                    "display_name": "Правка ролей",
                    "description": "Можно изменять данные ролей."
                },
                "metas": {
                    "display_name": "Правка метаданных",
                    "description": "Можно изменять данные метаданных."
                },
                "redirections": {
                    "display_name": "Правка перенаправлений",
                    "description": "Можно изменять данные перенаправлений."
                },
                "posts": {
                    "display_name": "Правка всех статей",
                    "description": "Можно изменять данные всех статей."
                },
                "own": {
                    "posts": {
                        "display_name": "Правка своих статей",
                        "description": "Можно изменять данные своих статей."
                    }
                }
            },
            "delete": {
                "form_settings": {
                    "display_name": "Удаление форм",
                    "description": "Можно удалять формы."
                },
                "form_submissions": {
                    "display_name": "Удаление заполнений форм",
                    "description": "Можно удалять данные заполнения форм."
                },
                "users": {
                    "display_name": "Удаление пользователей",
                    "description": "Можно удалять пользователей."
                },
                "roles": {
                    "display_name": "Удаление ролей",
                    "description": "Можно удалять роли."
                },
                "metas": {
                    "display_name": "Удаление метаданных",
                    "description": "Можно удалять метаданные."
                },
                "redirections": {
                    "display_name": "Удаление перенаправлений",
                    "description": "Можно удалять перенаправления."
                },
                "posts": {
                    "display_name": "Удаление всех статьи",
                    "description": "Можно удалять все статьи."
                },
                "own": {
                    "posts": {
                        "display_name": "Удаление своих статей",
                        "description": "Можно удалять свои статьи."
                    }
                }
            },
            "publish": {
                "posts": {
                    "display_name": "Публикация статей",
                    "description": "Можно управлять публикацией статей."
                }
            },
            "impersonate": {
                "display_name": "Войти под видом пользователя",
                "description": "Можно войти под видом другого пользователя. Полезно для тестирования."
            }
        },
        "routes": {
            "home": "home",
            "about": "about",
            "contact": "contact",
            "contact-sent": "contact-sent",
            "legal-mentions": "legal-mentions",
            "redactors": "blog/redactors/{user}"
        },
        "validation": {
            "accepted": "{attribute} должен быть принят.",
            "active_url": "{attribute} является некорректным URL.",
            "after": "{attribute} должен быть датой после {date}.",
            "after_or_equal": "{attribute} должен быть датой после или равной {date}.",
            "alpha": "{attribute} может содержать только буквы.",
            "alpha_dash": "{attribute} может содержать только буквы, цифры и дефисы.",
            "alpha_num": "{attribute} может содержать только буквы и цифры.",
            "array": "{attribute} должен быть списком.",
            "before": "{attribute} должен быть датой до {date}.",
            "before_or_equal": "{attribute} должен быть датой до или равной {date}.",
            "between": {
                "numeric": "{attribute} должен быть между {min} и {max}.",
                "file": "{attribute} должен быть между {min} и {max} kilobytes.",
                "string": "{attribute} должен быть между {min} и {max} characters.",
                "array": "{attribute} должен содержать от {min} до {max} элементов."
            },
            "boolean": "{attribute} должен быть «истина» или «ложь».",
            "confirmed": "{attribute} подтверждение не совпадает.",
            "date": "{attribute} не является корректной датой.",
            "date_format": "{attribute} не совпадает с форматом {format}.",
            "different": "{attribute} и {other} должны различаться.",
            "digits": "{attribute} должен содержать {digits} цифр.",
            "digits_between": "{attribute} должен быть от {min} до {max} цифр.",
            "dimensions": "{attribute} содержит неверные размеры изображения.",
            "distinct": "{attribute} содержит дублирующее значение.",
            "email": "{attribute} должен быть корректным почтовым адресом.",
            "exists": "выбранный {attribute} неверен.",
            "file": "{attribute} должен быть файлом.",
            "filled": "{attribute} должен быть заполнен.",
            "image": "{attribute} должен быть изображением.",
            "in": "выбранный {attribute} неверен.",
            "in_array": "{attribute} отсутствует в {other}.",
            "integer": "{attribute} должен быть целым.",
            "ip": "{attribute} должен быть корректным IP-адресом.",
            "ipv4": "{attribute} должен быть корректным IPv4-адресом.",
            "ipv6": "{attribute} должен быть корректным IPv6-адресом.",
            "json": "{attribute} должен быть корректной JSON-строкой.",
            "max": {
                "numeric": "{attribute} не может быть более чем {max}.",
                "file": "{attribute} не может быть более чем {max} Кб.",
                "string": "{attribute} не может быть более чем {max} символов.",
                "array": "{attribute} не может содержать более чем {max} значений."
            },
            "mimes": "{attribute} должен быть файлом типа {values}.",
            "mimetypes": "{attribute} должен быть файлом типа {values}.",
            "min": {
                "numeric": "{attribute} должен быть не менее чем {min}.",
                "file": "{attribute} должен быть не менее чем {min} Кб.",
                "string": "{attribute} должен содержать не менее чем {min} символов.",
                "array": "{attribute} должен содержать не менее чем {min} значений."
            },
            "not_in": "выбранный {attribute} неверен.",
            "not_regex": "формат {attribute} неверен.",
            "numeric": "{attribute} должен быть числом.",
            "present": "поле {attribute} должно присутствовать.",
            "regex": "формат {attribute} неверен.",
            "required": "поле {attribute} обязательно.",
            "required_if": "поле {attribute} обязательно, когда {other} равно {value}.",
            "required_unless": "поле {attribute} обязательно, если {other} в {values}.",
            "required_with": "поле {attribute} обязательно, если присутствуют {values}.",
            "required_with_all": "поле {attribute} обязательно, если присутствуют {values}.",
            "required_without": "поле {attribute} обязательно, если отсутствуют {values}.",
            "required_without_all": "поле {attribute} обязательно, если нет ничего из {values}.",
            "same": "{attribute} и {other} должны совпадать.",
            "size": {
                "numeric": "{attribute} должно быть {size}.",
                "file": "{attribute} должно быть {size} Кб.",
                "string": "{attribute} должно быть {size} символов.",
                "array": "{attribute} должно содержать {size} элементов."
            },
            "string": "{attribute} должно быть строкой.",
            "timezone": "{attribute} должно быть корректным часовым поясом.",
            "unique": "{attribute} уже есть в базе.",
            "uploaded": "{attribute} не получилось загрузить.",
            "url": "{attribute} имеет неверный формат.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": {
                "name": "Имя",
                "display_name": "Отображаемое имя",
                "username": "Псевдоним",
                "email": "E-mail",
                "first_name": "Имя",
                "last_name": "Фамилия",
                "password": "Пароль",
                "password_confirmation": "Подтверждение пароля",
                "old_password": "Старый пароль",
                "new_password": "Новый пароль",
                "new_password_confirmation": "Подтверждение нового пароля",
                "postal_code": "Почтовый индекс",
                "city": "Город",
                "country": "Страна",
                "address": "Адрес",
                "phone": "Телефон",
                "mobile": "Мобильный",
                "age": "Возраст",
                "sex": "Пол",
                "gender": "Пол",
                "day": "День",
                "month": "Месяц",
                "year": "Год",
                "hour": "Час",
                "minute": "Минута",
                "second": "Секунда",
                "title": "Заголовок",
                "content": "Содержание",
                "description": "Описание",
                "summary": "Резюме",
                "excerpt": "Отрывок",
                "date": "Дата",
                "time": "Время",
                "available": "Доступный",
                "size": "Размер",
                "roles": "Роли",
                "permissions": "Права",
                "active": "Активный",
                "message": "Сообщение",
                "g-recaptcha-response": "Защитный код",
                "locale": "Локализация",
                "route": "Маршрут",
                "url": "URL",
                "form_type": "Тип формы",
                "form_data": "Данные формы",
                "recipients": "Получатели",
                "source_path": "Исходный путь",
                "target_path": "Целевой путь",
                "redirect_type": "Тип переадресации",
                "timezone": "Часовой пояс",
                "order": "Порядок показа",
                "image": "Изображение",
                "status": "Статус",
                "pinned": "Закреплено",
                "promoted": "Выделено",
                "body": "Основная часть",
                "tags": "Метки",
                "published_at": "Опубликовано",
                "unpublished_at": "Снято с публикации",
                "metable_type": "Сущность"
            }
        }
    }
}
