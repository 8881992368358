<template>
  <div :class="className">
    <div class="nav-item dropdown">
      <span class="dropdown-filter-title" v-if="title">{{ title }}</span>
      <a
        class="dropdown-toggle dropdown-filter-select"
        href="#"
        id="dropdown-filter"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {{ filterTitle }}
      </a>
      <div
        class="dropdown-menu dropdown-menu_custom"
        aria-labelledby="dropdown-filter"
      >
        <div
          :class="['dropdown-item', { 'item-checkbox': item.checkbox }]"
          v-for="(item, index) in filterOptions"
          :key="index"
          @click="onItemSelect(item)"
        >
          <div
            class="custom-control custom-checkbox mr-sm-2"
            v-if="item.checkbox"
          >
            <input
              type="checkbox"
              class="custom-control-input"
              :id="`filter-checkbox-${index}`"
            />
            <label
              class="custom-control-label"
              :for="`filter-checkbox-${index}`"
            >
              {{ item.label }}
            </label>
          </div>

          <span v-else>{{ item.label }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropdownFilter',
  props: {
    filterOptions: {
      type: Array,
      default: function() {
        return []
      }
    },
    filterKey: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    dropdownTitle: {
      type: String,
      default: 'All'
    },
    type: {
      type: String,
      default: 'secondary'
    },
    clearStatus: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selectedItem: null,
      className: `dropdown-filter-${this.type} py-2`
    }
  },
  computed: {
    filterTitle() {
      return this.selectedItem
        ? `${this.selectedItem.label.substring(0, 15)}...`
        : this.dropdownTitle
    }
  },
  watch: {
    clearStatus: function(val) {
      if (val == 'clear') {
        this.selectedItem = null
        this.$emit('filter-cleared')
      }
    }
  },
  methods: {
    onItemSelect(item) {
      this.selectedItem = item
      this.$emit('filter-selected', { key: this.filterKey, value: item.value })
    }
  }
}
</script>
