export default {
  scrolTo() {
    $('html, body').animate(
      { scrollTop: $('#our-services').offset().top },
      1500
    )
  },
  openSignIn() {
    const signUp = $('#sign-up-panel')
    const signIn = $('#sign-in-panel')
    const signForm = $('.sign')
    const restoreForm = $('.res')
    $(signUp).removeClass('active show')
    $(restoreForm).removeClass('active show')
    $('#m-signin-signup .nav-link').removeClass('active show')
    $('#m-signin-signup .nav-link[href="#sign-in-panel"]').addClass(
      'active show'
    )
    $(signForm).addClass('active show')
    $(signIn).addClass('active show')
    const url = new URL(window.location)
    url.hash = ''
    history.replaceState(null, document.title, url)
  },
  openSignUp() {
    const signUp = $('#sign-up-panel')
    const signIn = $('#sign-in-panel')
    const signForm = $('.sign')
    const restoreForm = $('.res')
    $(signUp).addClass('active show')
    $(restoreForm).removeClass('active show')
    $('#m-signin-signup .nav-link').addClass('active show')
    $('#m-signin-signup .nav-link[href="#sign-in-panel"]').removeClass(
      'active show'
    )
    $(signForm).addClass('active show')
    $(signIn).removeClass('active show')
    const url = new URL(window.location)
    url.hash = ''
    history.replaceState(null, document.title, url)
  }
}
