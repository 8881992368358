<template>
  <div v-if="serviceProvider" class="d-flex flex-column w-100 person-profile">
    <div class="text-center profile card-container-first row no-gutters">
      <div class="row info-back w-100 pt-1 pb-0">
        <a href="/available-services#nav-service-provider" class="router-link-exact-active open active">
          <i class="fe fe-chevron-left"></i> <span>Back</span>
        </a>
      </div>
      <div class="col-md-7 px-0">
        <div class="box col-left h-auto mt-0 text-left">
          <div class="row no-gutters">
            <div class="col">
              <h4 class="block-title">{{ serviceProvider.ORG_NAME }}</h4>
              <p class="text-medium text-secondary mt-3">
                {{ serviceProvider.DESCRIPTION_SHORT }}
              </p>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col">
              <h4 class="block-title">Located in:</h4>
              <p class="text-medium text-secondary mt-3">
                {{ serviceProvider.LOCATED_IN_CM }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-5 d-flex flex-column">
        <div class="box col-right mt-0 text-left">
          <h4 class="block-title py-3 mt-0">Contacts</h4>
          <p class="text-medium text-gray d-block">
            <i class="fe fe-phone pr-3"></i> {{ serviceProvider.OFFICE_PHONE }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'ServiceProvider',
    props: {
      id: {
        type: String,
        default: null
      }
    },
    data() {
      return {
        serviceProvider: null,
        resourceRoute: 'service_provider'
      }
    },
    computed: {
      selectedService: function() {
        return this.person.servicesProvided[this.selectedServiceIndex]
      }
    },
    created: function(){
      this.getServiceProviders()
    },
    methods: {
      async getServiceProviders() {
        try {
          let { data } = await axios.get(`/api/service_providers?NUM=` + this.id)

          let providers = data.recordset ? data.recordset : []

          if(providers.length > 0){
            this.serviceProvider = providers[0]
          }
        } catch (e) {
          this.$app.error(e)
          return []
        }
      },
    }
  }
</script>
