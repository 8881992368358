import '../fontawesome'
import 'slick-carousel'
import PerfectScrollbar from 'perfect-scrollbar'
import intlTelInput from 'intl-tel-input'
import 'pwstrength-bootstrap/dist/pwstrength-bootstrap'
import swal from 'sweetalert2'
import WebFont from 'webfontloader'
import Turbolinks from 'turbolinks'
import { createLocales } from '../vue-i18n-config'
import utils from './utils'

/**
 * JS Settings App
 */
let jsonSettings = document.querySelector(
  '[data-settings-selector="settings-json"]'
)
window.settings = jsonSettings ? JSON.parse(jsonSettings.textContent) : {}

window.settings.appName = process.env.APP_NAME
window.settings.adminPathName = process.env.APP_ADMIN_PATH
window.settings.editorName = process.env.EDITOR_NAME
window.settings.editorSiteUrl = process.env.EDITOR_SITE_URL
window.settings.blogEnabled = process.env.BLOG_ENABLED

window.swal = swal
window.locale = $('html').attr('lang')

const i18n = createLocales(window.locale)

export default createApp => {
  Turbolinks.start()

  /**
   * Font
   */
  WebFont.load({
    custom: {
      families: ['Jost'],
      urls: ['https://indestructibletype.com/fonts/Jost.css']
    }
  })

  /**
   * Cookie Consent
   */
  window.addEventListener('load', () => {
    window.cookieconsent.initialise({
      palette: {
        popup: {
          background: '#fff',
          text: '#777'
        },
        button: {
          background: '#3097d1',
          text: '#ffffff'
        }
      },
      showLink: false,
      theme: 'edgeless',
      content: {
        message: window.settings.cookieconsent.message,
        dismiss: window.settings.cookieconsent.dismiss
      }
    })
  })

  document.addEventListener('turbolinks:load', () => {
    $(document).ready(function() {
      $('body').bootstrapMaterialDesign()
    })

    /**
     * Vue Mounting
     */
    if (document.getElementById('app') !== null) {
      const { app } = createApp()
      app.$mount('#app')
    }

    /**
     * Tel Input
     */
    ;[...document.querySelectorAll('input[type="tel"]')].forEach(input => {
      intlTelInput(input)
    })

    /**
     * Bind all bootstrap tooltips
     */
    $('[data-toggle="tooltip"]').tooltip()

    /**
     * Bind all bootstrap popovers
     */
    $('[data-toggle="popover"]').popover()

    /**
     * Slick
     */
    $('[data-toggle="slider"]')
      .not('.slick-initialized')
      .slick({
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        centerMode: true,
        // speed: 300,
        // slidesToShow: 5,
        // slidesToScroll: 1,
        // centerMode: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      })

    /**
     * Bind all swal confirm buttons
     */
    $('[data-toggle="confirm"]').click(e => {
      e.preventDefault()

      window
        .swal({
          title: $(e.currentTarget).attr('data-trans-title'),
          type: 'warning',
          showCancelButton: true,
          cancelButtonText: $(e.currentTarget).attr('data-trans-button-cancel'),
          confirmButtonColor: '#dd4b39',
          confirmButtonText: $(e.currentTarget).attr(
            'data-trans-button-confirm'
          )
        })
        .then(result => {
          if (result.value) {
            $(e.target)
              .closest('form')
              .submit()
          }
        })
    })

    $('[data-toggle="password-strength-meter"]').pwstrength({
      ui: {
        bootstrap4: true
      }
    })

    /**
     * Bootstrap tabs nav specific hash manager
     */
    let hash = document.location.hash
    let tabanchor = $('.nav-tabs a:first')

    if (hash) {
      tabanchor = $(`.nav-tabs a[href="${hash}"]`)
    }

    tabanchor.tab('show')

    $('a[data-toggle="tab"]').on('show.bs.tab', e => {
      window.location.hash = e.target.hash
    })

    $('#sign-in-modal .forgot-pass-link').on('click', function(e) {
      e.preventDefault()
      $('#m-signin-signup').removeClass('in show active')
      $('#m-reset-password').addClass('in show active')
    })

    $('#sign-in-modal .forgot-pass-link-back').on('click', function(e) {
      e.preventDefault()
      $('#m-reset-password').removeClass('in show active')
      $('#m-signin-signup').addClass('in show active')
    })

    $('#service-providers-section .collapse').on(
      'hide.bs.collapse',
      function() {
        $(this)
          .parents('.tab-row-wrapper')
          .removeClass('active')
      }
    )

    $('#service-providers-section .collapse').on(
      'show.bs.collapse',
      function() {
        $(this)
          .parents('.tab-row-wrapper')
          .addClass('active')
      }
    )

    $('a[href="#our-services"]').click(function(e) {
      e.preventDefault()
      utils.scrolTo()
    })

    $('.nav-link-sign-in').click(function(e) {
      e.preventDefault()
      utils.openSignIn()
    })

    $('a[data-popup]').click(function(e) {
      e.preventDefault()
      utils.openSignUp()
    })

    if ($('#service-providers-section .spd-table').length > 0) {
      new PerfectScrollbar('#service-providers-section .spd-table', {
        maxScrollbarLength: 125
      })
    }

    $('a.btn_more').click(function() {
      if($(this).hasClass('collapsed')) {
        $(this).text(i18n.t('buttons.view_less'))
      } else {
        $(this).text(i18n.t('buttons.view_all'))
      }
    })

  })
}
