<template>
  <div class="modal-content">
    <div class="modal-body p-0">
      <div class="container">
        <div class="row" v-if="!formSubmitted">
          <div class="col-md-6">
            <div class="modal-left-box display-flex justify-content-center">
              <div class="col-12 text-center">
                <img
                  src="/images/logos/logo.png"
                  alt="LASI"
                  class="modal-image"
                />
              </div>
              <div class="col-12 text-center create-account">
                <h4>{{ $t('labels.user.why_create_account') }}</h4>
                <ul class="fa-ul text-left">
                  <li>
                    <span class="fa-li"><i class="fas fa-check"></i></span
                    >{{ $t('labels.user.why_create_account_line1') }}
                  </li>
                  <li>
                    <span class="fa-li"><i class="fas fa-check"></i></span
                    >{{ $t('labels.user.why_create_account_line2') }}
                  </li>
                  <li>
                    <span class="fa-li"><i class="fas fa-check"></i></span
                    >{{ $t('labels.user.why_create_account_line3') }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <a data-dismiss="modal" class="close-modal-link">
              <i class="fas fa-times"></i>
            </a>
            <div class="modal-right-box">
              <h3 class="text-center">
                {{ $t('labels.user.create_account') }}
              </h3>
              <div>
                <b-form @submit="onSubmit" id="sign-up-form">
                  <div class="form-row">
                    <div class="col">
                      <!-- First name -->
                      <div class="form-group">
                        <label for="first_name">{{
                          $t('validation.attributes.first_name')
                        }}</label>
                        <b-form-input
                          id="sgu_first_name"
                          required="required"
                          name="first_name"
                          v-model="model.first_name"
                          type="text"
                          :placeholder="$t('validation.attributes.first_name')"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="col">
                      <!-- Last name -->
                      <div class="form-group">
                        <label for="last_name">{{
                          $t('validation.attributes.last_name')
                        }}</label>
                        <b-form-input
                          id="sgu_last_name"
                          required="required"
                          name="last_name"
                          v-model="model.last_name"
                          type="text"
                          :placeholder="$t('validation.attributes.last_name')"
                        ></b-form-input>
                        <b-form-feedback>{{
                          feedback('first_name')
                        }}</b-form-feedback>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="email">{{
                      $t('validation.attributes.email_address')
                    }}</label>
                    <b-form-input
                      id="sgu_email"
                      required="required"
                      name="email"
                      v-model="model.email"
                      type="email"
                      :placeholder="$t('validation.attributes.email_address')"
                    ></b-form-input>
                    <b-form-feedback>{{ feedback('email') }}</b-form-feedback>
                  </div>
                  <div class="form-row">
                    <div class="col">
                      <div class="form-group">
                        <label for="password">{{
                          $t('validation.attributes.password')
                        }}</label>
                        <div class="input-group" id="show_hide_password">
                          <b-form-input
                            id="sgu_password"
                            required="required"
                            name="password"
                            :state="state('password')"
                            class="form-control"
                            v-model="model.password"
                            :type="passwordType"
                            :placeholder="$t('validation.attributes.password')"
                          >
                          </b-form-input>
                          <div class="input-group-addon">
                            <span @click="togglePassword()"
                              ><i :class="passwordIcon" aria-hidden="true"></i
                            ></span>
                          </div>
                        </div>
                        <password-meter
                          :password="model.password"
                          @score="onScore"
                        ></password-meter>
                        <span
                          
                          >{{ $t('validation.password_message') }}</span
                        >
                        <span>
                          <ul class="message-list">
                            <li v-if="!passLength" class="item-not-pass">
                              <i class="fa fa-times-circle" aria-hidden="true"></i>
                              {{ $t('validation.password_length') }}
                            </li>
                            <li v-else class="item-pass">
                              <i class="fa fa-check-circle" aria-hidden="true"></i>
                              {{ $t('validation.password_length') }}
                            </li>
                            <li v-if="!passNum" class="item-not-pass">
                              <i class="fa fa-times-circle" aria-hidden="true"></i>
                              {{ $t('validation.password_num') }}
                            </li>
                            <li v-else class="item-pass">
                              <i class="fa fa-check-circle" aria-hidden="true"></i>
                              {{ $t('validation.password_num') }}
                            </li>
                            <li v-if="!passSpecial" class="item-not-pass">
                              <i class="fa fa-times-circle" aria-hidden="true"></i>
                              {{ $t('validation.password_special') }}
                            </li>
                            <li v-else class="item-pass">
                              <i class="fa fa-check-circle" aria-hidden="true"></i>
                              {{ $t('validation.password_special') }}
                            </li>
                          </ul>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col">
                      <div class="form-group">
                        <label for="password_confirmation">{{
                          $t('validation.attributes.password_confirmation')
                        }}</label>
                        <div class="input-group" id="show_hide_password">
                          <b-form-input
                            id="sgu_password_confirmation"
                            required="required"
                            name="password_confirmation"
                            :state="passwordMatch"
                            class="form-control"
                            v-model="model.password_confirmation"
                            :type="passwordConfirmType"
                            :placeholder="
                              $t('validation.attributes.password_confirmation')
                            "
                          ></b-form-input>
                          <div class="input-group-addon">
                            <span @click="togglePasswordConfirm()"
                              ><i
                                :class="passwordConfirmIcon"
                                aria-hidden="true"
                              ></i
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col">
                      <div
                        class="form-group display-flex justify-content-center"
                      >
                        <input
                          :disabled="!passwordMatch"
                          type="submit"
                          form="sign-up-form"
                          class="btn btn-outline-primary"
                          :value="$t('labels.user.create_account')"
                        />
                      </div>
                    </div>
                  </div>
                </b-form>
              </div>
              <hr />
              <p class="text-center">
                {{ $t('labels.user.already_have_account') }}<br />
                <a
                  data-dismiss="modal"
                  class="sign-in-link"
                  href="#sign-in-modal"
                  data-toggle="modal"
                  >{{ $t('labels.user.sign_in') }}</a
                >
              </p>
            </div>
          </div>
        </div>
        <div class="row signup-loader" v-else>
          <div class="col-12 mx-auto text-center lasi-logo">
            <img src="/images/logos/logo.png" alt="LASI" class="modal-image" />
          </div>
          <div class="setting-up col-md-8 mx-auto text-center">
            <b-spinner small></b-spinner>
            <span>{{ $t('labels.user.setting_up_account') }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SignUpForm',
  props: {
    agency: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      model: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        password_confirmation: '',
        agency_id: this.agency,
      },
      validation: {},
      passwordValue: null,
      score: null,
      passwordMessage: '',
      passwordConfirmationMessage: '',
      isPasswordValid: false,
      passwordRegEx: RegExp('^(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})'),
      passwordNumRegEx: RegExp('^(?=.*[0-9])'),
      passwordSpecialRegEx: RegExp('^(?=.*[!@#\$%\^&\*])'),
      passwordNotValid: true,
      passwordMatch: false,
      passwordIcon: 'fa fa-eye-slash',
      passwordConfirmIcon: 'fa fa-eye-slash',
      passwordType: 'password',
      passwordConfirmType: 'password',
      formSubmitted: false,
      passLength: false,
      passNum: false,
      passSpecial: false
    }
  },
  methods: {
    feedback(name) {
      if (this.state(name)) {
        return this.validation.errors[name][0]
      }
    },
    state(name) {
      return this.validation.errors !== undefined &&
        this.validation.errors.hasOwnProperty(name)
        ? 'invalid'
        : null
    },
    onSubmit(evt) {
      this.formSubmitted = true
      evt.preventDefault()
      window.axios
        .post(window.route('register'), this.model)
        .then(response => {
          location.href = window.location.origin + '/' + this.$i18n.locale + '/app'
          window.localStorage.setItem('loggedIn', 'true')
        })
        .catch(e => {
          if (e.response.status === 422) {
            this.validation = e.response.data
            this.formSubmitted = false
            return
          }
        })
    },
    // Method to set score of the current password
    // score range 0-4 (weak to secure)
    onScore({ score }) {
      this.score = score
      this.performValidationCheck()
    },
    // checks for if all password validation is met and
    // create account button can be enabled
    performValidationCheck() {
      // check for minimum password requirements
      this.isPasswordValid = this.passwordRegEx.test(this.model.password)

      if (this.isPasswordValid && this.score > 0) {
        this.passwordMessage = ''
        if (
          this.model.password != '' &&
          this.model.password == this.model.password_confirmation
        ) {
          // clear messages
          this.passwordConfirmationMessage = ''
          // set password match to activate create account button
          this.passwordMatch = true
        } else {
          // set password confirmation message otherwise
          this.passwordConfirmationMessage = this.$t(
            'validation.password_not_match'
          )
          this.passwordMatch = false
        }
      }
      if(this.model.password.length > 7)
      {
        this.passLength = true
      }
      else
      {
        this.passLength = false
      }
      this.passNum = this.passwordNumRegEx.test(this.model.password)
      this.passSpecial = this.passwordSpecialRegEx.test(this.model.password)
      this.passwordMessage = this.$t('validation.password_message')
    },
    togglePassword() {
      if (this.passwordIcon == 'fa fa-eye-slash') {
        // toggle to show password
        this.passwordIcon = 'fa fa-eye'
        this.passwordType = 'text'
      } else {
        // toggle to hide password
        this.passwordIcon = 'fa fa-eye-slash'
        this.passwordType = 'password'
      }
    },
    togglePasswordConfirm() {
      if (this.passwordConfirmIcon == 'fa fa-eye-slash') {
        // toggle to show password
        this.passwordConfirmIcon = 'fa fa-eye'
        this.passwordConfirmType = 'text'
      } else {
        // toggle to hide password
        this.passwordConfirmIcon = 'fa fa-eye-slash'
        this.passwordConfirmType = 'password'
      }
    }
  }
}
</script>

<style scoped>
.modal-body i{
  color: #333333;
}

.create-account i{
  color: red;
}

.input-group-addon {
  position: absolute;
  right: 5px;
  top: 15px;
  cursor: pointer;
  z-index: 1000;
}

.setting-up {
  color: #94d343;
  padding-top: 15px;
}

.signup-loader {
  padding-top: 50px;
  padding-bottom: 50px;
}

.item-not-pass, .item-not-pass i {
  color:red !important;
  list-style:none;
}

.item-pass, .item-pass i{
  color:green !important;
  list-style:none;
}

.message-list {
  padding-inline-start: 0;
}
</style>
