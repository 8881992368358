<template>
  <section id="service-providers-section" class="">
    <div class="container-fluid">
      <div class="row">
        <div class="col col-sm-12 pr-md-0 left-box">
          <h2 class="h1">
            {{ $t('static.frontend.pages.services.top_section.tab1') }}
          </h2>
          <div class="spd-box bg-white">
            <div class="spd-filter d-flex justify-content-between">
              <div class="form-group custom-select-field">
                <label>{{ $t('labels.location') }}</label>
                <div class="select-wrapper">
                  <select v-model="area" class="form-control">
                    <option
                      v-for="(areaOption, index) in areaOptions"
                      :key="index"
                      :value="areaOption.value"
                    >
                      {{ areaOption.label }}
                    </option>
                  </select>
                </div>
              </div>
              <div
                class="form-group custom-select-field bmd-form-group dropdown-with-checkboxes"
              >
                <DropdownWithCheckboxes
                  owner-filter-options="spCategories"
                  :filter-options="spCategoriesOptions"
                  :key="`category-${renderKeySpCategories}`"
                  :filter-title="$t('labels.type_of_services')"
                  :index-is-id="true"
                  @select-filter-options="selectFilterOptions($event)"
                  :selected-filter-options="
                    listOfValueByKey(spCategories, 'spCategories', 'value')
                  "
                  :dropdown-title="$t('labels.type_of_services')"
                ></DropdownWithCheckboxes>
              </div>
              <div class="selected-filter-options">
                <span
                  :key="`spCategories-${index}`"
                  v-for="(item, index) in spCategories"
                >
                  {{ item.value }}
                  <i
                    @click="
                      removeSelectedFilterOption(item.option, 'spCategories')
                    "
                  >
                    &times;
                  </i>
                </span>
              </div>
              <div class="results">
                <span>{{ $t('labels.results') }}</span>
                <span class="text-primary">{{ serviceProviders.length }}</span>
              </div>
            </div>
            <div class="spd-table">
              <div
                v-for="(serviceProvider, index) in serviceProviders"
                :key="index"
                class="tab-row-wrapper"
              >
                <div class="tab-row d-flex justify-content-between">
                  <a :href="`/service-provider/${serviceProvider.NUM}`">
                    <span class="text-black">{{
                      serviceProvider.ORG_NAME
                    }}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col col-sm-12 p-0 right-box bg-white">
          <div id="map" class="map-box">
            <google-maps
              ref="GoogleMaps"
              :google-api-key="googleApiKey"
              :geo="GeoLocation"
              width="100%"
              height="750px"
            ></google-maps>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import GoogleMaps from '../../backend/components/Plugins/GoogleMaps'
import DropdownWithCheckboxes from './DropdownWithCheckboxes'

export default {
  name: 'ProvidersDirectory',
  components: {
    GoogleMaps,
    DropdownWithCheckboxes,
  },
  props: {
    googleApiKey: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      pageTitle: 'Service Providers Directory',
      spCategories: [],
      spCategoriesOptions: {},
      renderKeySpCategories: 0,
      GeoLocation: {
        lat: 45.42153,
        lng: -75.697193,
      },
      scrollSettings: {
        maxScrollbarLength: 200,
        minScrollbarLength: 40,
        suppressScrollX: true,
        wheelPropagation: false,
        interceptRailY: (styles) => ({ ...styles, height: 0 }),
      },
      serviceProviders: [],
      area: 50,
      areaOptions: [
        {
          label: '2 km',
          value: 2,
        },
        {
          label: '5 km',
          value: 5,
        },
        {
          label: '10 km',
          value: 10,
        },
        {
          label: '25 km',
          value: 25,
        },
        {
          label: '50 km',
          value: 50,
        },
        {
          label: '75 km',
          value: 75,
        },
        {
          label: '100 km',
          value: 100,
        },
      ],
      fields: [
        {
          key: 'ORG_NAME',
          label: 'Name',
        },
        {
          key: 'LOCATED_IN_CM',
          label: 'Address',
        },
      ],
    }
  },
  watch: {
    spCategories(val) {
      this.getServiceProviders()
    },
    area(val) {
      this.getServiceProviders()
    },
  },
  created() {
    // this.getGeoLocation()
    // this.getCategories()
    this.getServiceProviders()
  },
  methods: {
    async getServiceProviders() {
      if (this.area) {
        let GHID = this.spCategories.map(function (item) {
          return item.option
        })

        try {
          let { data } = await axios.get(`/api/service_providers`, {
            params: {
              GHID: this.spCategories.length <= 0 ? 'all' : GHID.join(),
              CMType: this.area ? this.area : null,
              GeoLocatedNearLatitude: this.GeoLocation.lat,
              GeoLocatedNearLongitude: this.GeoLocation.lng,
            },
          })

          this.serviceProviders = data.recordset
          this.$refs.GoogleMaps.resetProvidersOnMap()
          this.$refs.GoogleMaps.addProvidersOnMap(this.serviceProviders)
        } catch (e) {
          this.$app.error(e)
          return []
        }
      }
    },
    async getCategories() {
      let self = this
      try {
        let { data } = await axios.get(`api/sp_categories`)
        let obj = {}

        if (data.quicklist) {
          data.quicklist.forEach(function (value, index) {
            obj[value.GH_ID] = value.GeneralHeading
          })

          self.spCategoriesOptions = obj
        }
      } catch (e) {
        this.$app.error(e)
        return []
      }
    },
    getGeoLocation() {
      let self = this

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            self.GeoLocation.lng = position.coords.longitude
            self.GeoLocation.lat = position.coords.latitude

            self.getServiceProviders()
          },
          function (error) {
            if (error.message === 'User denied geolocation prompt') {
              self.GetLocationByIp()
            } else {
              alert(error.message)
            }
          },
          {
            enableHighAccuracy: true,
            timeout: 5000,
          }
        )
      }
    },
    selectFilterOptions($event) {
      if ($event.ownerFilterOptions === 'spCategories') {
        if (!$event.isChecked) {
          this.spCategories = this.spCategories.filter(
            (item) => item.option !== $event.filteredOption
          )
        } else {
          this.spCategories.push({
            option: $event.filteredOption,
            value: $event.filteredOptionValue,
          })
        }
      }
    },
    removeSelectedFilterOption(indexOrId, arrayName) {
      if (arrayName === 'spCategories') {
        this.renderKeySpCategories++
        this.spCategories = this.spCategories.filter(
          (item) => item.option !== indexOrId
        )
      }
    },
    listOfValueByKey(array, arrayName, keyName) {
      let result = []
      if (arrayName === 'spCategories') {
        for (let key in array) {
          result.push(array[key][keyName])
        }
      }
      return result
    },
  },
}
</script>
