<template>
  <div id="send-email-form-frontend">
    <div class="form-group">
      <b-form-input
        v-model="model.name"
        :state="state('name')"
        type="email"
        :placeholder="$t('validation.attributes.name')"
      ></b-form-input>
      <span
        :class="[feedback('name') ? 'invalid-feedback text-small' : '']"
      >
        {{ feedback('name') ? feedback('name') : '' }}
      </span>
    </div>
    <div class="form-group">
      <b-form-input
        v-model="model.email"
        :state="state('email')"
        type="email"
        :placeholder="$t('validation.attributes.email_address')"
      ></b-form-input>
      <span
        :class="[feedback('email') ? 'invalid-feedback text-small' : '']"
      >
        {{ feedback('email') ? feedback('email') : '' }}
      </span>
    </div>
    <div class="form-group">
      <b-form-textarea
        v-model="model.message"
        :state="state('message')"
        type="text"
        :placeholder="$t('validation.attributes.message')"
        :rows="10"
      ></b-form-textarea>
      <span :class="[feedback('message') ? 'invalid-feedback text-small' : '']">
        {{ feedback('message') ? feedback('message') : '' }}
      </span>
    </div>
    <div class="form-group">
      <div class="btn btn-outline-primary margin-0">
        <span class="text-small" @click="sendEmail()">
          {{ $t('buttons.send_message') }}
        </span>
        <div class="ripple-container"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      resourceRoute: 'contact',
      model: {
        name: '',
        email: '',
        message: ''
      },
      validation: {}
    }
  },
  methods: {
    feedback(name) {
      if (this.state(name)) {
        return this.validation.errors[name][0]
      }
    },
    state(name) {
      return this.validation.errors !== undefined &&
        this.validation.errors.hasOwnProperty(name)
        ? 'invalid'
        : null
    },
    async sendEmail() {
      let formData = this.$app.objectToFormData(this.model)
      try {
        let { data } = await axios.post('/contact', formData)

        this.$app.noty[data.status](data.message)

        let model = this.model

        Object.keys(model).forEach(function(key) {
          if (typeof model[key] === 'string') {
            model[key] = ''
          }
        })

        this.validation = {}
      } catch (e) {
        // Validation errors
        if (e.response.status === 422) {
          this.validation = e.response.data
          return
        }

        this.$app.error(e)
      }
    }
  }
}
</script>
