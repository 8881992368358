<template>
  <section id="community-services" class="">
    <div class="container">
      <div class="row filter-box">
        <div
          class="col d-flex justify-content-md-end justify-content-center align-items-end flex-wrap"
        >
          <h5 class="filter-header">
            {{ $t('labels.frontend.titles.filter_by') }}
          </h5>
          <dropdown-filter
            class="drop-down-filters"
            type="secondary"
            dropdown-title="Agency"
            :filter-options="thisAgenciesOptions"
            @filter-selected="filterAgency"
          >
          </dropdown-filter>
        </div>
      </div>
      <div class="selected-filter-options"></div>
      <b-container v-if="thisServicesFiltered[1] != null">
        <b-row v-for="row in nrows" :key="row" class="services-cards">
          <b-row v-if="selectedRow != row">
            <b-col
              v-for="col in 3"
              :key="col"
              cols-sm="12"
              cols-md="4"
              :order="col"
              class="cards-inactive"
            >
              <div
                v-if="thisServicesFiltered[col + 3 * (row - 1) - 1] != null"
                :class="
                  isActive(thisServicesFiltered[col + 3 * (row - 1) - 1].id)
                    ? 'card service-card-active'
                    : 'card service-card'
                "
              >
                <div class="card-body text-center">
                  <div class="d-block">
                    <b-img
                      :src="
                        thisServicesFiltered[col + 3 * (row - 1) - 1].icon_code
                      "
                      :class="
                        isActive(
                          thisServicesFiltered[col + 3 * (row - 1) - 1].id
                        )
                          ? 'svc-icon-active'
                          : 'svc-icon'
                      "
                    ></b-img>
                    <h2>
                      {{
                        thisServicesFiltered[col + 3 * (row - 1) - 1].name[
                          $i18n.locale
                        ]
                      }}
                    </h2>
                  </div>
                  <div class="d-block">
                    <p>
                      {{
                        thisServicesFiltered[col + 3 * (row - 1) - 1].summary[
                          $i18n.locale
                        ]
                      }}
                    </p>
                  </div>
                  <div
                    :class="
                      isActive(thisServicesFiltered[col + 3 * (row - 1) - 1].id)
                        ? 'show-more-info-active'
                        : 'show-more-info'
                    "
                  >
                    <p class="description-text">
                      {{
                        thisServicesFiltered[col + 3 * (row - 1) - 1]
                          .description[$i18n.locale]
                      }}
                    </p>
                    <div class="row agency-info-row">
                      <div class="col-12 text-center">
                        <h5>
                          {{ $t('labels.frontend.titles.services_provided') }}
                        </h5>
                      </div>
                      <div class="col-4">
                        <img
                          :src="
                            thisServicesFiltered[col + 3 * (row - 1) - 1].agency
                              .featured_image_url
                          "
                          :alt="
                            thisServicesFiltered[col + 3 * (row - 1) - 1].agency
                              .title
                          "
                          class="modal-image"
                        />
                      </div>
                      <div class="col-8 contact-info-text-col">
                        <h4 class="agency-name-header">
                          {{
                            thisServicesFiltered[col + 3 * (row - 1) - 1].agency
                              .title
                          }}
                        </h4>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col text-center">
                        <p class="contact-info-header">
                          {{ $t('labels.frontend.titles.contact_info') }}
                        </p>
                        <p>
                          <span
                            v-if="
                              thisServicesFiltered[col + 3 * (row - 1) - 1]
                                .program_manager_email
                            "
                            class="underline-text"
                            >{{
                              $t(
                                'labels.frontend.titles.program_manager_email'
                              )
                            }}:</span
                          >
                          {{
                            thisServicesFiltered[col + 3 * (row - 1) - 1]
                              .program_manager_email
                          }}
                        </p>
                        <p>
                          <span
                            v-if="
                              thisServicesFiltered[col + 3 * (row - 1) - 1]
                                .agency.general_email
                            "
                            class="underline-text"
                            >Agency
                            {{ $t('validation.attributes.email') }}:</span
                          >
                          {{
                            thisServicesFiltered[col + 3 * (row - 1) - 1].agency
                              .general_email
                          }}
                        </p>
                        <p>
                          <span
                            v-if="
                              thisServicesFiltered[col + 3 * (row - 1) - 1]
                                .agency.phone
                            "
                            class="underline-text"
                            >{{ $t('validation.attributes.phone') }}:</span
                          >
                          {{
                            thisServicesFiltered[col + 3 * (row - 1) - 1].agency
                              .phone
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col text-center">
                        <button
                          class="btn btn-lg btn-outline-primary btn-register-now"
                          @click="registerNow()"
                        >
                          {{ $t('buttons.register_now') }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center margin-top-auto">
                  <i
                    v-if="
                      !isActive(
                        thisServicesFiltered[col + 3 * (row - 1) - 1].id
                      )
                    "
                    class="fas fa-arrow-circle-right btn-show-more"
                    @click="
                      showMore(
                        thisServicesFiltered[col + 3 * (row - 1) - 1].id,
                        row,
                        col
                      )
                    "
                  ></i>
                  <i
                    v-else
                    class="fas fa-arrow-circle-left btn-show-more-active"
                    @click="
                      hideShowMore(
                        thisServicesFiltered[col + 3 * (row - 1) - 1].id
                      )
                    "
                  ></i>
                </div>
              </div>
            </b-col>
          </b-row>
          <div v-else>
            <b-row
              class="services-cards"
              v-if="
                thisServicesFiltered[selectedCol + 3 * (row - 1) - 1] != null
              "
            >
              <!-- First Card -->
              <div class="col-md-8 col-sm-12 card service-card-active">
                <div class="card-body text-center">
                  <div class="d-block">
                    <b-img
                      :src="
                        thisServicesFiltered[selectedCol + 3 * (row - 1) - 1]
                          .icon_code
                      "
                      :class="
                        isActive(thisServicesFiltered[1 + 3 * (row - 1) - 1].id)
                          ? 'svc-icon-active'
                          : 'svc-icon'
                      "
                    ></b-img>
                    <h2>
                      {{
                        thisServicesFiltered[selectedCol + 3 * (row - 1) - 1]
                          .name[$i18n.locale]
                      }}
                    </h2>
                  </div>
                  <div class="d-block">
                    <p>
                      {{
                        thisServicesFiltered[selectedCol + 3 * (row - 1) - 1]
                          .summary[$i18n.locale]
                      }}
                    </p>
                  </div>
                  <div
                    :class="
                      isActive(
                        thisServicesFiltered[selectedCol + 3 * (row - 1) - 1].id
                      )
                        ? 'show-more-info-active'
                        : 'show-more-info'
                    "
                  >
                    <p class="description-text">
                      {{
                        thisServicesFiltered[selectedCol + 3 * (row - 1) - 1]
                          .description[$i18n.locale]
                      }}
                    </p>
                    <div class="row agency-info-row">
                      <div class="col-12 text-center">
                        <h5>
                          {{ $t('labels.frontend.titles.services_provided') }}
                        </h5>
                      </div>
                      <div class="col-4">
                        <img
                          :src="
                            thisServicesFiltered[
                              selectedCol + 3 * (row - 1) - 1
                            ].agency.featured_image_url
                          "
                          :alt="
                            thisServicesFiltered[
                              selectedCol + 3 * (row - 1) - 1
                            ].agency.title
                          "
                          class="modal-image"
                        />
                      </div>
                      <div class="col-8 contact-info-text-col">
                        <h4 class="agency-name-header">
                          {{
                            thisServicesFiltered[
                              selectedCol + 3 * (row - 1) - 1
                            ].agency.title
                          }}
                        </h4>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col text-center">
                        <p class="contact-info-header">
                          {{ $t('labels.frontend.titles.contact_info') }}
                        </p>
                        <p>
                          <span
                            v-if="
                              thisServicesFiltered[
                                selectedCol + 3 * (row - 1) - 1
                              ].program_manager_email
                            "
                            class="underline-text"
                            >{{
                              $t(
                                'labels.frontend.titles.program_manager_email'
                              )
                            }}:</span
                          >
                          {{
                            thisServicesFiltered[
                              selectedCol + 3 * (row - 1) - 1
                            ].program_manager_email
                          }}
                        </p>
                        <p>
                          <span
                            v-if="
                              thisServicesFiltered[
                                selectedCol + 3 * (row - 1) - 1
                              ].agency.general_email
                            "
                            class="underline-text"
                            >Agency
                            {{ $t('validation.attributes.email') }}:</span
                          >
                          {{
                            thisServicesFiltered[
                              selectedCol + 3 * (row - 1) - 1
                            ].agency.general_email
                          }}
                        </p>
                        <p>
                          <span
                            v-if="
                              thisServicesFiltered[
                                selectedCol + 3 * (row - 1) - 1
                              ].agency.phone
                            "
                            class="underline-text"
                            >{{ $t('validation.attributes.phone') }}:</span
                          >
                          {{
                            thisServicesFiltered[
                              selectedCol + 3 * (row - 1) - 1
                            ].agency.phone
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col text-center">
                        <button
                          class="btn btn-lg btn-outline-primary btn-register-now"
                          @click="registerNow()"
                        >
                          {{ $t('buttons.register_now') }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center margin-top-auto">
                  <i
                    v-if="
                      !isActive(
                        thisServicesFiltered[selectedCol + 3 * (row - 1) - 1].id
                      )
                    "
                    class="fas fa-arrow-circle-right btn-show-more"
                    @click="
                      showMore(
                        thisServicesFiltered[selectedCol + 3 * (row - 1) - 1]
                          .id,
                        row,
                        selectedCol
                      )
                    "
                  ></i>
                  <i
                    v-else
                    class="fas fa-arrow-circle-left btn-show-more-active"
                    @click="
                      hideShowMore(
                        thisServicesFiltered[selectedCol + 3 * (row - 1) - 1].id
                      )
                    "
                  ></i>
                </div>
              </div>
              <div
                class="col-md-4 col-sm-12"
                v-if="
                  thisServicesFiltered[otherCol1 + 3 * (row - 1) - 1] != null
                "
              >
                <!-- second Card -->
                <div class="col-12 card service-card">
                  <div class="card-body text-center">
                    <div class="d-block">
                      <b-img
                        :src="
                          thisServicesFiltered[otherCol1 + 3 * (row - 1) - 1]
                            .icon_code
                        "
                        :class="
                          isActive(
                            thisServicesFiltered[otherCol1 + 3 * (row - 1) - 1]
                              .id
                          )
                            ? 'svc-icon-active'
                            : 'svc-icon'
                        "
                      ></b-img>
                      <h2>
                        {{
                          thisServicesFiltered[otherCol1 + 3 * (row - 1) - 1]
                            .name[$i18n.locale]
                        }}
                      </h2>
                    </div>
                    <div class="d-block">
                      <p>
                        {{
                          thisServicesFiltered[otherCol1 + 3 * (row - 1) - 1]
                            .summary[$i18n.locale]
                        }}
                      </p>
                    </div>
                    <div
                      :class="
                        isActive(
                          thisServicesFiltered[otherCol1 + 3 * (row - 1) - 1].id
                        )
                          ? 'show-more-info-active'
                          : 'show-more-info'
                      "
                    >
                      <p class="description-text">
                        {{
                          thisServicesFiltered[otherCol1 + 3 * (row - 1) - 1]
                            .description[$i18n.locale]
                        }}
                      </p>
                      <div class="row agency-info-row">
                        <div class="col-12 text-center">
                          <h5>
                            {{ $t('labels.frontend.titles.services_provided') }}
                          </h5>
                        </div>
                        <div class="col-4">
                          <img
                            :src="
                              thisServicesFiltered[
                                otherCol1 + 3 * (row - 1) - 1
                              ].agency.featured_image_url
                            "
                            :alt="
                              thisServicesFiltered[
                                otherCol1 + 3 * (row - 1) - 1
                              ].agency.title
                            "
                            class="modal-image"
                          />
                        </div>
                        <div class="col-8 contact-info-text-col">
                          <h4 class="agency-name-header">
                            {{
                              thisServicesFiltered[
                                otherCol1 + 3 * (row - 1) - 1
                              ].agency.title
                            }}
                          </h4>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col text-center">
                          <p class="contact-info-header">
                            {{ $t('labels.frontend.titles.contact_info') }}
                          </p>
                          <p>
                            <span
                              v-if="
                                thisServicesFiltered[
                                  otherCol1 + 3 * (row - 1) - 1
                                ].program_manager_email
                              "
                              class="underline-text"
                              >{{
                                $t(
                                  'labels.frontend.titles.program_manager_email'
                                )
                              }}:</span
                            >
                            {{
                              thisServicesFiltered[
                                otherCol1 + 3 * (row - 1) - 1
                              ].program_manager_email
                            }}
                          </p>
                          <p>
                            <span
                              v-if="
                                thisServicesFiltered[
                                  otherCol1 + 3 * (row - 1) - 1
                                ].agency.general_email
                              "
                              class="underline-text"
                              >Agency
                              {{ $t('validation.attributes.email') }}:</span
                            >
                            {{
                              thisServicesFiltered[
                                otherCol1 + 3 * (row - 1) - 1
                              ].agency.general_email
                            }}
                          </p>
                          <p>
                            <span
                              v-if="
                                thisServicesFiltered[
                                  otherCol1 + 3 * (row - 1) - 1
                                ].agency.phone
                              "
                              class="underline-text"
                              >{{ $t('validation.attributes.phone') }}:</span
                            >
                            {{
                              thisServicesFiltered[
                                otherCol1 + 3 * (row - 1) - 1
                              ].agency.phone
                            }}
                          </p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col text-center">
                          <button
                            class="btn btn-lg btn-outline-primary btn-register-now"
                            @click="registerNow()"
                          >
                            {{ $t('buttons.register_now') }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text-center margin-top-auto">
                    <i
                      v-if="
                        !isActive(
                          thisServicesFiltered[otherCol1 + 3 * (row - 1) - 1].id
                        )
                      "
                      class="fas fa-arrow-circle-right btn-show-more"
                      @click="
                        showMore(
                          thisServicesFiltered[otherCol1 + 3 * (row - 1) - 1]
                            .id,
                          row,
                          otherCol1
                        )
                      "
                    ></i>
                    <i
                      v-else
                      class="fas fa-arrow-circle-left btn-show-more-active"
                      @click="
                        hideShowMore(
                          thisServicesFiltered[otherCol1 + 3 * (row - 1) - 1].id
                        )
                      "
                    ></i>
                  </div>
                </div>
                <!-- Third Card -->
                <div class="col-12 card service-card">
                  <div class="card-body text-center">
                    <div class="d-block">
                      <b-img
                        :src="
                          thisServicesFiltered[otherCol2 + 3 * (row - 1) - 1]
                            .icon_code
                        "
                        :class="
                          isActive(
                            thisServicesFiltered[otherCol2 + 3 * (row - 1) - 1]
                              .id
                          )
                            ? 'svc-icon-active'
                            : 'svc-icon'
                        "
                      ></b-img>
                      <h2>
                        {{
                          thisServicesFiltered[otherCol2 + 3 * (row - 1) - 1]
                            .name[$i18n.locale]
                        }}
                      </h2>
                    </div>
                    <div class="d-block">
                      <p>
                        {{
                          thisServicesFiltered[otherCol2 + 3 * (row - 1) - 1]
                            .summary[$i18n.locale]
                        }}
                      </p>
                    </div>
                    <div
                      :class="
                        isActive(
                          thisServicesFiltered[otherCol2 + 3 * (row - 1) - 1].id
                        )
                          ? 'show-more-info-active'
                          : 'show-more-info'
                      "
                    >
                      <p class="description-text">
                        {{
                          thisServicesFiltered[otherCol2 + 3 * (row - 1) - 1]
                            .description[$i18n.locale]
                        }}
                      </p>
                      <div class="row agency-info-row">
                        <div class="col-12 text-center">
                          <h5>
                            {{ $t('labels.frontend.titles.services_provided') }}
                          </h5>
                        </div>
                        <div class="col-4">
                          <img
                            :src="
                              thisServicesFiltered[
                                otherCol2 + 3 * (row - 1) - 1
                              ].agency.featured_image_url
                            "
                            :alt="
                              thisServicesFiltered[
                                otherCol2 + 3 * (row - 1) - 1
                              ].agency.title
                            "
                            class="modal-image"
                          />
                        </div>
                        <div class="col-8 contact-info-text-col">
                          <h4 class="agency-name-header">
                            {{
                              thisServicesFiltered[
                                otherCol2 + 3 * (row - 1) - 1
                              ].agency.title
                            }}
                          </h4>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col text-center">
                          <p class="contact-info-header">
                            {{ $t('labels.frontend.titles.contact_info') }}
                          </p>
                          <p>
                            <span
                              v-if="
                                thisServicesFiltered[
                                  otherCol2 + 3 * (row - 1) - 1
                                ].program_manager_email
                              "
                              class="underline-text"
                              >{{
                                $t(
                                  'labels.frontend.titles.program_manager_email'
                                )
                              }}:</span
                            >
                            {{
                              thisServicesFiltered[
                                otherCol2 + 3 * (row - 1) - 1
                              ].program_manager_email
                            }}
                          </p>
                          <p>
                            <span
                              v-if="
                                thisServicesFiltered[
                                  otherCol2 + 3 * (row - 1) - 1
                                ].agency.general_email
                              "
                              class="underline-text"
                              >Agency
                              {{ $t('validation.attributes.email') }}:</span
                            >
                            {{
                              thisServicesFiltered[
                                otherCol2 + 3 * (row - 1) - 1
                              ].agency.general_email
                            }}
                          </p>
                          <p>
                            <span
                              v-if="
                                thisServicesFiltered[
                                  otherCol2 + 3 * (row - 1) - 1
                                ].agency.phone
                              "
                              class="underline-text"
                              >{{ $t('validation.attributes.phone') }}:</span
                            >
                            {{
                              thisServicesFiltered[
                                otherCol2 + 3 * (row - 1) - 1
                              ].agency.phone
                            }}
                          </p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col text-center">
                          <button
                            class="btn btn-lg btn-outline-primary btn-register-now"
                            @click="registerNow()"
                          >
                            {{ $t('buttons.register_now') }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text-center margin-top-auto">
                    <i
                      v-if="
                        !isActive(
                          thisServicesFiltered[otherCol2 + 3 * (row - 1) - 1].id
                        )
                      "
                      class="fas fa-arrow-circle-right btn-show-more"
                      @click="
                        showMore(
                          thisServicesFiltered[otherCol2 + 3 * (row - 1) - 1]
                            .id,
                          row,
                          otherCol2
                        )
                      "
                    ></i>
                    <i
                      v-else
                      class="fas fa-arrow-circle-left btn-show-more-active"
                      @click="
                        hideShowMore(
                          thisServicesFiltered[otherCol2 + 3 * (row - 1) - 1].id
                        )
                      "
                    ></i>
                  </div>
                </div>
              </div>
            </b-row>
          </div>
        </b-row>
      </b-container>
    </div>
    <!--Modal: Register Form-->
    <div
      class="modal fade"
      id="create-account-connect-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="Create Account Modal"
      aria-hidden="true"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <!--Content-->
        <sign-up-form-connect
          :agency="this.selectedServiceAgency"
          :communityService="this.selectedShowMore"
        ></sign-up-form-connect>
        <!--/.Content-->
      </div>
    </div>
    <!--Modal: Register Form-->
    <!--Modal: Login Form-->
    <div
      class="modal fade"
      id="sign-in-connect-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="Sign In Connect Modal"
      aria-hidden="true"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <!--Content-->
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="container">
              <div class="row">
                <div class="col">
                  <a data-dismiss="modal" class="close-modal-link">
                    <i class="fas fa-times"></i>
                  </a>
                  <div class="modal-right-box">
                    <h3 class="text-center">{{ $t('labels.user.sign_in') }}</h3>
                    <sign-in-form-connect
                      :agency="this.selectedServiceAgency"
                      :communityService="this.selectedShowMore"
                    ></sign-in-form-connect>
                    <div class="form-group display-flex justify-content-center">
                      <a
                        data-dismiss="modal"
                        class="forgot-pass-link"
                        href="#forget-password-modal"
                        data-toggle="modal"
                      >
                        {{ $t('labels.user.password_forgot') }}
                      </a>
                    </div>
                    <hr />
                    <p class="text-center">
                      <span>{{ $t('labels.user.no_account') }}</span
                      ><br />
                      <a
                        data-dismiss="modal"
                        class="sign-in-link"
                        href="#create-account-connect-modal"
                        data-toggle="modal"
                      >
                        {{ $t('labels.user.create_account') }}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--/.Content-->
      </div>
    </div>
    <!--Modal: Login Form-->
  </section>
</template>
<script>
import DropdownFilter from './DropdownFilter'

export default {
  name: 'CommunityServices',
  components: {
    DropdownFilter
  },
  props: {
    services: {
      type: Array,
      default: () => []
    },
    agencies: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    nrows() {
      return Math.floor((this.thisServicesFiltered.length - 1) / 3) + 1
    }
  },
  data() {
    return {
      thisServicesOrg: this.services,
      thisServicesFiltered: this.services,
      thisAgencies: this.agencies,
      thisAgenciesOptions: [],
      selectedShowMore: 0,
      selectedAgency: '',
      totalItems: 0,
      selectedServiceAgency: 0,
      cardsCol: '4',
      selectedRow: '',
      selectedCol: '',
      otherCol1: '',
      otherCol2: '',
      showOrg: true,
      registrationInfo: {
        agencyId: '',
        clientId: '',
        connectServiceId: ''
      }
    }
  },
  created: function() {
    this.thisAgenciesOptions = this.agencies
    this.thisAgenciesOptions.forEach(function(obj) {
      obj.value = obj.id
      obj.label = obj.title
    })
    // Insert "All" to start of the categories array.
    this.thisAgenciesOptions.unshift({ value: 'All', label: 'All' })
  },
  methods: {
    // Show more info on a specific card click
    showMore(id, row, col) {
      // Set row/col location
      this.selectedRow = row
      this.selectedCol = col
      if (col == 1) {
        this.otherCol1 = 2
        this.otherCol2 = 3
      } else if (col == 2) {
        this.otherCol1 = 1
        this.otherCol2 = 3
      } else {
        this.otherCol1 = 1
        this.otherCol2 = 2
      }

      this.selectedShowMore = id
      let serviceSelected = {}
      serviceSelected = this.thisServicesFiltered.filter(
        item => item.id == id
      )[0]
      this.selectedServiceAgency = serviceSelected.agency_id
    },
    // Hide show more info when up arrow clicked for active card
    hideShowMore(id) {
      this.showOrg = true
      this.selectedRow = ''
      this.selectedCol = ''
      this.otherCol1 = ''
      this.otherCol2 = ''
      this.selectedShowMore = 0
      this.selectedServiceAgency = 0
    },
    // Tells which card's show more is activated
    isActive(i) {
      if (this.selectedShowMore != 0) {
        return i === this.selectedShowMore
      }
    },
    cardsRowCheck(row) {
      if (this.selectedRow == row) {
        return true
      }
      return false
    },
    cardsColCheck(row, col) {
      // when selected card by it's row and col position
      if (this.selectedRow == row && this.selectedCol == col) {
        return 'flex-grow m-2 order-0'
      }
      // other cards in selected rows
      else if (this.selectedRow == row && this.selectedCol != col) {
        return 'align-content-stretch flex-wrap m-2 order-' + col
      }
      // original
      return 'm-2 d-flex flex-column order-' + col
    },
    // Tells card order based on index / array length logic
    getCardOrder(i) {
      if (i > 11) {
        return 12
      } else {
        return i + 1
      }
    },
    // Tells active card order based on index / array length logic
    getActiveCardOrder(i) {
      if ((i + 1) % 3 == 0) {
        return i - 2
      } else {
        return i + 1
      }
    },
    // Filter courses by Language
    filterAgency(agency) {
      // Filter for all
      if (agency.value !== 'All') {
        this.selectedAgency = agency.value
        // Filter Course tags for language
        this.thisServicesFiltered = this.thisServicesOrg.filter(
          item => item.agency_id == this.selectedAgency
        )
      } else {
        // clear out selected agency if all selected
        this.selectedAgency
        this.thisServicesFiltered = this.thisServicesOrg
      }

      // Set total items
      this.totalItems = Object.keys(this.thisServicesFiltered).length
    },
    async registerNow() {
      try {
        // User Logged in flow
        if (
          this.$app.user != null &&
          this.$app.user.roles[0].name == 'client'
        ) {
          this.registrationInfo.agencyId = this.selectedServiceAgency
          this.registrationInfo.clientId = this.$app.user.id
          this.registrationInfo.connectServiceId = this.selectedShowMore
          let { data } = await axios.post(
            `/app/create_registration`,
            this.registrationInfo
          )
          if (data.status = 200) {
            // Notify Registered successfully
            this.$app.noty['success'](
              'You have successfully been registered for the service, an agency worker will be in touch with you shortly!'
            )
          }
        } else if (
          this.$app.user != null &&
          this.$app.user.roles[0].name != 'client'
        ) {
          // Notify this service is only valid for LASI clients
          this.$app.noty['error'](
            'This service is only valid for LASI clients.'
          )
        }
        // User not logged in flow
        else {
          // show signup form
          $('#create-account-connect-modal').modal('show')
        }
      } catch (e) {
        this.$app.error(e)
        return []
      }
    }
  }
}
</script>
<style>
#community-services .dropdown-toggle {
  white-space: nowrap;
  border-radius: 3px;
  border: 2px solid #ff0000;
  padding: 10px;
  color: #333333;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  margin: 10px;
}

#community-services h5.filter-header {
  font-size: 18px;
  font-weight: 700;
  color: #000000;
  padding-right: 20px;
}

#community-services h2 {
  font-size: 24px;
  font-weight: 300;
  color: #000000;
  padding: 20px;
}

#community-services p {
  font-size: 1.2rem;
  line-height: normal;
}

#community-services .card-deck {
  margin: 15px 0;
}

#community-services .card-body {
  padding-bottom: 0px;
}

#community-services .service-card {
  border-radius: 6px;
  border: 1px solid #000000;
  padding: 15px;
  margin: 12px 0;
  box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 20%);
  height: fit-content;
}

#community-services .service-card-active {
  border-radius: 6px;
  border: 1px solid #000000;
  padding: 15px;
  margin: 12px 0;
  box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 20%);
  height: fit-content;
}

#community-services .selected-filter-options span {
  background-color: red;
  border: 1px solid red;
  border-radius: 15px;
  margin-left: 10px;
  margin-bottom: 5px;
  color: #ffffff;
  display: inline-block;
  padding: 0 10px;
  font-size: 14px;
}

#community-services .selected-filter-options span i {
  font-style: normal;
  font-size: 14px;
  width: 16px;
  display: inline-block;
  text-align: center;
  height: 23px;
  line-height: 24px;
  cursor: pointer;
  color: #ffffff;
}

#community-services .dropdown-menu .dropdown-item {
  max-width: 95%;
}

#community-services .dropdown-menu .dropdown-item:hover {
  cursor: pointer;
}
</style>
<style scoped>
#community-services {
  width: 100%;
}

.agency-name-header {
  font-weight: 900;
}

i {
  font-size: xxx-large;
  color: #ff0000;
}

.btn-register-now {
  margin-bottom: 20px;
}

.btn-show-more {
  color: #000;
  font-size: xx-large;
}

.btn-show-more-active {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 15px;
  margin-top: 15px;
  color: #000;
  font-size: xx-large;
}

.btn-show-more:hover,
.btn-show-more-active:hover {
  cursor: pointer;
  color: #ff0000;
}

.agency-info-row {
  margin-top: 50px;
}

.cards-inactive {
  padding-left: 0px;
}

.contact-info-header {
  text-decoration: underline;
  margin-top: 25px;
}

.contact-info-text-col {
  margin-top: 35px;
  padding-left: 15px;
}

.description-text {
  margin-top: 50px;
}

.filter-box {
  padding-top: 50px;
}

.modal-dialog {
  max-width: 100%;
}

.show-more-info {
  display: none;
}

.show-more-info-active {
  display: block;
}

#sign-in-connect-modal .modal-dialog {
  max-width: 450px;
  align-items: center;
  justify-content: center;
}

#sign-in-connect-modal .close-modal-link {
  position: relative;
  right: 5px;
  top: 10px;
  float: right;
  cursor: pointer;
}

.underline-text {
  text-decoration: underline;
}

.svc-col {
  width: 100%;
}

.svc-icon {
  width: 100px;
  height: 100px;
}

.svc-icon-active {
  width: 300px;
  height: 300px;
}

.test {
  border: solid;
}
</style>
