<template>
  <section id="coalition-agencies" class="">
    <div class="container">
      <div class="row filter-box">
        <div
          class="col d-flex justify-content-md-end justify-content-center align-items-end flex-wrap"
        >
          <h5 class="filter-header">
            {{ $t('labels.frontend.titles.filter_by') }}
          </h5>
          <DropdownWithCheckboxes
            owner-filter-options="service_categories"
            :filter-options="serviceCategories"
            :key="`category-${renderKeyserviceCategories}`"
            :filter-title="$t('labels.type_of_services')"
            :selected-filter-options="
              listOfValueByKey(
                service_categories,
                'service_categories',
                'value'
              )
            "
            :index-is-id="true"
            @select-filter-options="selectFilterOptions($event)"
            :dropdown-title="
              setDropdownTitle(
                service_categories,
                $t('labels.type_of_services')
              )
            "
          ></DropdownWithCheckboxes>
          <DropdownWithCheckboxes
            owner-filter-options="available_languages"
            :filter-options="languages"
            :key="`languages-${renderKeyLanguages}`"
            :filter-title="$t('labels.language')"
            :selected-filter-options="
              listOfValueByKey(
                available_languages,
                'available_languages',
                'value'
              )
            "
            :dropdown-title="
              setDropdownTitle(available_languages, $t('labels.language'))
            "
            @select-filter-options="selectFilterOptions($event)"
          ></DropdownWithCheckboxes>
          <DropdownWithCheckboxes
            owner-filter-options="available_locations"
            :filter-options="locations"
            :key="`locations-${renderKeyLocations}`"
            :filter-title="$t('labels.location')"
            :selected-filter-options="
              listOfValueByKey(
                available_locations,
                'available_locations',
                'value'
              )
            "
            :index-is-id="true"
            :dropdown-title="
              setDropdownTitle(available_locations, $t('labels.location'))
            "
            @select-filter-options="selectFilterOptions($event)"
          ></DropdownWithCheckboxes>
        </div>
      </div>
      <div class="selected-filter-options">
        <span
          :key="`available_languages-${item.option}`"
          v-for="item in available_languages"
        >
          <!-- {{ $t('labels.localization.'+ item.toLowerCase()) }} -->
          {{ item.value }}
          <i
            @click="
              removeSelectedFilterOption(item.option, 'available_languages')
            "
            class="fas fa-times"
            >&nbsp;</i
          >
        </span>

        <span
          :key="`service_categories-${item.option}`"
          v-for="item in service_categories"
        >
          {{ item.value }}
          <i
            @click="
              removeSelectedFilterOption(item.option, 'service_categories')
            "
            class="fas fa-times"
            >&nbsp;</i
          >
        </span>
        <span
          :key="`available_locations-${item.option}`"
          v-for="item in available_locations"
        >
          {{ item.value }}
          <i
            @click="
              removeSelectedFilterOption(item.option, 'available_locations')
            "
            class="fas fa-times"
            >&nbsp;</i
          >
        </span>
      </div>
      <div class="row equal agencies-cards">
        <div
          class="col col-12 col-sm-6 col-lg-4 card-deck"
          :key="item.id"
          v-for="item in thisAgencies"
        >
          <div class="card h-100 agency-card">
            <div class="card-body text-center">
              <a
                v-if="item.service_categories.length > 0"
                :href="`/agency/${item.slug}`"
                class="d-block"
              >
                <img
                  :src="item.logo"
                  :alt="item.title"
                  class="fluid"
                  height="100"
                />
                <h2 class="">{{ item.title }}</h2>
              </a>
              <span v-else>
                <img
                  :src="'/img/' + item.featured_image_url"
                  :alt="item.title"
                  class="fluid"
                  height="100"
                />
                <h2 class="">{{ item.title }}</h2>
              </span>

              <p v-if="item.service_categories.length > 0">
                {{ $t('static.frontend.sections.available_agencies.p2-1') }}
              </p>
              <p v-else>
                {{ $t('static.frontend.sections.available_agencies.p2-2') }}
              </p>
              <AgencyServiceCategories
                :service-categories="item.service_categories"
                :agency-id="item.id"
                :is-clicked-by-agency-id="isClickedByAgencyId"
                @clicked-close="isClickedByAgencyId = null"
              ></AgencyServiceCategories>
            </div>
            <div class="text-center margin-top-auto">
              <a
                v-if="item.service_categories.length > 0"
                :href="'/' + $i18n.locale + `/agency/${item.slug}`"
                class="btn btn-outline-primary"
              >
                {{ $t('buttons.view_services') }}
              </a>
              <b-btn
                v-else
                onclick="return false"
                :disabled="true"
                class="btn btn-outline-primary"
              >
                {{ $t('buttons.coming_soon') }}
              </b-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import DropdownWithCheckboxes from './DropdownWithCheckboxes'
import AgencyServiceCategories from './AgencyServiceCategories'
export default {
  name: 'LasiCoalitionAgencies',
  components: {
    DropdownWithCheckboxes,
    AgencyServiceCategories
  },
  props: {
    agencies: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      service_categories: [],
      available_languages: [],
      available_locations: [],
      isClickedByAgencyId: null,
      thisAgencies: this.agencies,
      serviceCategories: {},
      languages: {},
      locations: {},
      renderKeyserviceCategories: 0,
      renderKeyLanguages: 0,
      renderKeyLocations: 0
    }
  },
  created: function() {
    this.languages = this.selectDistinctItems(
      this.agencies,
      'available_languages'
    )

    let languages = this.languages
    let ordered = {}
    let self = this
    Object.keys(this.languages)
      .sort()
      .forEach(function(key) {
        if (key) {
          ordered[key] = self.$t(
            'labels.localization.' + languages[key].toLowerCase()
          )
        }
      })
    this.languages = ordered

    this.serviceCategories = this.selectDistinctItems(
      this.agencies,
      'service_categories',
      'name',
      'id'
    )
    this.locations = this.selectDistinctItems(this.agencies, 'location')
  },
  methods: {
    listOfValueByKey(array, arrayName, keyName) {
      let result = []
      if (arrayName === 'service_categories') {
        for (let key in array) {
          result.push(array[key][keyName])
        }
      }
      if (arrayName === 'available_languages') {
        for (let key in array) {
          result.push(array[key][keyName])
        }
      }
      if (arrayName === 'available_locations') {
        for (let key in array) {
          result.push(array[key][keyName])
        }
      }
      return result
    },
    open(agencyId) {
      this.isClickedByAgencyId =
        this.isClickedByAgencyId !== agencyId ? agencyId : null
    },
    removeSelectedFilterOption(indexOrId, arrayName) {
      if (arrayName === 'service_categories') {
        this.renderKeyserviceCategories++
        this.service_categories = this.service_categories.filter(
          item => item.option !== indexOrId
        )
      } else if (arrayName === 'available_languages') {
        this.renderKeyLanguages++
        this.available_languages = this.available_languages.filter(
          item => item.option !== indexOrId
        )
      } else if (arrayName === 'available_locations') {
        this.renderKeyLocations++
        this.available_locations = this.available_locations.filter(
          item => item.option !== indexOrId
        )
      }

      this.fetchAgencies()
    },
    selectDistinctItems(arrays, propArray, propValue = null, propKey = null) {
      let result = {}
      let self = this
      if (arrays.length !== 0) {
        arrays.forEach(arrayItem => {
          const foundProp = arrayItem[propArray]
          if (Array.isArray(foundProp) && foundProp.length > 0) {
            foundProp.forEach(foundPropItem => {
              const resultKey = propKey ? foundPropItem[propKey] : foundPropItem
              result[resultKey] = propValue
                ? foundPropItem[propValue]
                : foundPropItem
            })
          } else {
            result[foundProp] = `Ottawa - ${self.$t(
              'labels.locations.' + foundProp
            )}`
          }
        })
      }
      return result
    },
    selectFilterOptions($event) {
      if ($event.ownerFilterOptions === 'service_categories') {
        if (!$event.isChecked) {
          this.service_categories = this.service_categories.filter(
            item => item.option !== $event.filteredOption
          )
        } else {
          this.service_categories.push({
            option: $event.filteredOption,
            value: $event.filteredOptionValue
          })
        }
      } else if ($event.ownerFilterOptions === 'available_languages') {
        // Remove
        if (!$event.isChecked) {
          this.available_languages = this.available_languages.filter(
            item => item.option !== $event.filteredOption
          )
        }
        // Add
        else {
          this.available_languages.push({
            option: $event.filteredOption,
            value: $event.filteredOptionValue
          })
        }
      } else if ($event.ownerFilterOptions === 'available_locations') {
        if (!$event.isChecked) {
          this.available_locations = this.available_locations.filter(
            item => item.option !== $event.filteredOption
          )
        } else {
          this.available_locations.push({
            option: $event.filteredOption,
            value: $event.filteredOptionValue
          })
        }
      }
      this.fetchAgencies()
    },
    async fetchAgencies() {
      try {
        let { data } = await window.axios.post(window.route(`get-agencies`), {
          available_languages: this.available_languages,
          service_categories: this.service_categories,
          locations: this.available_locations
        })
        this.thisAgencies = data.data
      } catch (e) {
        this.$app.error(e)
        return []
      }
    },
    setDropdownTitle(arrayItems, title) {
      if (arrayItems.length > 0) {
        return `${title}
          (${arrayItems.length})
        `
      } else {
        return title
      }
    },
    redirectToAgency(slug) {
      location.href = '/agency/' + slug
    }
  }
}
</script>
