<template>
  <div>
    <button
      v-if="!agency.is_user_registered"
      role="button"
      class="btn btn-outline-primary text-left"
      :class="[btnClass, !display ? 'd-none' : '']"
      @click="showModal(agency.id)"
    >
      {{ $t('buttons.register') }}
    </button>

    <button
      v-else
      role="button"
      class="btn btn-outline-primary text-left"
      :class="[btnClass]"
      @click.prevent="redirectToAppointments"
    >
      {{ $t('buttons.make_appointment') }}
    </button>
    <b-modal

      body-class="modal-body-register"
      ok-title="Register"
      :ok-disabled="setDisabled()"
      footer-class="modal-footer-register"
      @ok="triggerModal(agency.id)"
      :ref="`my-modal-${agency.id}`"
    >
      <template slot="modal-header" slot-scope="{ close }">
        <h5>{{ $t('labels.backend.register_to_agency.title') }}</h5>
      </template>
      <template
        slot="default"
        v-if="agency.service_categories"
        class="d-block text-left"
      >
        <b-form-group
          v-for="(serviceCategory, index) in agency.service_categories"
          :key="index"
          class="col-12"
        >
          <b-form-checkbox
            v-model="categoriesSelected"
            :value="serviceCategory.id"
          >
            {{ serviceCategory.name }}
          </b-form-checkbox>
        </b-form-group>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'RegisterToAgency',
  props: {
    btnClass: {
      type: String,
      default: null
    },
    agency: {
      type: Object,
      default: null,
      required: true
    }
  },
  data() {
    return {
      display: true,
      categoriesSelected: []
    }
  },
  watch: {
    categoriesSelected(newVal) {
      if (newVal.length > 0) {
        $('.modal-footer-register button.btn.btn-primary')
          .removeClass('disabled')
          .prop('disabled', false)
      } else {
        $('.modal-footer-register button.btn.btn-primary')
          .addClass('disabled')
          .prop('disabled', true)
      }
    }
  },
  methods: {
    async registerToAgency(agencyId) {
      try {
        let formData = this.$app.objectToFormData({
          service_categories: this.categoriesSelected
        })
        let { data } = await axios.post(
          `/app/agencies/${agencyId}/register`,
          formData
        )
        if (data.status === 'success') {
          this.display = false
          this.agency.is_user_registered = true
        }
        this.$app.noty[data.status](data.message)
      } catch (e) {
        this.$app.error(e)
        return []
      }
    },
    redirectToAppointments() {
      window.location.href = '/app/appointments'
    },
    async showModal(agencyId) {
      this.$refs['my-modal-' + agencyId].show()
    },
    triggerModal(agencyId) {
      this.registerToAgency(agencyId)
    },
    setDisabled() {
      return !this.categoriesSelected.length
    }
  }
}
</script>

<style scoped></style>
