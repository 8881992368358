<template>
  <div>
    <b-form @submit="onSubmit" id="sign-in-form" class="create-account-form">
      <div class="form-group">
        <label for="email">{{ $t('validation.attributes.email') }}</label>
        <b-form-input
          id="sgi-email"
          required
          name="email"
          v-model="model.email"
          :state="state('email')"
          type="email"
          :placeholder="$t('validation.attributes.email_address')"
        ></b-form-input>
        <b-form-feedback>{{ feedback('email') }}</b-form-feedback>
      </div>

      <div class="form-group mb-3">
        <label for="password">{{ $t('validation.attributes.password') }}</label>
        <b-form-input
          id="sgi-password"
          required="required"
          name="password"
          v-model="model.password"
          :state="state('password')"
          type="password"
          :placeholder="$t('validation.attributes.password')"
        ></b-form-input>
        <b-form-feedback>{{ feedback('password') }}</b-form-feedback>
      </div>
      <div class="form-group display-flex justify-content-center">
        <input type="submit" form="sign-in-form" class="btn btn-outline-primary" :value="$t('buttons.sign_in')" />
      </div>
    </b-form>
  </div>
</template>

<script>
export default {
  name: 'SignInForm',
   props: {
    communityService: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      model: {
        email: '',
        password: '',
        agency: ''
      },
      validation: {}
    }
  },
  methods: {
    feedback(name) {
      if (this.state(name)) {
        return this.validation.errors[name][0]
      }
    },
    state(name) {
      return this.validation.errors !== undefined &&
        this.validation.errors.hasOwnProperty(name)
        ? 'invalid'
        : null
    },
    onSubmit(evt) {
      evt.preventDefault();
      window.axios
        .post(window.route('login'), this.model)
        .then(response => {
          location.href = window.location.origin + '/' + this.$i18n.locale + '/app'
          window.localStorage.setItem('loggedIn', 'true')
        })
        .catch(e => {
          if (e.response.status === 422) {
            this.validation = e.response.data
            return
          }
        })
    }
  }
}
</script>
