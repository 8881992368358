<template>
  <div class="plugins-google-maps-component">
    <div
      ref="map"
      class="map-box"
      :style="{ width: width, height: height }"
    ></div>
  </div>
</template>

<script>
import GoogleMapsLoader from 'google-maps'

export default {
  name: 'PluginsGoogleMapsComponent',
  props: {
    width: {
      type: String,
      default: '360px'
    },
    height: {
      type: String,
      default: '474px'
    },
    serviceProviders: {
      type: Array,
      default: () => []
    },
    geo: {
      type: Object,
      default: () => ({
        lat: null,
        lng: null
      })
    },
    googleApiKey: {
      type: String,
      default: null
    },
    selectedProvider: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      map: null,
      markers: [],
      infoWindow: null
    }
  },
  watch: {
    serviceProviders: 'resetMarkers',
    selectedProvider: 'selectMarker'
  },
  mounted() {
    if (this.geo.lat && this.geo.lng) {
      this.init(this.geo)
    }
  },
  methods: {
    init(myLocation) {
      GoogleMapsLoader.KEY = this.googleApiKey

      GoogleMapsLoader.load(google => {
        this.map = new google.maps.Map(this.$refs.map, {
          center: myLocation,
          zoom: 12,
          zoomControl: true,
          mapTypeControl: true,
          mapTypeControlOptions: {
            mapTypeIds: [
              google.maps.MapTypeId.ROADMAP,
              google.maps.MapTypeId.SATELLITE
            ]
          },
          scaleControl: false,
          streetViewControl: true,
          rotateControl: false,
          fullscreenControl: true,
          styles: [
            {
              featureType: 'poi.place_of_worship',
              elementType: 'labels',
              stylers: [
                {
                  visibility: 'off'
                }
              ]
            },
            {
              featureType: 'poi.business',
              elementType: 'labels',
              stylers: [
                {
                  visibility: 'off'
                }
              ]
            }
          ]
        })
        this.addProvidersOnMap()
      })
    },

    addProvidersOnMap() {
      if (this.serviceProviders.length > 0) {
        // declare parent to invoke methods
        let self = this
        // set one infoWindow for re-use
        this.infoWindow = new google.maps.InfoWindow({
          maxWidth: '150px'
        })
        this.serviceProviders.forEach(serviceProvider => {
          let marker = new google.maps.Marker({
            position: {
              lat: serviceProvider.LATITUDE,
              lng: serviceProvider.LONGITUDE
            },
            map: this.map,
            title: serviceProvider.ORG_NAME,
            icon: {
              url: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png'
            }
          })
          // Add listener on Marker click
          google.maps.event.addListener(marker, 'click', function() {
            self.infoWindow.setContent(self.getInfoContent(serviceProvider))
            self.infoWindow.open(this.map, marker)
            // Add listener on map click to close
            google.maps.event.addListener(this.map, 'click', function() {
              self.infoWindow.close()
              marker.visible = false
            })
          })
          marker.setMap(this.map)
          this.markers.push(marker)
        })
      }
    },
    resetMarkers() {
      this.resetProvidersOnMap()
      this.addProvidersOnMap()
    },
    resetProvidersOnMap() {
      for (var i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null)
      }
      this.markers = []
    },
    // get info window content for location
    getInfoContent(serviceProvider) {
      const contentString =
        '<div id="content" style="max-width:250px;">' +
        '<h6 id="firstHeading" class="firstHeading">' +
        serviceProvider.ORG_NAME +
        '</h6>' +
        '<div id="bodyContent">' +
        '<p>' +
        serviceProvider.LOCATED_IN_CM +
        '</p>' +
        '<p><a href="/app/service-providers-directory/' +
        serviceProvider.NUM +
        '/show">' +
        'Learn more</a></p>' +
        '</div>' +
        '</div>'

      return contentString
    },
    // Method to set bounce to the appropriate marker
    selectMarker() {
      if (this.selectedProvider) {
        this.clearAnimation()
        let marker = this.markers.filter(item => {
          return item.title == this.selectedProvider.ORG_NAME
        })[0]
        marker.setIcon(
          'https://maps.google.com/mapfiles/ms/icons/green-dot.png'
        )
        marker.setAnimation(google.maps.Animation.BOUNCE)
        let self = this
        setTimeout(function() {
          marker.setAnimation(null)
          marker.setIcon(
            'https://maps.google.com/mapfiles/ms/icons/blue-dot.png'
          )
        }, 1500)
      }
    },
    // Clear other marker animations, in the case of hovering over quickly.
    clearAnimation() {
      for (var i = 0; i < this.markers.length; i++) {
        this.markers[i].setAnimation(null)
        this.markers[i].setIcon(
          'https://maps.google.com/mapfiles/ms/icons/blue-dot.png'
        )
      }
    }
  }
}
</script>
