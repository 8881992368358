<template>
  <div
    :class="[
      'service-categories',
      isClickedByAgencyId === agencyId ? 'active' : ''
    ]"
  >
    <ul class="list-group align-items-center">
      <li :key="item.id" v-for="item in serviceCategories.slice(0, 3)" class="list-group-item">
        <i :class="item.icon_code + ' orange'"></i>
        {{ item.name }}
      </li>
      <li class="list-group-item" v-if="serviceCategories.length > 3">
        <i class="far fa-plus-square orange"></i>
        {{ serviceCategories.length - 3 + ' ' + $t('labels.more') }} 
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'AgencyServiceCategories',
  props: {
    serviceCategories: {
      type: Array,
      default: () => []
    },
    agencyId: {
      type: Number,
      default: null
    },
    isClickedByAgencyId: {
      type: Number,
      default: null
    }
  },
  methods: {
    close() {
      this.$emit('clicked-close')
    }
  }
}
</script>
