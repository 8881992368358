import { render, staticRenderFns } from "./CommunityServices.vue?vue&type=template&id=1602fa6d&scoped=true&"
import script from "./CommunityServices.vue?vue&type=script&lang=js&"
export * from "./CommunityServices.vue?vue&type=script&lang=js&"
import style0 from "./CommunityServices.vue?vue&type=style&index=0&lang=css&"
import style1 from "./CommunityServices.vue?vue&type=style&index=1&id=1602fa6d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1602fa6d",
  null
  
)

component.options.__file = "CommunityServices.vue"
export default component.exports