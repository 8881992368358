<template>
  <div
    class="form-group custom-select-field bmd-form-group dropdown-with-checkboxes"
  >
    <label class="bmd-label-static sr-only">{{ filterTitle }}</label>
    <div class="dropdown-filter-secondary py-2">
      <div class="nav-item dropdown">
        <a
          class="dropdown-toggle dropdown-filter-select"
          href="#"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {{ dropdownTitle }}
        </a>
        <div
          :class="['dropdown-menu filter-options', { show: state }]"
          aria-labelledby="dropdown-filter"
        >
          <div
            class="dropdown-item item-checkbox"
            v-for="(item, index) in filterOptions"
            :key="index"
          >
            <div
              @click="toggleDropdown"
              class="custom-control custom-checkbox mr-sm-2"
            >
              <input
                type="checkbox"
                class="custom-control-input"
                :id="`filter-checkbox-${filterTitle.replace(' ', '')}-${index}`"
                :checked="selectedFilterOptions.includes(item)"
                @change="
                  selectFilterOption(
                    $event,
                    indexIsId ? item : $event.target.value,
                    ownerFilterOptions,
                    index
                  )
                "
                :value="indexIsId ? index : item"
              />
              <label
                class="custom-control-label"
                :for="
                  `filter-checkbox-${filterTitle.replace(' ', '')}-${index}`
                "
              >
                {{ item }}
              </label>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropdownWithCheckboxes',
  props: {
    ownerFilterOptions: {
      type: String,
      default: ''
    },
    filterOptions: {
      type: Object,
      default: () => {}
    },
    selectedFilterOptions: {
      type: Array,
      default: () => []
    },
    filterTitle: {
      type: String,
      default: 'Filter Title'
    },
    indexIsId: {
      type: Boolean,
      default: false
    },
    dropdownTitle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      state: false
    }
  },
  mounted() {
    document.addEventListener('click', this.close)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.close)
  },
  methods: {
    toggleDropdown() {
      this.state = true
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.state = false
      }
    },
    selectFilterOption(event, value, ownerFilterOptions, index) {
      this.$emit('select-filter-options', {
        isChecked: event.target.checked,
        filteredOption: index,
        filteredOptionValue: value,
        ownerFilterOptions,
      })
    }
  }
}
</script>
