<template>
  <div>
    <a class="nav-link" href="#" @click="redirectDashboard">Dashboard</a>
  </div>
</template>

<script>
    export default {
        name: "DashboardLink",
        methods: {
          redirectDashboard () {
            location.href = window.location.origin + '/' + this.$i18n.locale + '/app'
          }
        }
    }
</script>

<style scoped>

</style>
